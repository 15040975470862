import { Button, Container, Stack, StyledComponentProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import AsyncStorageHelper from '../../auth/AsyncStorageHelper';
import AuthManager from '../../auth/AuthManager';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import { HookTypes, withHooks } from '../../utils/withHooks';
import PhoneNumberTextField from '../common/PhoneNumberTextField';
import EmailTextField from '../common/EmailTextField';
import Footer from '../footer/Footer';
import MagicLinkLogin from './MagicLinkLogin';
import { Link } from 'react-router-dom';

const PREFIX = 'Login';

const classes = {
	root: `${PREFIX}-root`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme: { mixins, breakpoints, palette } }) => ({
	[`& .${classes.root}`]: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: '50vh',
		marginBottom: mixins.toolbar.minHeight,
		color: palette.text.primary,
		[breakpoints.up('lg')]: {
			minHeight: '83vh',
		},
	},
}));

type Props = HookTypes & StyledComponentProps;

const Login = observer(
	class Login extends React.Component<Props> {
		static readonly contextType = StoreContext;
		get location() {
			return this.props.location!;
		}
		get history() {
			return this.props.navigate!;
		}

		phoneNumber: string = '';
		email: string = '';
		showMagicLinkLogin: boolean = true;
		timeout: any = null;
		statusText: string = 'Vi sender deg en bekreftelseskode for å bekrefte identiteten din';
		errorText?: string;
		loginMethod: 'phone' | 'email' = 'phone';

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				phoneNumber: observable,
				email: observable,
				showMagicLinkLogin: observable,
				statusText: observable,
				errorText: observable,
				loginMethod: observable,
				queryParams: computed,
				rootStore: computed,
				userStore: computed,
				uiState: computed,
				handlePhoneNumberChange: action,
				handleMagicLinkLoginSuccess: action,
				handleMagicLinkLoginError: action,
				loginCheck: action,
				setLoginMethodToPhone: action,
				setLoginMethodToEmail: action,
			});
		}

		get queryParams() {
			try {
				let search = this.location.search;

				if (search) {
					if (search.startsWith('?')) {
						search = search.replace('?', '');
					}

					const query: any = {};
					const parts = search.split('&');

					parts.forEach((part: string) => {
						const p = part.split('=');
						query[p[0]] = p[1] || true;
					});

					return query;
				}
			} catch (e) {
				console.error(e);
			}

			return {};
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		componentDidMount(): void {
			this.loginCheck();
		}

		componentWillUnmount(): void {
			clearTimeout(this.timeout);
		}

		loginCheck = async () => {
			try {
				console.log('Checking login status...');
				const { isLoggedIn } = this.userStore;
				if (isLoggedIn) {
					this.history('/app/dashboard');
				} else {
					this.timeout = setTimeout(() => {
						this.loginCheck();
					}, 1000);
				}
			} catch (error) {
				console.error(error);
			}
		};

		handleMagicLinkLoginError = () => {
			console.log('Magic link login failed!');
			this.showMagicLinkLogin = false;
		};

		handleMagicLinkLoginSuccess = () => {
			console.log('Magic link login success!');
			this.showMagicLinkLogin = false;
			try {
				const cachedPath = AsyncStorageHelper.getCache(`@BefWeb:login:redirect`);

				if (cachedPath && cachedPath != '/') {
					this.history(cachedPath);
				} else {
					this.history(`/app/dashboard`);
				}
			} catch (error) {
				console.error(error);
				this.history('/');
			}
		};

		handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			this.phoneNumber = event.target.value;
		};

		handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			this.email = event.target.value;
		};

		handlePhoneSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			try {
				await AuthManager.phoneAuth(undefined, this.phoneNumber);

				this.history('/sign-in/verification-code', { state: { phoneNumber: this.phoneNumber } });
			} catch (error) {
				console.error(error);
			}
		};

		handleEmailSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			try {
				await AuthManager.emailAuth(this.email);

				this.history('/sign-in/magiclink-sent', { state: { email: this.email } });
			} catch (error) {
				console.error(error);

				this.errorText = 'Noe gikk galt. Vennligst prøv igjen senere';
			}
		};

		changeMethod = () => {
			this.loginMethod = this.loginMethod === 'phone' ? 'email' : 'phone';

			if (this.loginMethod === 'phone') {
				this.statusText = 'Vi sender deg en bekreftelseskode for å bekrefte identiteten din';
			} else if (this.loginMethod === 'email') {
				this.statusText = 'Vi sender en Magisk Lenke til e-posten din';
			}
		};

		renderLoginMethods() {
			switch (this.loginMethod) {
				case 'phone':
					return this.renderPhoneLogin();
				case 'email':
					return this.renderEmailLogin();
				default:
					return this.renderMethodSelection();
			}
		}

		setLoginMethodToPhone = () => {
			this.loginMethod = 'phone';
			this.statusText = 'Vi sender deg en bekreftelseskode for å bekrefte identiteten din';
		};

		setLoginMethodToEmail = () => {
			this.loginMethod = 'email';
			this.statusText = 'Vi sender en Magisk Lenke til e-post';
		};

		renderMethodSelection() {
			return (
				<Stack spacing={2}>
					<Button color="primary" fullWidth onClick={this.setLoginMethodToPhone} variant="contained">
						Logg inn med telefon
					</Button>
					<Button color="primary" fullWidth onClick={this.setLoginMethodToEmail} variant="contained">
						Logg inn med e-post
					</Button>
				</Stack>
			);
		}

		renderEmailLogin() {
			const { isComputer } = this.uiState;

			return (
				<form onSubmit={this.handleEmailSubmit}>
					<EmailTextField
						autoFocus={isComputer}
						fullWidth
						margin="normal"
						onChange={this.handleEmailChange}
						required
						value={this.email}
					/>
					<Button color="primary" fullWidth type="submit" variant="contained">
						Send Magisk Lenke
					</Button>
				</form>
			);
		}

		renderPhoneLogin() {
			const { isComputer } = this.uiState;

			return (
				<form onSubmit={this.handlePhoneSubmit}>
					<PhoneNumberTextField
						autoFocus={isComputer}
						fullWidth
						margin="normal"
						onChange={this.handlePhoneNumberChange}
						required
						value={this.phoneNumber}
					/>
					<Button color="primary" fullWidth type="submit" variant="contained">
						Send kode
					</Button>
				</form>
			);
		}

		render() {
			const { isMobile, isTablet, isPortraitOrientation } = this.uiState;
			const { isLoggedIn } = this.userStore;

			return (
				<Root>
					<Container classes={{ root: classes.root }} className="Login">
						{this.showMagicLinkLogin && this.queryParams?.t && (
							<MagicLinkLogin
								token={this.queryParams?.t}
								onSuccess={this.handleMagicLinkLoginSuccess}
								onError={this.handleMagicLinkLoginError}
							/>
						)}

						{!(isLoggedIn && this.showMagicLinkLogin) && (
							<>
								<Typography
									align="center"
									gutterBottom
									variant={isMobile || (isTablet && isPortraitOrientation) ? 'h5' : 'h3'}
								>
									<span className="text--bold text--italic text--red">Logg inn</span> for å finne
									jobbene dine
								</Typography>

								<Typography
									align="center"
									color={this.errorText ? 'error' : 'textSecondary'}
									gutterBottom
									variant={isMobile || (isTablet && isPortraitOrientation) ? 'body2' : 'h6'}
								>
									{this.errorText ?? this.statusText}
								</Typography>

								{this.renderLoginMethods()}

								<Typography variant="subtitle2" sx={{ marginTop: '.8rem' }}>
									Logg inn med {''}
									<Typography
										component={Link}
										onClick={this.changeMethod}
										to={'/sign-in'}
										variant="subtitle2"
									>
										{this.loginMethod === 'phone' ? 'e-post' : 'mobil'}
									</Typography>
								</Typography>
							</>
						)}

						{isLoggedIn && (
							<Stack spacing={2}>
								<Typography
									align="center"
									gutterBottom
									variant={isMobile || (isTablet && isPortraitOrientation) ? 'h5' : 'h3'}
								>
									Du er logget inn.
								</Typography>
								<Button component={Link} to="/dashboard" variant="contained">
									Gå til dashbord
								</Button>
							</Stack>
						)}
					</Container>
					<Footer />
				</Root>
			);
		}
	}
);

export default withHooks(Login);

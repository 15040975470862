import React, { useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import { motion, useMotionValue, useMotionTemplate, useTransform, useScroll, type MotionValue } from 'framer-motion';
import { alpha, lighten, darken } from '@mui/system';

type SpotlightProps = {
	children: React.ReactNode;
};

function useParallax(value: MotionValue<number>, distance: number) {
	return useTransform(value, [0, 1], [-distance, distance]);
}

type Star = {
	size: number;
	x: number;
	y: number;
	duration: number;
	id: string;
};

const Spotlight: React.FC<SpotlightProps> = ({ children }) => {
	const containerRef = useRef<HTMLDivElement>(null); // Ref for the container
	const mouseX = useMotionValue(0);
	const mouseY = useMotionValue(0);
	const { scrollYProgress } = useScroll();

	const parallax1 = useParallax(scrollYProgress, -60);
	const parallax2 = useParallax(scrollYProgress, -40);
	const parallax3 = useParallax(scrollYProgress, -20);

	const generateStars = (count: number): Star[] => {
		const stars = [];
		for (let i = 0; i < count; i++) {
			const size = Math.random() * 2 + 1; // Star size between 1px and 3px
			const x = Math.random() * 100; // Random horizontal position (percentage)
			const y = Math.random() * 100; // Random vertical position (percentage)
			const duration = Math.random() * 5 + 5; // Random twinkle duration between 5s and 10s
			const randomIdString = Math.random().toString(36).substring(7);
			stars.push({
				size,
				x,
				y,
				duration,
				id: `star-${count}-${randomIdString}`,
			});
		}
		return stars;
	};

	const stars1 = useMemo(() => generateStars(50), []);
	const stars2 = useMemo(() => generateStars(30), []);
	const stars3 = useMemo(() => generateStars(20), []);

	function handleMouseMove(event: React.MouseEvent<HTMLDivElement>) {
		const container = containerRef.current;
		if (container) {
			const { left, top } = container.getBoundingClientRect(); // Get container's position
			mouseX.set(event.clientX - left);
			mouseY.set(event.clientY - top - window.scrollY); // Adjust for scroll
		}
	}

	const renderStars = (stars: Star[], parallax: MotionValue<number>) => {
		const starItems: React.ReactNode[] = [];
		stars.forEach((star) => {
			starItems.push(
				<motion.div
					key={star.id}
					style={{
						position: 'absolute',
						width: `${star.size}px`,
						height: `${star.size}px`,
						backgroundColor: 'rgba(255, 255, 255, 0.8)',
						borderRadius: '50%',
						top: `${star.y}%`,
						left: `${star.x}%`,
						y: parallax, // Dynamic parallax motion value
						opacity: 0.6,
					}}
					animate={{
						opacity: [0.6, 1, 0.6],
						scale: [1, 1.2, 1],
					}}
					transition={{
						duration: star.duration,
						repeat: Infinity,
						repeatType: 'mirror',
					}}
				/>
			);
		});
		return starItems;
	};

	return (
		<Box
			ref={containerRef} // Attach the ref to the container
			sx={(theme) => ({
				position: 'relative',
				overflow: 'hidden',
				'&:hover .spotlight-effect': {
					opacity: 1,
				},
				backgroundImage:
					theme.palette.mode === 'dark'
						? `radial-gradient(40% 50% at 50% -20%, ${alpha(theme.palette.primary.dark, 0.5)}, ${alpha(lighten(theme.palette.background.default, 0.3), 0.2)}, transparent)`
						: `radial-gradient(40% 50% at 50% -20%, ${alpha(theme.palette.primary.light, 0.3)}, ${alpha(darken(theme.palette.background.default, 0.4), 0.1)}, transparent)`,
				backgroundRepeat: 'no-repeat',
			})}
			onMouseMove={handleMouseMove}
		>
			{/* Starry Sky */}
			<Box
				sx={{
					position: 'fixed',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					inset: 0,
					zIndex: 0,
				}}
			>
				{renderStars(stars1, parallax1)} {/* Foreground stars */}
				{renderStars(stars2, parallax2)} {/* Foreground stars */}
				{renderStars(stars3, parallax3)} {/* Foreground stars */}
			</Box>
			{/* Spotlight Effect */}
			<motion.div
				className="spotlight-effect"
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					inset: '-1px',
					borderRadius: 'inherit',
					opacity: 1,
					zIndex: 0,
					border: '10px solid rgba(0,0,0,0.5)',
					background: useMotionTemplate`
            radial-gradient(
              650px circle at ${mouseX}px ${mouseY}px,
              rgba(14, 165, 233, 0.15),
              transparent 80%
            )
          `,
					pointerEvents: 'none',
					transition: 'opacity 0.3s ease',
				}}
			/>
			<Box
				sx={{
					position: 'relative',
					zIndex: 1, // Ensure children stay above the spotlight
				}}
			>
				{children}
			</Box>
		</Box>
	);
};

export default Spotlight;

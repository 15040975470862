import { alpha, lighten, Stack, Avatar, Paper, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import './CustomerSupportChatMessage.scss';
import Message from '../../stores/Message';
import FilePreview2 from '../common/file/FilePreview';
import { Profile } from '../../stores/ProfileStore';

type Props = {
	name?: React.ReactNode | string;
	image?: string;
	icon?: React.ReactNode;
	children: React.ReactNode;
	right?: boolean;
	message?: Message;
	profile?: Profile;
};

const CustomerSupportChatMessage = observer(
	class CustomerSupportChatMessage extends React.Component<Props> {
		onFileClick = (file: any) => {
			const { message } = this.props;
			if (!message) return;
			const { attachments } = message;
			const attachment = attachments?.find((attachment: any) => attachment.file === file);

			if (!attachment?.file?.path) {
				return;
			}

			window.open(attachment.file?.path, '_blank');
		};

		renderAttachments() {
			const { message } = this.props;
			const files = message?.attachments?.map((attachment: any) => attachment.file);

			if (!files) {
				return;
			}

			// eslint-disable-next-line no-undef
			return (
				<Stack
					direction="row"
					flexWrap="wrap"
					spacing={1}
					sx={{
						['& .FilePreview']: {
							maxWidth: '90%',
							width: 350,
						},
					}}
				>
					<FilePreview2 files={files} onFileClick={this.onFileClick} />
				</Stack>
			);
		}

		renderDesktop() {
			const { name, image, icon, children, right, profile } = this.props;
			return (
				<Stack direction={right ? 'row-reverse' : 'row'} spacing={2} className="customer-support-chat-message">
					<Stack direction="column" alignItems="center" justifyContent="flex-start">
						<Avatar
							src={image}
							sx={{
								boxShadow: profile?.isAI ? '0 0 0 2px #f50057' : 'none',
							}}
						>
							{image ?? icon}
						</Avatar>
						<Typography variant="caption">
							{name}
							{profile?.isAI ? '(KI)' : ''}
						</Typography>
					</Stack>
					<Stack direction="column" alignItems={right ? 'flex-end' : 'flex-start'} spacing={2}>
						<Paper
							elevation={2}
							sx={(theme) => ({
								padding: '0 1rem',
								background: right
									? alpha(theme.palette.primary.main, 0.1)
									: lighten(theme.palette.background.paper, 0.1),
							})}
							className="message-content"
						>
							{children}
						</Paper>
						{this.renderAttachments()}
					</Stack>
				</Stack>
			);
		}

		renderMobile() {
			const { name, image, icon, children, right, profile } = this.props;
			return (
				<Stack direction="column" spacing={1} className="customer-support-chat-message">
					<Stack direction={right ? 'row-reverse' : 'row'} alignItems="center" justifyContent={'flex-start'}>
						{!right && (
							<Avatar
								src={image}
								sx={{ width: 32, height: 32, boxShadow: profile?.isAI ? '0 0 0 2px #f50057' : 'none' }}
							>
								{image ?? icon}
							</Avatar>
						)}

						<Typography variant="caption" sx={{ margin: '0 0.5rem' }}>
							{name}
							{profile?.isAI ? '(KI)' : ''}
						</Typography>
					</Stack>
					<Stack direction="column" alignItems={right ? 'flex-end' : 'flex-start'} spacing={2}>
						<Paper
							elevation={2}
							sx={(theme) => ({
								padding: '0 1rem',
								background: right
									? alpha(theme.palette.primary.main, 0.1)
									: lighten(theme.palette.background.paper, 0.1),
								display: 'flex',
								flexShrink: 1,
								width: 'fit-content',
								height: 'fit-content',
								borderRadius: '1rem',
								borderTopLeftRadius: right ? '1rem' : '0',
								borderTopRightRadius: right ? '0' : '1rem',
							})}
							className="message-content"
						>
							{children}
						</Paper>
						{this.renderAttachments()}
					</Stack>
				</Stack>
			);
		}

		render() {
			if (window.innerWidth > 600) {
				return this.renderDesktop();
			} else {
				return this.renderMobile();
			}
		}
	}
);

export default CustomerSupportChatMessage;

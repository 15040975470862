import { styled, Typography } from '@mui/material';

const GradientText = styled(Typography)(() => ({
	fontSize: '3rem', // Adjust the size as needed
	fontWeight: 'bold',
	background: 'linear-gradient(90deg, #ff8a00, #e52e71, #9b51e0)',
	backgroundSize: '200% 200%',
	WebkitBackgroundClip: 'text',
	WebkitTextFillColor: 'transparent',
	animation: 'gradientShift 5s ease infinite',
	'@keyframes gradientShift': {
		'0%': {
			backgroundPosition: '0% 50%',
		},
		'50%': {
			backgroundPosition: '100% 50%',
		},
		'100%': {
			backgroundPosition: '0% 50%',
		},
	},
}));

export default GradientText;

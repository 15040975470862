import config from '../../config/config';
import { FetchWrapper, FetchWrapperResponse } from '../../fetch/FetchWrapper';

/**
 * Profile api
 */
export default class EventApi {
	static async getEvents(from?: string): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/events/list`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				from,
			}),
			requireAuth: true,
		});
	}

	/**
	 * Load profiles
	 * @param {Number[]} userIds
	 */
	static async getEvent(eventId: string): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/events/get/${eventId}`;
		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	/**
	 * create event
	 * @param {Number[]} userIds
	 */
	static async createEvent(befareEvent: any, channelId?: number): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/events/create`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				meeting: befareEvent,
				channelId,
			}),
			requireAuth: true,
		});
	}

	/**
	 * respond to event
	 * @param {Number[]} userIds
	 */
	static async respondToEvent(eventId: string, status: string): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/events/respond/${eventId}`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				status,
			}),
			requireAuth: true,
		});
	}

	/**
	 * cancel event
	 * @param {String} eventId
	 */
	static async cancelEvent(eventId: string): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/events/respond/${eventId}`;
		return FetchWrapper.delete(url, {
			requireAuth: true,
		});
	}
}

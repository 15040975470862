import React, { createContext, useContext, useState, useEffect } from 'react';

// TypeScript type for `BeforeInstallPromptEvent`
interface BeforeInstallPromptEvent extends Event {
	readonly platforms: string[];
	prompt: () => Promise<void>;
	userChoice: Promise<{ outcome: 'accepted' | 'dismissed'; platform: string }>;
}

interface PWAContextProps {
	isInstallable: boolean;
	promptInstall: () => Promise<void>;
}

const PWAContext = createContext<PWAContextProps>({
	isInstallable: false,
	promptInstall: async () => {
		console.error('Installasjonsflyt ikke tilgjengelig.');
	},
});

export const PWAProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
	const [isInstallable, setIsInstallable] = useState(false);

	useEffect(() => {
		const handleBeforeInstallPrompt = (event: Event) => {
			event.preventDefault();
			setDeferredPrompt(event as BeforeInstallPromptEvent);
			setIsInstallable(true);
		};

		window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

		return () => {
			window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
		};
	}, []);

	const promptInstall = async () => {
		if (deferredPrompt) {
			await deferredPrompt.prompt();
			const choiceResult = await deferredPrompt.userChoice;
			if (choiceResult.outcome === 'accepted') {
				console.log('Brukeren installerte appen');
			} else {
				console.log('Brukeren avviste installasjonen');
			}
			setDeferredPrompt(null);
			setIsInstallable(false);
		} else {
			console.warn('Installasjonsflyt ikke tilgjengelig.');
		}
	};

	return <PWAContext.Provider value={{ isInstallable, promptInstall }}>{children}</PWAContext.Provider>;
};

export const usePWA = () => useContext(PWAContext);

import { createTheme, darken, Theme } from '@mui/material';

const minelektrikerTheme = (baseTheme: Theme, options: any) => {
	const { isDarkMode } = options;
	const lightModePalette = {
		primary: {
			main: '#224459',
		},
		secondary: {
			main: '#a8353a',
		},
	};

	const darkModePalette = {
		primary: {
			main: lightModePalette.primary.main,
			contrastText: '#ffffff',
		},
		secondary: {
			main: lightModePalette.secondary.main,
			contrastText: '#ffffff',
		},
	};
	const theme = createTheme(
		{
			...baseTheme,
			palette: options.isDarkMode
				? {
						mode: 'dark',
						background: {
							default: darken(darkModePalette.primary.main, 0.8), // Mørkere variant av primary.main
							paper: darken(darkModePalette.primary.main, 0.6), // Litt mindre mørk variant
						},
						divider: 'rgba(255, 255, 255, 0.1)',
						error: {
							main: '#d44141',
						},
						info: {
							main: '#3a91bb',
						},
						primary: darkModePalette.primary,
						secondary: darkModePalette.secondary,
						success: {
							main: '#6eb351',
						},
						text: {
							primary: '#f6f6f6',
							secondary: '#00bcd4',
							disabled: 'rgba(23, 57, 99, 0.38)',
						},
						warning: {
							main: '#fdb714',
						},
					}
				: {
						mode: 'light',
						background: {
							default: '#fff',
							paper: '#f1f1f1',
						},
						divider: 'rgba(23, 57, 99, 0.12)',
						error: {
							main: '#d44141',
						},
						info: {
							main: '#3a91bb',
						},
						primary: {
							main: '#224459',
						},
						secondary: {
							main: '#a8353a',
						},
						success: {
							main: '#6eb351',
						},
						text: {
							primary: 'rgb(23, 57, 99, 0.87)',
							secondary: 'rgba(23, 57, 99, 0.60)',
							disabled: 'rgba(23, 57, 99, 0.38)',
							// hint: 'rgba(23, 57, 99, 0.38)',
						},
						warning: {
							main: '#fdb714',
						},
					},
			shape: {
				borderRadius: 8,
			},
			typography: {
				fontFamily: 'Inter, Helvetica, sans-serif',
			},
		},
		[isDarkMode]
	);
	return theme;
};

export default minelektrikerTheme;

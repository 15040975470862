import { Box, Link, Typography, Unstable_Grid2 as Grid2 } from '@mui/material';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import { ServiceName } from '../../stores/UiState';

type Props = {};

const Footer = observer(
	class Footer extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				uiState: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		render() {
			const { serviceName } = this.uiState;

			return (
				<Box
					className="Footer"
					component="footer"
					padding="var(--gutter-width)"
					paddingTop="32px"
					textAlign="center"
					bgcolor="primary.main"
					color="primary.contrastText"
				>
					<Grid2 container spacing={2}>
						<Grid2 xs={12} sm={6} md={4}>
							<Typography variant="h6">Elektriker</Typography>
							<ul style={{ listStyle: 'none', padding: 0 }}>
								<li>
									<Link
										color="inherit"
										component={RouterLink}
										gutterBottom
										to="/near-me/elektriker/0151/Oslo"
										underline="always"
									>
										Oslo
									</Link>
								</li>
								<li>
									<Link
										color="inherit"
										component={RouterLink}
										gutterBottom
										to="/near-me/elektriker/5020/Bergen"
										underline="always"
									>
										Bergen
									</Link>
								</li>
								<li>
									<Link
										color="inherit"
										component={RouterLink}
										gutterBottom
										to="/near-me/elektriker/7011/Trondheim"
										underline="always"
									>
										Trondheim
									</Link>
								</li>
								<li>
									<Link
										color="inherit"
										component={RouterLink}
										gutterBottom
										to="/near-me/elektriker/4002/Stavanger"
										underline="always"
									>
										Stavanger
									</Link>
								</li>
								<li>
									<Link
										color="inherit"
										component={RouterLink}
										gutterBottom
										to="/near-me/elektriker/3030/Drammen"
										underline="always"
									>
										Drammen
									</Link>
								</li>
								<li>
									<Link
										color="inherit"
										component={RouterLink}
										gutterBottom
										to="/near-me/elektriker/1600/Fredrikstad"
										underline="always"
									>
										Fredrikstad
									</Link>
								</li>
								<li>
									<Link
										color="inherit"
										component={RouterLink}
										gutterBottom
										to="/near-me/elektriker/4611/Kristiansand"
										underline="always"
									>
										Kristiansand
									</Link>
								</li>
								<li>
									<Link
										color="inherit"
										component={RouterLink}
										gutterBottom
										to="/near-me/elektriker/9010/Tromsø"
										underline="always"
									>
										Tromsø
									</Link>
								</li>
								<li>
									<Link
										color="inherit"
										component={RouterLink}
										gutterBottom
										to="/near-me/elektriker/6413/Molde"
										underline="always"
									>
										Molde
									</Link>
								</li>
							</ul>
						</Grid2>
						<Grid2 xs={12} sm={6} md={4}>
							<Typography variant="h6">Tjenester</Typography>
							<ul style={{ listStyle: 'none', padding: 0 }}>
								<li>
									<Link
										color="inherit"
										component={RouterLink}
										gutterBottom
										to="/artikler/lade-elbil-hjemme"
										underline="always"
									>
										Elbillader
									</Link>
								</li>
								<li>
									<Link
										color="inherit"
										component={RouterLink}
										gutterBottom
										to="/artikler/renovere-sikringsskap"
										underline="always"
									>
										Sikringsskap
									</Link>
								</li>
								<li>
									<Link
										color="inherit"
										component={RouterLink}
										gutterBottom
										to="/artikler/ny-stikkontakt"
										underline="always"
									>
										Ny stikkontakt
									</Link>
								</li>
								<li>
									<Link
										color="inherit"
										component={RouterLink}
										gutterBottom
										to="/artikler/lamper-og-belysning"
										underline="always"
									>
										Lamper og belysning
									</Link>
								</li>
								<li>
									<Link
										color="inherit"
										component={RouterLink}
										gutterBottom
										to="/artikler/varmekabler"
										underline="always"
									>
										Varmekabler
									</Link>
								</li>
							</ul>
						</Grid2>
						<Grid2 xs={12} sm={6} md={4}>
							{serviceName === ServiceName.Befare ? (
								<div style={{ marginBottom: '16px' }}>
									<RouterLink to="/about-befare">
										<img alt="Befare logo" height={40} src="/logo/befare.png" width={170} />
									</RouterLink>
								</div>
							) : (
								<Typography gutterBottom variant="h5">
									{serviceName}
								</Typography>
							)}

							{serviceName !== ServiceName.Befare && (
								<div style={{ marginBottom: '32px' }}>
									<Typography gutterBottom variant="subtitle2">
										En spesialtilpasset versjon av
									</Typography>
									<RouterLink to="/about-befare">
										<img alt="Befare logo" height={24} src="/logo/befare.png" width={102} />
									</RouterLink>
								</div>
							)}

							<div
								style={{
									alignItems: 'center',
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '64px',
								}}
							>
								<Link
									color="inherit"
									component={RouterLink}
									gutterBottom
									to="/privacy"
									underline="always"
								>
									Personvernerklæring
								</Link>
								{/* TODO Split up privacy policy and terms of use
						<Link color="inherit" component={RouterLink} gutterBottom underline="always">
							Vilkår for bruk
						</Link> */}
								<Link
									color="inherit"
									component={RouterLink}
									to="/cookie-declaration"
									underline="always"
								>
									Informasjonskapsler
								</Link>
							</div>

							<Typography variant="subtitle2">© {new Date().getFullYear()} Befare</Typography>
						</Grid2>
					</Grid2>
				</Box>
			);
		}
	}
);

export default Footer;

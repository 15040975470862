/* eslint eqeqeq: "off" */

import { IReactionDisposer, action, makeAutoObservable, reaction, runInAction } from 'mobx';
import { MessageContent, MessageDTO, MessageType, MessageWidget, UpsertMessageDTO, User } from '../types';
import { ChannelQuickActionTriggerData, QuickAction, Trigger, TriggerType } from '../Trigger.types';

import ChatApi from '../api/endpoints/ChatApi';
import LiteEvent from '../helpers/LiteEvent';
import LogUtil from '../helpers/LogUtil';
import { RootStore } from './RootStore';
import { SoundEffect } from './UiState';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import Message from './Message';
import { ChannelTypeDTO, DynamicChannelView } from '../dto/channel.types';
import { DateUtil } from '../helpers/DateUtil';
import CustomerServiceApi from '../api/endpoints/CustomerServiceApi';

/**
 * ChatStore
 */
export class ChatStore {
	rootStore: RootStore;

	private readonly onNewMessage = new LiteEvent<any>();

	channels: Channel[] = [];
	isLoading: boolean = true; // default to true to prevent loading before we are ready
	loading: any = {};
	activeChannel?: Channel;

	isInitialized: boolean = false;
	debouncedLoadChats: any;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.setAppBadge = debounce(this.setAppBadge, 1000);
		this.debouncedLoadChats = debounce(this.loadChats, 1000);

		this.processTrigger = this.processTrigger.bind(this);
		this.updateReaction = this.updateReaction.bind(this);
		this.updateChannelMessagesFromServer = this.updateChannelMessagesFromServer.bind(this);
		this.updateChannelFromServer = this.updateChannelFromServer.bind(this);
		this.loadChats = this.loadChats.bind(this);
		this.setAppBadge = this.setAppBadge.bind(this);

		makeAutoObservable(this, {
			rootStore: false,
			processTrigger: action,
			updateReaction: action,
			updateChannelMessagesFromServer: action,
			updateChannelFromServer: action,
			loadChats: action,
		});

		this.init();
	}

	init() {
		if (this.isInitialized) {
			console.log('ChatStore already initialized');
			return;
		}
		this.rootStore.userStore.UserIdChanged.on(() => {
			console.log(`ChatStore: UserIdChanged`);
			this.debouncedLoadChats();
		});

		this.rootStore.userStore.SignedOut.on(() => {
			runInAction(() => {
				this.channels = [];
			});
			this.clearAppBadge();
		});

		// @todo consider checking userStore.lastOnline timestamp
		// @todo consider adding a delta endpoint that returns messages for channelIds created after a given timestamp
		this.rootStore.Online.on(() => {
			console.log(`ChatStore: Online`);
			this.debouncedLoadChats();
		});
	}

	setActiveChannel(channel?: Channel) {
		this.activeChannel = channel;
	}

	filterChannels(types: string[]) {
		return this.channels.filter((channel) => types?.includes(channel.channelType) && !channel.deleted);
	}

	public get NewMessage() {
		return this.onNewMessage.expose();
	}

	/**
	 * Get numb3r of unread messages
	 */
	get unreadInboxCount(): number {
		let unreadMessages = 0;
		this.channels
			.filter((c: any) => !c.channelType?.includes('PROJECT'))
			.forEach((channel) => {
				unreadMessages += channel.unreadNum;
			});

		return unreadMessages;
	}

	/**
	 * Get numb3r of unread messages
	 */
	get unreadCount(): number {
		let unreadMessages = 0;
		this.channels.forEach((channel) => {
			unreadMessages += channel.unreadNum;
		});

		return unreadMessages;
	}

	/**
	 * Get numb3r of channels with unread messages
	 */
	get unreadInboxChannelCount(): number {
		let unreadMessages = 0;

		this.channels
			.filter((c: any) => !c.channelType?.includes('PROJECT'))
			.forEach((channel: any) => {
				unreadMessages += channel.unreadNum > 0 ? 1 : 0;
			});

		return unreadMessages;
	}

	/**
	 * Get numb3r of channels with unread messages
	 */
	get unreadChannelCount(): number {
		let unreadMessages = 0;
		this.channels.forEach((channel: any) => {
			unreadMessages += channel.unreadNum > 0 ? 1 : 0;
		});

		return unreadMessages;
	}

	get agentChannels() {
		return this.filterChannels(['AGENT']);
	}

	async processTrigger(trigger: Trigger<any>) {
		try {
			const { data } = trigger.event;
			const { channelId, id } = data;
			if (!(channelId || id)) {
				return;
			}
			const channel = id ? data : this.findChannel(channelId);
			switch (trigger.urn) {
				case TriggerType.CHANNEL_JOIN:
				case TriggerType.CHANNEL_LEAVE:
					await this.loadChannel(channelId);
					break;
				case TriggerType.CHANNEL_TYPING:
					channel?.updateTyping(data.userId);
					break;
				case TriggerType.CHANNEL_PEEKING:
					channel?.updatePeeking(data.userId);
					break;
				case TriggerType.CHANNEL_MESSAGE_NEW:
				case TriggerType.CHANNEL_MESSAGE_UPDATED:
					this.rootStore.notificationStore.onNewMessage(data as MessageDTO);
					this.updateChannelMessagesFromServer(channelId, {
						messages: [data],
					});

					runInAction(() => this.setAppBadge);
					break;
				case TriggerType.CHANNEL_MESSAGE_DELETED:
					this.updateChannelMessagesFromServer(
						channelId,
						{
							messages: [data],
						},
						true
					);
					break;
				case TriggerType.CHANNEL_REACTION_NEW:
					// @todo: remove, replace with notification store. This is just for demo
					if (data.userId != this.rootStore.profileStore.currentUserProfile?.userId) {
						this.rootStore.uiState.playSound(SoundEffect.NEW_REACTION);
						const profile = this.rootStore.profileStore.getProfile(data.userId);

						const reaction = this.rootStore.settingsStore.applyEmojiSettingsToText(data.reaction);
						if (reaction && profile?.name) {
							toast(`${profile?.name}: ${reaction}`, {
								toastId: `reaction:${data.reactionId}`,
							});
						}
					}
					this.updateReaction(channelId, data.messageId, data.userId, data.reaction, data.reactionId, true);
					break;
				case TriggerType.CHANNEL_REACTION_DELETED:
				case TriggerType.CHANNEL_REACTION_UPDATED:
					this.updateReaction(channelId, data.messageId, data.userId, data.reaction, data.reactionId, false);
					break;
				case TriggerType.CHANNEL_CREATED:
				case TriggerType.CHANNEL_UPDATED:
				case TriggerType.CHANNEL_DELETED:
					this.updateChannelFromServer(data);
					break;
				case TriggerType.CHANNEL_QUICK_ACTION_CREATED:
				case TriggerType.CHANNEL_QUICK_ACTION:
					this.processQuickActionTrigger(trigger as unknown as Trigger<ChannelQuickActionTriggerData>);
					break;
				case TriggerType.CHANNEL_DYNAMIC_VIEW:
					this.processDynamicViewTrigger(trigger as unknown as Trigger<DynamicChannelView>);
					break;
			}
		} catch (err) {
			LogUtil.warn('Unable to process channel trigger', trigger, err);
		}
	}

	processDynamicViewTrigger = async (trigger: Trigger<DynamicChannelView>) => {
		const { channelId } = trigger.event.data;
		console.info('Dynamic view trigger', trigger);
		const channel = this.channels.find((channel) => channel.id === +channelId);
		if (!channel) {
			console.warn(`Channel not found for dynamic view trigger ${channelId}`);
			return;
		}

		channel.setDynamicView(trigger.event.data);
	};

	processQuickActionTrigger = async (trigger: Trigger<ChannelQuickActionTriggerData>) => {
		const { channelId, quickActions } = trigger.event.data;

		const channel = this.channels.find((channel) => channel.id === +channelId);
		if (!channel) {
			console.warn(
				'Channel not found',
				trigger,
				this.channels.map((c) => c.id)
			);
			return;
		}

		channel.updateQuickActions(quickActions);
	};

	/**
	 * @param {String} channelId
	 * @param {String} messageId
	 * @param {String} userId
	 * @param {String} reaction
	 * @param {Boolean} add default true
	 */
	updateReaction(
		channelId: number,
		messageId: number,
		userId: string,
		reaction: string,
		reactionId: string,
		add: boolean = true
	) {
		const foundChannel = this.findChannel(channelId);

		if (foundChannel) {
			runInAction(() => {
				foundChannel?.updateReaction(messageId, userId, reaction, reactionId, add);
			});
		}
	}

	/**
	 * emit eevnt when user is typing
	 * @param {number} channelId
	 */
	isTyping(channelId: number) {
		// @todo add debounce
		this.rootStore.emitEvent('user-typing', {
			channelId: channelId,
		});
	}

	/**
	 * emit eevnt when user is peeking
	 * @param {number} channelId
	 */
	isPeeking(channelId: number) {
		this.rootStore.emitEvent('user-peeking', {
			channelId: channelId,
		});
	}

	/**
	 * Set loading status
	 * @param _isLoading Set loading
	 */
	setIsLoading(_isLoading: boolean = true) {
		this.isLoading = _isLoading;
	}

	/**
	 * @deprecated use channel.messages
	 * @param  {number} channelId
	 * @returns {Message[]} messages
	 */
	getMessages(channelId: number): Message[] {
		const channel = this.findChannel(channelId);
		return channel?.messages && Array.isArray(channel.messages) ? channel.messages : [];
	}

	/**
	 * @deprecated use channel.mostRecentMessage
	 * @param  {number} channelId
	 * @returns {Message | null} message
	 */
	getLastMessage(channelId: number): Message | null {
		const messages = this.getMessages(channelId);
		return messages[messages.length - 1] || null;
	}

	/**
	 * @param  {String} channelId
	 * @deprecated refactor and create a method on channel for this
	 * @return {Promise} promise
	 */
	async loadMessages(channelId: number, limit: number = 25): Promise<void> {
		const channel = this.findChannel(channelId);

		if (
			channel &&
			!channel.isLoading &&
			!channel.isLoadingMessages &&
			(channel.lastMessageFetch + 10000 < Date.now() || !channel.firstMessageLoadDone)
		) {
			// Set this immediately to avoid spamming
			channel.setIsLoadingMessages(true);
			channel.firstMessageLoadDone = true;
			this.setIsLoading(true);
			return ChatApi.getMessages(channelId, 0, limit)
				.then((messageJson: any) => {
					this.updateChannelMessagesFromServer(channelId, messageJson);
					if (messageJson.messages.length < limit) {
						runInAction(() => {
							channel.blockLoadMoreMessages = true;
						});
					}
					this.setIsLoading(false);
					runInAction(() => this.setAppBadge);
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					this.setIsLoading(false);
					channel.setIsLoadingMessages(false);
				});
		} else {
			return Promise.resolve();
		}
	}

	/**
	 * @param  {String} channelId
	 * @return {Promise} promise
	 */
	async loadMoreMessages(channelId: number, limit: number = 25): Promise<void> {
		const channel = this.findChannel(channelId);
		// @todo channel can be undefined here, that causes empty channel with no messages
		// due to this being called while loading channels
		// solution is probably to queue up this load
		if (channel && !channel.isLoading && !channel.isLoadingMessages && !channel.blockLoadMoreMessages) {
			channel.setIsLoadingMessages(true);
			return ChatApi.getMessages(channelId, channel.messages.length, limit)
				.then((messageJson: any) => {
					this.updateChannelMessagesFromServer(channelId, messageJson);
					if (messageJson.messages.length < limit) {
						runInAction(() => {
							channel.blockLoadMoreMessages = true;
						});
					}
				})
				.catch((err) => {
					LogUtil.error(err);
				})
				.finally(() => {
					channel.setIsLoadingMessages(false);
				});
		} else {
			console.warn(
				`Load more messages prevented ${channelId}. isLoading: ${channel?.isLoading}.isLoadingMessages: ${channel?.isLoadingMessages}`,
				channel
			);
			return Promise.resolve();
		}
	}

	/**
	 * @deprecated use channel.groupMembers directly
	 * @param  {String} chatId
	 * @returns {User[] | undefined} users
	 */
	getGroupMembers(chatId: number): User[] {
		return this.findChannel(chatId)?.groupMembers ?? [];
	}

	/**
	 * @param  {String} chatId
	 * @returns {User[]} users
	 */
	getGroupMembersExcludingCurrentUser(chatId: number): User[] {
		const { currentUserId } = this.rootStore.userStore;
		return this.getGroupMembers(chatId).filter((groupMember) => groupMember.userId !== currentUserId);
	}

	/**
	 * @param  {number} channelId
	 * @returns {Channel | undefined} chat
	 */
	findChannel(channelId: number, preventLoad: boolean = true): Channel | undefined {
		const channel = this.channels.find((c: Channel) => '' + c.id == '' + channelId);
		if (!preventLoad && channel) {
			this.loadChannel(channelId).catch(console.warn);
		}

		return channel;
	}

	addChannelsIfNotExists(channels: any[]) {
		if (Array.isArray(channels)) {
			channels.forEach((channel: any) => {
				// this if should not be needed, but just in case...
				if (channel?.id) {
					const existingChannel = this.findChannel(channel.id, true);
					if (!existingChannel) {
						this.updateChannelFromServer(channel, false);
					}
				}
			});
		}
	}

	canLoadChannel(channelId: number): boolean {
		if (!channelId || this.isLoading) {
			console.log(`Can't load channel ${channelId} due to isLoading ${this.isLoading}`);
			return false;
		}

		if (!this.loading.loadChannel) {
			this.loading.loadChannel = {};
		}

		if (this.loading.loadChannel[channelId] + 2500 >= Date.now()) {
			return false;
		}

		return true;
	}

	/**
	 * @param  {String} channelId
	 */
	async loadChannel(channelId: number, loadMessages: boolean = false) {
		if (!this.canLoadChannel(channelId)) {
			return;
		}

		this.loading.loadChannel[channelId] = Date.now();

		const result = await ChatApi.getChannel(channelId).catch((err) => {
			console.warn('Error loading channel', err);
			return { statusCode: 500, data: null };
		});
		if (result.statusCode === 200) {
			const channelJson = result.data;
			this.updateChannelFromServer(channelJson, loadMessages);
		} else {
			console.warn('Error loading channel', result);
			// remove channel from list
			runInAction(() => {
				// the one exception to the rule that we should not remove channels is customer service channels
				const channel = this.findChannel(channelId);
				if (channel?.channelType !== ChannelTypeDTO.CustomerService) {
					this.channels = this.channels.filter((channel) => channel.id !== channelId);
				}
			});
		}
	}

	/**
	 * LoadChats
	 */
	loadChats(): void {
		this.setIsLoading(true);
		ChatApi.getChannels()
			.then((result) => {
				if (result.statusCode === 200) {
					runInAction(() => {
						const channels = result.data;
						channels.forEach((json) => {
							this.updateChannelFromServer(json);
						});
						this.setAppBadge();
					});
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				this.setIsLoading(false);
			});
		// we should not need runInAction here. Something is wrond with the way we call load chats
	}

	setAppBadge = () => {
		if ('setAppBadge' in navigator) {
			try {
				runInAction(() => {
					(navigator as any).setAppBadge(this.unreadCount).catch((err: any) => {
						console.error(err);
					});
				});
			} catch (err) {
				console.error(err);
			}
		}
	};

	clearAppBadge = () => {
		if ('clearAppBadge' in navigator) {
			try {
				(navigator as any).clearAppBadge().catch((err: any) => {
					console.error(err);
				});
			} catch (err) {
				console.error(err);
			}
		}
	};

	/**
	 * @param  {any} json
	 */
	async updateChannelFromServer(json: any, loadMessages: boolean = false): Promise<void> {
		if (!json?.id) {
			console.warn('Invalid channel json', json);
			return;
		}
		let foundChannel = this.findChannel(json.id);

		if (!foundChannel) {
			foundChannel = new Channel(this, json.id);
			this.channels.push(foundChannel);
		}

		foundChannel.updateFromJson(json);

		if (foundChannel?.deleted) {
			// remove channel from list
			this.channels = this.channels.filter((channel) => channel.id !== foundChannel!.id);
			console.log('Channel deleted', foundChannel);
		}

		const memberIds =
			foundChannel?.groupMembers.map((member) => ({
				userId: '' + member.userId,
				workspaceId: '' + json.workspaceId,
			})) ?? [];

		if (memberIds) {
			this.rootStore.profileStore.loadProfiles(memberIds);
		}

		if (foundChannel && loadMessages) {
			await this.loadMessages(foundChannel.id);
		}
	}

	/**
	 * @param {number} channelId
	 * @param  {any} messageJson
	 */
	updateChannelMessagesFromServer(
		channelId: number,
		messageJson: { messages: any[] },
		remove: boolean = false
	): void {
		// TODO Remove chat from channels array if it's deleted (should it even be possible to delete channels?)
		runInAction(() => {
			const foundChannel = this.findChannel(channelId);

			if (foundChannel) {
				if (remove) {
					messageJson.messages.forEach((message: any) => {
						foundChannel.removeMessage(message.messageId);
					});
				} else {
					foundChannel.updateMessagesFromJson(messageJson);
				}

				this.onNewMessage.trigger({
					channelId,
				});
			}
		});
	}

	async createChannel(
		type: ChannelTypeDTO,
		name: string,
		initialMembers: string[] = [],
		projectId?: string,
		agentId?: number
	) {
		const response = await ChatApi.createChannel(type, name, initialMembers, projectId, agentId);
		if (response.statusCode === 200) {
			runInAction(() => {
				this.updateChannelFromServer(response.data);
			});
		}

		return response;
	}

	/**
	 * @deprecated use channel.createMessage
	 * @param  {String} channelId
	 * @param  {String} text
	 */
	async createMessage(channelId: number, text: string, content: MessageContent[] = [], widget?: MessageWidget) {
		const messageDTO: UpsertMessageDTO = {
			content: content,
			text: text,
			type: widget ? MessageType.WIDGET : MessageType.MESSAGE,
			created: new Date(),
			isSeen: 2,
			sender: this.rootStore.profileStore.currentUserProfile!.asJson,
			widget,
			channelId,
		};

		try {
			this.rootStore.profileStore.requestPushPermission();
			const response = await ChatApi.sendMessage(channelId, messageDTO);
			if (response.statusCode === 200) {
				runInAction(() => {
					const foundChannel = this.findChannel(channelId);

					if (foundChannel && response.data?.id) {
						foundChannel.updateMessagesFromJson({ messages: [response.data] });
					}
				});
			}
			await this.markMessagesAsSeen(channelId, true);
		} catch (err) {
			LogUtil.error('Error while trying to send message', err);
		}

		return true;
	}

	/**
	 * @param  {number} channelId
	 * @param {Message} message
	 * @param  {String} reaction
	 */
	async createMessageReaction(channelId: number, message: Message, reaction: string) {
		try {
			await ChatApi.sendReaction(channelId, message, reaction);
		} catch (err) {
			LogUtil.error(err);
		}
	}

	/**
	 * @param  {number} channelId
	 * @param {Message} message
	 * @param  {String} reaction
	 */
	async deleteMessageReaction(channelId: number, message: Message, reaction: string) {
		try {
			await ChatApi.deleteReaction(channelId, message, reaction);
		} catch (err) {
			LogUtil.error(err);
		}
	}

	/**
	 * @deprecated use message.delete
	 * @param  {number} channelId
	 * @param {Message} message
	 */
	async deleteMessage(channelId: number, messageId: number) {
		try {
			const response = await ChatApi.deleteMessage(channelId, messageId);
			if (response.statusCode !== 200) {
				return false;
			}
			const channel = this.findChannel(channelId);
			if (channel) {
				// give it some time to animate that we are removing this message
				setTimeout(() => {
					runInAction(() => {
						channel.removeMessage(messageId);
					});
				}, 500);

				return true;
			}
		} catch (err) {
			LogUtil.error(err);
		}
		return false;
	}

	/**
	 * Mark all messages in given channel as seen
	 * @param channelId
	 */
	async markMessagesAsSeen(channelId: number, force: boolean) {
		try {
			const channel = this.findChannel(channelId);
			let success = false;
			if (channel) {
				// Some throttling on this, but ignore if there is a new message that has beed read
				if (!force && channel.unreadNum < 1) {
					return true;
				}
				// Patchy, but the easiest way to prevent double loads
				runInAction(() => {
					channel.setLastRead(DateUtil.createDateWithTimezoneOffset(new Date().toISOString()));
				});
				const response = await ChatApi.markChannelAsRead(channelId);
				if (response.statusCode === 200) {
					success = true;
					runInAction(() => {
						channel.setLastRead(new Date());
						channel.setMembersLastRead(response.data?.lastRead);
					});

					runInAction(() => this.setAppBadge);
				}
			}

			return success;
		} catch (err) {
			LogUtil.error(err);
		}
	}

	async addVideoChatHighlight(channelId: number, file: any, duration?: number, projectId?: string) {
		try {
			// @todo: fix this properly. Should store this in a better way that a chat message
			// however a chat message takes us 60-70% there so this is ok-ish i guess :/
			const chatMessageContent: any[] = [];
			chatMessageContent.push({
				contentType: 'file',
				file: file,
				comment: `Høydepunkt fra ${duration ? Math.round(duration / 1000) : '-'}s inn i samtalen. ${projectId}`,
			});
			return this.createMessage(channelId, 'Høydepunkt fra videoChat', chatMessageContent);
		} catch (err) {
			LogUtil.error('Error adding highlight to chat', err);
			return null;
		}
	}
}

/**
 * CHAT
 * Domain object
 */
export class Channel {
	static readonly TYPING_TTL = 2500; // ms
	static readonly PEEKING_TTL = 10000; // ms

	draftMessage: Message = new Message({
		type: MessageType.MESSAGE,
		text: '',
		created: new Date(),
	});

	isLoading: boolean = false;
	isLoadingMessages: boolean = false;
	lastUpdated: number = 0;
	id: number;
	groupMembers: User[] = [];
	_messages: Message[] = [];
	numMessages: number = 0;
	numUnreadMessagesFromServer: number = 0;
	blockLoadMoreMessages: boolean = false;
	lastMessageFetch: number = 0;
	nextMessageSkip: number = 0;
	channelType: ChannelTypeDTO = ChannelTypeDTO.Public;
	created: Date = new Date();
	updated: Date = new Date();
	lastRead: Date = new Date('01-01-1970');
	membersLastRead: Record<number, Date> = {};
	deleted: Date | undefined;
	description: string = '';
	name: string = '';
	topic: string = '';
	workspaceId: string = '';
	firstMessageLoadDone: boolean = false;
	store: ChatStore;
	autoSave: boolean = false; // Indicator for submitting changes in this Chat to the server
	saveHandler: IReactionDisposer; // Disposer of the side effect auto-saving this Chat (dispose)
	lastUpdateState: any = null; // Last state of the Channel before the last update
	typing: any[] = [];
	peeking: any[] = [];
	tickerTimeout: any;

	TICKER_MS: number = 5000;

	isSendingMessage: boolean = false;
	isInitiated: boolean = false;
	aiEnabled: boolean = false;
	hitl: boolean = false;
	agentId?: number;
	quickActions: QuickAction[] = [];
	dynamicView?: DynamicChannelView;

	constructor(store: ChatStore, id: number) {
		makeAutoObservable(this, {
			id: false,
			tickerTimeout: false,
			store: false,
			updateFromJson: action,
		});

		this.id = +id;
		this.store = store;
		this.resetDraftMessage();

		this.ticker = this.ticker.bind(this);

		this.saveHandler = reaction(
			() => {
				return Object.values(this.asJson);
			}, // Observe everything that is used in the JSON
			debounce(() => {
				// If autoSave is true, send JSON to the server
				this.runAutoSave();
			}, 1000)
		);
	}

	setDynamicView(view: DynamicChannelView | undefined) {
		this.dynamicView = view;
	}

	clearDynamicView() {
		this.dynamicView = undefined;
	}

	get asJson() {
		return {
			id: this.id,
			name: this.name,
			topic: this.topic,
			description: this.description,
			aiEnabled: this.aiEnabled,
		};
	}

	get messages() {
		// filter out deleted messages
		return this._messages
			.filter((message) => !message.deleted)
			.sort((a, b) => {
				const aTimestamp: Date = new Date(a.created);
				const bTimestamp: Date = new Date(b.created);

				if (aTimestamp > bTimestamp) {
					return 1;
				}
				if (aTimestamp < bTimestamp) {
					return -1;
				}

				return 0;
			});
	}

	get mostRecentMessage(): Message | undefined {
		if (this.messages.length === 0) {
			return undefined;
		}
		return this.messages[this.messages.length - 1];
	}

	runAutoSave() {
		const isChanged = JSON.stringify(this.asJson) !== JSON.stringify(this.lastUpdateState);

		if (this.autoSave && this.isInitiated && isChanged) {
			console.info('Auto-saving channel', this.asJson);
			this.save();
		}
	}

	save() {
		ChatApi.updateChannel(this.asJson).catch((err) => {
			LogUtil.warn('Error while trying to update channel', err);
		});
	}

	resetDraftMessage() {
		this.draftMessage = new Message({
			type: MessageType.MESSAGE,
			text: '',
			created: new Date(),
		});
	}

	setIsLoading(loading: boolean = true) {
		this.isLoading = loading;
	}

	setIsLoadingMessages(loading: boolean = true) {
		// @todo revisit to find a more elegant way of throttling this
		if (loading === false) {
			setTimeout(() => {
				runInAction(() => {
					this.isLoadingMessages = loading;
				});
			}, 1000);
		} else {
			runInAction(() => {
				this.isLoadingMessages = loading;
			});
		}
	}

	setLastRead(date: Date) {
		this.lastRead = date;
	}

	setMembersLastRead(lastRead: Record<string, string>) {
		try {
			if (lastRead && Object.keys(lastRead).length > 0) {
				Object.keys(lastRead).forEach((key) => {
					this.membersLastRead[+key] = DateUtil.createDateWithTimezoneOffset(lastRead[key]);
				});
			}
		} catch (err) {
			// do nothing
			console.warn('Error setting membersLastRead', err);
		}
	}

	getMembersThatHasReadTheMessage(message: Message) {
		const created = message.created;
		const members: number[] = [];
		if (this.membersLastRead && Object.keys(this.membersLastRead).length > 0) {
			Object.keys(this.membersLastRead).forEach((key) => {
				const value = this.membersLastRead[+key];
				if (value >= created) {
					members.push(+key);
				}
			});
		}
		return members;
	}

	removeMessage(messageId: number) {
		const index = this.findMessageIndex(messageId);
		if (index >= 0) {
			this.messages.splice(index, 1);
		}

		this.lastUpdated = Date.now();
	}

	get unreadNum(): number {
		if (!Array.isArray(this.messages)) {
			return 0;
		}

		const aiMessageTypes: MessageType[] = [MessageType.AI_MESSAGE, MessageType.AI_STATUS_MESSAGE];
		const unreadMessages = this.messages.filter(
			(message: Message) =>
				!aiMessageTypes.includes((message.type ?? '') as MessageType) && message.created > this.lastRead
		);
		// we only load the first message on channel load
		// in that case there might be more than one unread
		if (
			this.messages.length < this.numMessages &&
			this.numUnreadMessagesFromServer > unreadMessages.length &&
			unreadMessages.length > 0
		) {
			return this.numUnreadMessagesFromServer;
		}

		return Array.isArray(unreadMessages) ? unreadMessages.length : 0;
	}

	ticker() {
		runInAction(() => {
			clearTimeout(this.tickerTimeout);
			const typing = this.typing.filter((t) => t.timestamp > Date.now() - Channel.TYPING_TTL);
			const peeking = this.peeking.filter((p) => p.timestamp > Date.now() - Channel.PEEKING_TTL);

			if (typing !== this.typing) {
				this.typing = typing;
			}

			if (peeking !== this.peeking) {
				this.peeking = peeking;
			}

			if (this.typing.length > 0 || this.peeking.length > 0) {
				this.tickerTimeout = setTimeout(this.ticker, this.TICKER_MS);
			}
		});
	}

	/**
	 * @param  {String} messageId
	 * @param  {String} userId
	 * @param  {String} reaction
	 * @param {String} reactionId
	 * @param  {Boolean} add
	 */
	updateReaction(messageId: number, userId: string, reaction: string, reactionId?: string, add: boolean = true) {
		const message = this.findMessage(+messageId);
		if (!message) {
			return;
		}

		const reactionObj = {
			id: reactionId ?? '' + Date.now(),
			name: reaction,
			reaction,
			userId,
		};
		if (add) {
			message.addReaction(reactionObj);
		} else {
			message.removeReaction({
				id: reactionId,
				reaction,
				userId,
			});
		}

		this.lastUpdated = Date.now();
	}

	updateGroupMembers(members: any[]) {
		// use  set  to remove duplicates
		const memberIds = new Set(members.map((member) => '' + member.userId));
		this.groupMembers = Array.from(memberIds).map((userId) => ({
			userId: userId,
		}));
	}

	/**
	 * @param  {any} json
	 */
	updateFromJson(json: any): void {
		this.autoSave = false; // Prevent sending of our changes back to the servers
		this.updateGroupMembers(json.groupMembers || []);
		this.channelType = json.channelType || 'PRIVATE';
		this.created = json.created ? DateUtil.createDateWithTimezoneOffset(json.created) : new Date();
		this.updated = json.updated ? DateUtil.createDateWithTimezoneOffset(json.updated) : new Date();
		this.deleted = json.deleted ? DateUtil.createDateWithTimezoneOffset(json.deleted) : undefined;
		this.description = json.description || '';
		this.name = json.name || '';
		this.topic = json.topic || '';
		this.workspaceId = json.workspaceId || '';
		this.updateMessagesFromJson(json);
		if (json.lastRead) {
			const newLastRead = DateUtil.createDateWithTimezoneOffset(json.lastRead);
			this.lastRead = this.lastRead < newLastRead ? newLastRead : this.lastRead;
		}
		this.numUnreadMessagesFromServer = json.numUnread;
		this.numMessages = json.numMessages;
		this.lastUpdated = Date.now();
		this.aiEnabled = json.aiEnabled || false;
		this.hitl = json.hitl || false;
		this.agentId = json.agentId;

		// This is should not be needed, it's a patch for auto-save
		this.lastUpdateState = this.asJson;
		this.isInitiated = true;
		this.autoSave = true;
	}

	/**
	 * Update typing status
	 * @param {String} userId
	 */
	updateTyping(userId: string) {
		const index = this.findTypingIndex(userId);
		if (index >= 0) {
			this.typing[index].timestamp = Date.now();
		} else {
			this.typing.push({
				userId: userId,
				timestamp: Date.now(),
			});
		}

		this.ticker();
	}

	/**
	 * Update peeking status
	 * @param {String} userId
	 */
	updatePeeking(userId: string) {
		const index = this.findPeekingIndex(userId);
		if (index >= 0) {
			this.peeking[index].timestamp = Date.now();
		} else {
			this.peeking.push({
				userId: userId.toString(),
				timestamp: Date.now(),
			});
		}

		this.ticker();
	}

	/**
	 * @param  {any} json
	 */
	updateMessagesFromJson(json: any) {
		if (!json.messages) {
			return;
		}

		json.messages.forEach((message: any) => {
			this.addMessage(message);
		});
		this.nextMessageSkip = json.next || 0;
		this.lastMessageFetch = Date.now();
		this.lastUpdated = Date.now();
	}

	/**
	 * @param messageId
	 * @returns {Message | undefined} message
	 */
	findMessage(messageId: number): Message | undefined {
		return this.messages.find((message: Message) => message.id === messageId);
	}

	/**
	 * @param messageId
	 * @returns {number} index
	 */
	findMessageIndex(messageId: number): number {
		return this.messages.findIndex((message: Message) => message.id === messageId);
	}

	/**
	 * @param messageIndex
	 * @param userId
	 * @param reaction
	 * @returns {number} index
	 */
	findMessageReactionIndex(messageId: number, userId: string, reaction: string): number {
		const message = this.findMessage(messageId);
		if (!message) {
			return -1;
		} else {
			return (message.reactions ?? []).findIndex(
				(react) => react.userId === userId && react.reaction === reaction
			);
		}
	}

	/**
	 * @param userId
	 * @returns {number} index
	 */
	findTypingIndex(userId: string): number {
		return this.typing.findIndex((typing) => typing.userId === userId);
	}

	/**
	 * @param userId
	 * @returns {number} index
	 */
	findPeekingIndex(userId: string): number {
		return this.peeking.findIndex((peeking) => peeking.userId === userId.toString());
	}

	/**
	 * Adds or updates message
	 * @param message
	 */
	addMessage(message: MessageDTO) {
		runInAction(() => {
			if (!message.id) {
				console.warn('tried to add a message without id', { message });
				return;
			}

			message.id = +message.id;
			const index = this.findMessageIndex(message.id);
			if (index >= 0) {
				this.messages[index].updateFromDTO(message);
			} else {
				this._messages.push(this.createMessageFromDTO(message));
			}
		});
	}

	/**
	 * @param  {any} message
	 * @returns {Message} message
	 */
	createMessageFromDTO(message: MessageDTO) {
		const newMessage = new Message(message);
		return newMessage;
	}

	async sendCustomerServiceMessage(sessionId: string) {
		const response = await CustomerServiceApi.sendMessage(sessionId, this.draftMessage.text, {
			channelId: this.id,
			name: this.name,
			topic: this.topic,
			description: this.description,
			content: this.draftMessage.content,
		});

		if (response.statusCode === 200) {
			runInAction(() => {
				this.addMessage(response.data);
			});
		} else {
			LogUtil.warn('Error while trying to send customer service message', response);
		}
	}

	async sendDraftMessage() {
		if (this.draftMessage.id) {
			this.draftMessage
				.save()
				.then(() => {
					this.resetDraftMessage();
				})
				.catch((err) => {
					LogUtil.error('Error while trying to send message', err);
					toast('Fikk ikke sendt meldingen');
				});

			return;
		}
		await this.createMessage(this.draftMessage.text, this.draftMessage.content, this.draftMessage.widget)
			.catch((err) => {
				LogUtil.error('Error while trying to send message', err);
				toast('Fikk ikke sendt meldingen');
			})
			.then(() => {
				this.resetDraftMessage();
			});
	}

	/**
	 * @param  {String} channelId
	 * @param  {String} text
	 */
	async createMessage(text: string, content: MessageContent[] = [], widget?: MessageWidget) {
		const message: Partial<MessageDTO> = {
			content: content,
			text: text,
			type: widget ? MessageType.WIDGET : MessageType.MESSAGE,
			widget,
		};

		const response = await ChatApi.sendMessage(this.id, message);
		if (response.statusCode === 200) {
			runInAction(() => {
				this.addMessage(response.data);
			});

			await this.markMessagesAsSeen(true);
			return true;
		}
		await this.markMessagesAsSeen(true);

		return false;
	}

	/**
	 * Mark all messages in given channel as seen
	 * @param channelId
	 */
	async markMessagesAsSeen(force?: boolean) {
		try {
			const lastMessage = this.mostRecentMessage;
			const lastMessageMoreRecentThanLastRead =
				lastMessage?.created && this.lastRead
					? lastMessage?.created?.getTime() > this.lastRead?.getTime()
					: (this.lastRead?.getTime() || 0) + 10000 > Date.now();
			// Some throttling on this, but ignore if there is a new message that has beed read
			if (!force && !lastMessageMoreRecentThanLastRead) {
				return true;
			}
			// Patchy, but the easiest way to prevent double loads
			const isoNow = DateUtil.createDateWithTimezoneOffset(new Date().toISOString());
			runInAction(() => {
				this.setLastRead(isoNow);
			});
			const response = await ChatApi.markChannelAsRead(this.id);
			if (response.statusCode === 200) {
				runInAction(() => {
					this.setLastRead(isoNow);
					this.setMembersLastRead(response.data?.lastRead);
				});
			}
		} catch (err) {
			LogUtil.error(err);
		}
	}

	isMember(userId: string) {
		return Boolean(this.groupMembers.find((member) => '' + member.userId === '' + userId));
	}

	async fetchMessages(limit: number = 25) {
		if (this.isLoadingMessages) {
			return;
		}
		if (this.blockLoadMoreMessages) {
			return;
		}

		this.setIsLoadingMessages(true);

		// Set this immediately to avoid spamming
		this.firstMessageLoadDone = true;
		return ChatApi.getMessages(this.id, this.messages.length, limit)
			.then((messageJson: any) => {
				this.updateMessagesFromJson(messageJson);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				this.setIsLoading(false);
				this.setIsLoadingMessages(false);
			});
	}

	async archive() {
		const response = await ChatApi.archiveChannel(this.id);
		if (response.statusCode === 200) {
			runInAction(() => {
				this.deleted = new Date();
			});
		}
	}

	updateQuickActions(quickActions: QuickAction[]) {
		console.log('updateQuickActions', this.id, quickActions);
		this.quickActions = quickActions;
	}

	onQuickActionClick(action: QuickAction) {
		// remove the action from the list
		const index = this.quickActions.findIndex((qa) => qa.type === action.type && qa.text === action.text);
		if (index >= 0) {
			this.quickActions.splice(index, 1);
		}
	}

	get lastMessage() {
		if (this.messages.length > 0) {
			return this.messages[this.messages.length - 1];
		}

		return null;
	}
}

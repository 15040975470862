import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import MuiChip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import SmartphoneRoundedIcon from '@mui/icons-material/SmartphoneRounded';
import CloudSyncRoundedIcon from '@mui/icons-material/CloudSyncRounded';
// import SecurityRoundedIcon from "@mui/icons-material/SecurityRounded";

const items = [
	{
		icon: <CloudSyncRoundedIcon />,
		title: 'Plattformuavhengig',
		description: 'Fungerer på alle enheter og operativsystemer, slik at du kan jobbe fra hvor som helst.',
		imageLight: 'url(/images/smartchat/kanban.png)',
		imageDark: 'url(/images/smartchat/kanban.png)',
	},
	{
		icon: <SmartphoneRoundedIcon />,
		title: 'Mobilvennlig',
		description: 'Gi kundene dine en sømløs opplevelse på mobil med optimalisert responsiv design.',
		imageLight: 'url(/images/smartchat/chat-mob.png)',
		imageDark: 'url(/images/smartchat/chat-mob.png)',
	},
	{
		icon: <DashboardRoundedIcon />,
		title: 'Innsiktsdashboard',
		description:
			'Få en sanntidsoversikt over de viktigste nøkkeltallene, inkludert brukeratferd og konverteringer.',
		imageLight: 'url(/images/smartchat/insight.png)',
		imageDark: 'url(/images/smartchat/insight.png)',
	},

	// {
	// 	icon: <SecurityRoundedIcon />,
	// 	title: "Datasikkerhet",
	// 	description:
	// 		"Vi prioriterer sikkerhet med robust kryptering og kontinuerlige oppdateringer for å beskytte dine data.",
	// 		imageLight: "url(/images/smartchat/insight.png)",
	// 		imageDark: "url(/images/smartchat/insight.png)",
	// },
];

interface ChipProps {
	selected?: boolean;
}

const Chip = styled(MuiChip)<ChipProps>(({ theme }) => ({
	variants: [
		{
			props: ({ selected }) => selected,
			style: {
				background: 'linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))',
				color: 'hsl(0, 0%, 100%)',
				borderColor: theme.palette.primary.light,
				'& .MuiChip-label': {
					color: 'hsl(0, 0%, 100%)',
				},
				...theme.applyStyles('dark', {
					borderColor: theme.palette.primary.dark,
				}),
			},
		},
	],
}));

interface MobileLayoutProps {
	selectedItemIndex: number;
	handleItemClick: (index: number) => void;
	selectedFeature: (typeof items)[0];
}

export function MobileLayout({ selectedItemIndex, handleItemClick, selectedFeature }: MobileLayoutProps) {
	if (!items[selectedItemIndex]) {
		return null;
	}

	return (
		<Box
			sx={{
				display: { xs: 'flex', sm: 'none' },
				flexDirection: 'column',
				gap: 2,
			}}
		>
			<Box sx={{ display: 'flex', gap: 2, overflow: 'auto', flexWrap: 'wrap' }}>
				{items.map(({ title }, index) => (
					<Chip
						size="medium"
						key={index}
						label={title}
						onClick={() => handleItemClick(index)}
						selected={selectedItemIndex === index}
					/>
				))}
			</Box>
			<Card variant="outlined">
				<Box
					sx={(theme) => ({
						mb: 2,
						backgroundSize: 'contain',
						backgroundPosition: 'center',
						minHeight: 280,
						backgroundRepeat: 'no-repeat',
						backgroundImage: 'var(--items-imageLight)',
						...theme.applyStyles('dark', {
							backgroundImage: 'var(--items-imageDark)',
						}),
					})}
					style={
						items[selectedItemIndex]
							? ({
									'--items-imageLight': items[selectedItemIndex].imageLight,
									'--items-imageDark': items[selectedItemIndex].imageDark,
								} as any)
							: {}
					}
				/>
				<Box sx={{ px: 2, pb: 2 }}>
					<Typography gutterBottom sx={{ color: 'text.primary', fontWeight: 'medium' }}>
						{selectedFeature.title}
					</Typography>
					<Typography variant="body2" sx={{ color: 'text.secondary', mb: 1.5 }}>
						{selectedFeature.description}
					</Typography>
				</Box>
			</Card>
		</Box>
	);
}

export default function Features() {
	const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

	const handleItemClick = (index: number) => {
		setSelectedItemIndex(index);
	};

	const selectedFeature = items[selectedItemIndex];

	return (
		<Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
			<Box sx={{ width: { sm: '100%', md: '60%' } }}>
				<Typography component="h2" variant="h4" gutterBottom sx={{ color: 'text.primary' }}>
					Nøkkelfunksjoner
				</Typography>
				<Typography variant="body1" sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}>
					Få en oversikt over de viktigste funksjonene i produktet. Her kan du se hvordan de kan gi verdi,
					forenkle prosesser og bidra til bedre resultater.
				</Typography>
			</Box>

			<Box
				sx={{
					display: 'flex',
					flexDirection: { xs: 'column', md: 'row-reverse' },
					gap: 2,
				}}
			>
				<div>
					<Box
						sx={{
							display: { xs: 'none', sm: 'flex' },
							flexDirection: 'column',
							gap: 2,
							height: '100%',
						}}
					>
						{items.map(({ icon, title, description }, index) => (
							<Box
								key={index}
								component={Button}
								onClick={() => handleItemClick(index)}
								sx={[
									(theme) => ({
										p: 2,
										height: '100%',
										width: '100%',
										'&:hover': {
											backgroundColor: theme.palette.action.hover,
										},
									}),
									selectedItemIndex === index && {
										backgroundColor: 'action.selected',
									},
								]}
							>
								<Box
									sx={[
										{
											width: '100%',
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'left',
											gap: 1,
											textAlign: 'left',
											textTransform: 'none',
											color: 'text.secondary',
										},
										selectedItemIndex === index && {
											color: 'text.primary',
										},
									]}
								>
									{icon}

									<Typography variant="h6">{title}</Typography>
									<Typography variant="body2">{description}</Typography>
								</Box>
							</Box>
						))}
					</Box>
					<MobileLayout
						selectedItemIndex={selectedItemIndex}
						handleItemClick={handleItemClick}
						selectedFeature={selectedFeature}
					/>
				</div>
				<Box
					sx={{
						display: { xs: 'none', sm: 'flex' },
						width: { xs: '100%', md: '70%' },
						height: 'var(--items-image-height)',
					}}
				>
					<Card
						variant="outlined"
						sx={{
							height: '100%',
							width: '100%',
							display: { xs: 'none', sm: 'flex' },
							pointerEvents: 'none',
						}}
					>
						<Box
							sx={(theme) => ({
								m: 'auto',
								width: 420,
								height: 500,
								backgroundSize: 'contain',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								backgroundImage: 'var(--items-imageLight)',
								...theme.applyStyles('dark', {
									backgroundImage: 'var(--items-imageDark)',
								}),
							})}
							style={
								items[selectedItemIndex]
									? ({
											'--items-imageLight': items[selectedItemIndex].imageLight,
											'--items-imageDark': items[selectedItemIndex].imageDark,
										} as any)
									: {}
							}
						/>
					</Card>
				</Box>
			</Box>
		</Container>
	);
}

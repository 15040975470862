import { alpha, Avatar, Box, Stack, Typography } from '@mui/material';
import React from 'react';

export type FeatureSectionProps = {
	icon: React.ReactNode;
	title: string;
	description: string;
};

export default class FeatureSection extends React.Component<FeatureSectionProps> {
	render() {
		const { icon, title, description } = this.props;
		return (
			<Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
				<Avatar
					sx={(theme) => ({
						width: theme.spacing(7),
						height: theme.spacing(7),
						fontSize: theme.typography.h3.fontSize,
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,
						background: `radial-gradient(circle, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
						boxShadow: `0 4px 8px ${alpha(theme.palette.primary.light, 0.2)}`,
					})}
				>
					{icon}
				</Avatar>
				<Typography variant="h6" sx={{ textAlign: 'center' }} color="primary">
					{title}
				</Typography>
				<Typography variant="body1" sx={{ textAlign: 'center' }} color="text.secondary">
					{description}
				</Typography>
			</Stack>
		);
	}
}

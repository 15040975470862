import { Action, Address } from '../../../types';
import {
	Button,
	CardActions,
	CardContent,
	CardMedia,
	Chip,
	Stack,
	StyledComponentProps,
	Typography,
} from '@mui/material';
import { computed, makeObservable } from 'mobx';

import { Company } from '../../../stores/company/Company';
import CompanyContactInformation from './CompanyContactInformation';
import CompanyEmployeeChips from './CompanyEmployeeChips';
import CompanyLogo from '../CompanyLogo';
import CompanyName from './CompanyName';
import ElevatedCard from '../ElevatedCard';
import MapFrame from '../MapFrame';
import React from 'react';
import { RootStore } from '../../../stores/RootStore';
import StoreContext from '../../../stores/StoreContext';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';

const PREFIX = 'CompanyCard';

const classes = {
	root: `${PREFIX}-root`,
	logo: `${PREFIX}-logo`,
	cardActions: `${PREFIX}-cardActions`,
};

const StyledElevatedCard = styled(ElevatedCard)(({ theme: { breakpoints } }) => ({
	[`&.${classes.root}`]: {
		[breakpoints.up('sm')]: {
			display: 'flex',
		},
		width: '100%',
		height: '100%',
	},

	[`& .${classes.logo}`]: {
		maxWidth: 200,
		maxHeight: 32,
		marginBottom: 4,
	},

	[`& .${classes.cardActions}`]: {
		paddingLeft: 16,
		paddingBottom: 12,
	},
}));

type Props = StyledComponentProps & {
	company: Company;
	hideMap?: boolean;
	hidePhoneNumber?: boolean;
	hideEmailAddress?: boolean;
	hideEmployees?: boolean;
	destination?: Address;
	buttons?: {
		primaryButton: Action;
		secondaryButton?: Action;
	};
	className?: string;
	plan?: string;
	ingress?: string;
	showPricingPlan?: boolean;
};

const CompanyCard = observer(
	class CompanyCard extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				rootStore: computed,
				pricingPlanStore: computed,
				address: computed,
				plan: computed,
				ingress: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get pricingPlanStore() {
			return this.rootStore.pricingPlanStore;
		}

		get address() {
			const { company } = this.props;

			const address: Address = {
				street: company.address,
				postCode: company.postalCode,
				postArea: company.city,
			};

			return address;
		}

		get plan() {
			return this.props.plan || this.props.company.plan;
		}
		get ingress() {
			if (this.plan == 'Basic') {
				return '';
			}

			return this.props.ingress || this.props.company.ingress;
		}

		render() {
			const {
				className,
				company,
				hideMap,
				hidePhoneNumber,
				hideEmailAddress,
				hideEmployees,
				buttons,
				showPricingPlan,
			} = this.props;

			return (
				<StyledElevatedCard className={`CompanyCard ${classes.root} ${className}`}>
					{!hideMap && (
						<CardMedia>
							<MapFrame address={this.address} destination={this.props.destination} height="100%" />
						</CardMedia>
					)}

					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div style={{ flexGrow: 1 }}>
							<CardContent>
								<Stack direction="column" spacing={1}>
									<CompanyLogo className={classes?.logo} company={company} />
									<CompanyName company={company} />
									{this.ingress && (
										<Typography variant="caption" color="primary">
											<strong>{this.ingress}</strong>
										</Typography>
									)}
									<CompanyContactInformation
										company={company}
										hideEmailAddress={hideEmailAddress}
										hidePhoneNumber={hidePhoneNumber}
									/>

									{showPricingPlan && this.plan && (
										<Chip label={this.plan} color="primary" variant="outlined" />
									)}

									{!hideEmployees && this.plan === 'Elite' && (
										<div style={{ marginTop: '2px' }}>
											<CompanyEmployeeChips company={company} />
										</div>
									)}
								</Stack>
							</CardContent>
						</div>

						{buttons && (
							<CardActions className={classes?.cardActions}>
								<Button color="primary" onClick={buttons.primaryButton.onClick} variant="contained">
									{buttons.primaryButton.label}
								</Button>
								{buttons.secondaryButton && (
									<Button
										color="secondary"
										variant="outlined"
										onClick={buttons.secondaryButton.onClick}
									>
										{buttons.secondaryButton.label}
									</Button>
								)}
							</CardActions>
						)}
					</div>
				</StyledElevatedCard>
			);
		}
	}
);

export default CompanyCard;

import { makeAutoObservable, runInAction } from 'mobx';
import CampaignApi from '../../api/endpoints/CampaignApi';
import {
	BudgetCurrencyEnum,
	CampaignAssignableWorkspaceDTO,
	CampaignDTO,
	CampaignDynamicStatusReason,
	CampaignFAQDTO,
	CampaignLeadDTO,
	CampaignStatusEnum,
	CampaignTypeEnum,
	DeepStatsDTO,
	ShallowStatsDTO,
} from '../../dto/campaign.types';
import CampaignLead from './CampaignLead';
import ChatWidgetConfig from '../ChatWidget';
import { CampaignHookConfig, CreateDBHook, DBHook } from '../../Hook.types';

export default class Campaign implements CampaignDTO {
	// types from campaignDTO
	id: number;
	created: Date = new Date();
	updated: Date = new Date();
	deleted?: Date;
	name: string;
	startTime: Date = new Date();
	endTime: Date = new Date();
	publishTime: Date = new Date();
	budget: number;
	budgetCurrency: BudgetCurrencyEnum = BudgetCurrencyEnum.NOK;
	workspaceId: number;
	status: CampaignStatusEnum;
	description?: string;
	url?: string;
	type: CampaignTypeEnum = CampaignTypeEnum.Basic;
	goal?: string | undefined;

	costPerClick: number = 0;
	costPerConversion: number = 25;
	costPerResolution: number = 10;

	showListing: boolean = false; // Whether the campaign should be listed
	listingDetails?: string; // Detailed info for chain members
	listingTerms?: string; // Terms for joining the campaign
	limitToChildrenOfWorkspaceId?: number | null; // Restrict listing to child workspaces
	acceptanceDeadline?: Date | null; // Deadline for accepting the campaign
	isMandatory?: boolean; // Whether it's mandatory to join

	// more things here

	leads: CampaignLead[] = [];
	shallowStats: ShallowStatsDTO | null = null;
	deepStats: DeepStatsDTO | null = null;
	faqs: CampaignFAQDTO[] = [];
	chatWidgetConfig?: ChatWidgetConfig;
	assignableWorkspaces: CampaignAssignableWorkspaceDTO[] = [];

	dynamicStatus: CampaignStatusEnum;
	dynamicStatusReasons: CampaignDynamicStatusReason[];

	hooks: DBHook<CampaignHookConfig>[] = [];

	constructor(dto: CampaignDTO) {
		// happy lint
		this.id = dto.id ?? 0;
		this.name = dto.name;
		this.budget = dto.budget ?? 0;
		this.workspaceId = dto.workspaceId;
		this.status = dto.status;
		this.dynamicStatus = dto.dynamicStatus;
		this.dynamicStatusReasons = dto.dynamicStatusReasons;
		this.updateFromDTO(dto);

		makeAutoObservable(this);

		if (this.id && this.id > 0) {
			if (!this.shallowStats) {
				this.getStats().catch(console.warn);
			}

			if (this.id) {
				this.getLeads().catch(console.warn);
			}
		}
	}

	get numConversionsGoal(): number {
		// based on costPerConversion and budget
		// 25% of budget / costPerConversion = numConversionsGoal
		return Math.ceil((this.budget * 0.4) / this.costPerConversion);
	}

	get numResolutionsGoal(): number {
		// based on costPerResolution and budget
		// 75% of budget / costPerResolution = numResolutionsGoal
		return Math.ceil((this.budget * 0.6) / this.costPerResolution);
	}

	get numClicksGoal(): number {
		// should be 50% higher than numResolutionsGoal
		return Math.ceil(this.numResolutionsGoal * 1.5);
	}

	public addOrUpdateLead(lead: CampaignLeadDTO): void {
		const existingLead = this.leads.find((l) => l.id === lead.id);
		if (existingLead) {
			existingLead.updateFromDTO(lead);
		} else {
			this.leads.push(new CampaignLead(lead));
		}
	}

	get mostRecentLeads(): CampaignLead[] {
		return this.leads.slice().sort((a, b) => {
			if (a.id && b.id) {
				return b.id - a.id;
			}
			return 0;
		});
	}

	public getMostRecentLeads(count: number): CampaignLead[] {
		return this.mostRecentLeads.slice(0, count);
	}

	/**
	 * Update campaign properties from a DTO
	 * @param {Partial<CampaignDTO>} dto
	 */
	public updateFromDTO(dto: Partial<CampaignDTO>): void {
		this.id = dto.id ?? this.id;
		this.created = new Date(dto.created ?? this.created);
		this.updated = new Date(dto.updated ?? this.updated);
		this.deleted = dto.deleted ?? this.deleted;
		this.name = dto.name ?? this.name;
		this.startTime = new Date(dto.startTime ?? this.startTime);
		this.endTime = new Date(dto.endTime ?? this.endTime);
		this.publishTime = new Date(dto.publishTime ?? this.publishTime);
		this.budget = dto.budget ?? this.budget;
		this.budgetCurrency = dto.budgetCurrency ?? this.budgetCurrency;
		this.workspaceId = dto.workspaceId ?? this.workspaceId;
		this.status = dto.status ?? this.status;
		this.description = dto.description ?? this.description;
		this.goal = dto.goal ?? this.goal;

		this.type = dto.type ?? this.type;

		this.url = dto.url ?? this.url;
		this.costPerClick = dto.costPerClick ?? this.costPerClick;
		this.costPerConversion = dto.costPerConversion ?? this.costPerConversion;
		this.costPerResolution = dto.costPerResolution ?? this.costPerResolution;
		// more things here
		this.showListing = dto.showListing ?? this.showListing;
		this.listingDetails = dto.listingDetails ?? this.listingDetails;
		this.listingTerms = dto.listingTerms ?? this.listingTerms;
		this.limitToChildrenOfWorkspaceId = dto.limitToChildrenOfWorkspaceId ?? this.limitToChildrenOfWorkspaceId;
		this.acceptanceDeadline = dto.acceptanceDeadline ? new Date(dto.acceptanceDeadline) : this.acceptanceDeadline;
		this.isMandatory = dto.isMandatory ?? this.isMandatory;

		this.dynamicStatus = dto.dynamicStatus ?? this.dynamicStatus;
		this.dynamicStatusReasons = dto.dynamicStatusReasons ?? [];

		if (dto.chatWidgetConfigDto) {
			this.chatWidgetConfig = new ChatWidgetConfig(dto.chatWidgetConfigDto);
		}
	}

	get asDTO(): CampaignDTO {
		return {
			id: this.id,
			created: this.created,
			updated: this.updated,
			deleted: this.deleted,
			name: this.name,
			startTime: this.startTime,
			endTime: this.endTime,
			publishTime: this.publishTime,
			budget: this.budget,
			budgetCurrency: this.budgetCurrency,
			workspaceId: this.workspaceId,
			status: this.status,
			description: this.description,
			url: this.url,
			type: this.type,
			goal: this.goal,
			costPerClick: this.costPerClick,
			costPerConversion: this.costPerConversion,
			costPerResolution: this.costPerResolution,
			showListing: this.showListing,
			listingDetails: this.listingDetails,
			listingTerms: this.listingTerms,
			limitToChildrenOfWorkspaceId: this.limitToChildrenOfWorkspaceId,
			acceptanceDeadline: this.acceptanceDeadline,
			isMandatory: this.isMandatory,
			// numConversionsGoal: this.numConversionsGoal,
			// numResolutionsGoal: this.numResolutionsGoal,
			// numClicksGoal: this.numClicksGoal,
			chatWidgetConfigDto: this.chatWidgetConfig?.asDTO,
			dynamicStatus: this.dynamicStatus,
			dynamicStatusReasons: this.dynamicStatusReasons,
		};
	}

	async getStats(includeTest: boolean = false): Promise<void> {
		const result = await CampaignApi.getCampaignStatistics(this.id, includeTest);
		if (result.statusCode === 200) {
			const { stats } = result.data;
			runInAction(() => {
				this.shallowStats = stats.shallowStats;
				this.deepStats = stats.deepStats;
				this.faqs = stats.faqs;
			});
		}
	}

	async getLeads(): Promise<void> {
		const result = await CampaignApi.getLeads(this.id);
		if (result.statusCode === 200) {
			runInAction(() => {
				result.data.leads.forEach((lead) => {
					this.addOrUpdateLead(lead);
				});
			});
		}
	}

	async save(): Promise<void> {
		console.log(`about to save campaign ${this.id}`, { theme: this.chatWidgetConfig?.theme });
		const result = await CampaignApi.updateCampaign(this.asDTO);
		if (result.statusCode === 200) {
			runInAction(() => {
				this.updateFromDTO(result.data);
			});
		}
	}

	async pause(): Promise<void> {
		this.status = CampaignStatusEnum.Paused;
		await this.save();
	}

	async start(): Promise<void> {
		this.status = CampaignStatusEnum.Active;
		await this.save();
	}

	async getAssignableWorkspaces(): Promise<void> {
		const result = await CampaignApi.getAssignableWorkspaces(this.id);
		if (result.statusCode === 200) {
			runInAction(() => {
				this.assignableWorkspaces = result.data;
			});
		}
	}

	async addEmbedding(embeddingId: number): Promise<boolean> {
		const result = await CampaignApi.addEmbedding(this.id, embeddingId);
		if (result.statusCode === 200) {
			return true;
		}
		return false;
	}

	async removeEmbedding(embeddingId: number): Promise<boolean> {
		const result = await CampaignApi.removeEmbedding(this.id, embeddingId);
		if (result.statusCode === 200) {
			return true;
		}
		return false;
	}

	// hooks
	async getHooks(): Promise<void> {
		const result = await CampaignApi.getHooks(this.id);
		if (result.statusCode === 200) {
			runInAction(() => {
				this.hooks = result.data.hooks;
			});
		}
	}

	async upsertHook(hook: CreateDBHook | DBHook): Promise<void> {
		const result = await CampaignApi.upsertHook(this.id, hook);
		if (result.statusCode === 200) {
			await this.getHooks();
		}
	}

	async deleteHook(hookId: number): Promise<void> {
		const result = await CampaignApi.deleteHook(this.id, hookId);
		if (result.statusCode === 200) {
			await this.getHooks();
		}
	}
}

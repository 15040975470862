import { Box, keyframes, Stack, styled, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { AppLogo } from '../../logo/AppLogo';

const fadeInUp = keyframes`
  from {
    opacity: 0;
	 transform: translateY(20px);
  }
  to {
    opacity: 1;
	transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
	opacity: 1;
  }
  to {
	opacity: 0;
  }
`;

const SplashBox = styled(Box)(({ theme }) => ({
	position: 'fixed',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	backgroundColor: theme.palette.primary.dark,
	zIndex: 9999,
	color: theme.palette.primary.contrastText,
	transition: 'opacity 0.5s ease',
	['&.hidden']: {
		pointerEvents: 'none',
		animation: `${fadeOut} 0.5s ease`,
		animationDelay: '1.0s',
		animationFillMode: 'forwards',
	},
	['& .logo']: {
		height: 100,
		maxWidth: '80vw',
		objectFit: 'contain',
		animation: `${fadeInUp} 0.5s ease`,
		animationFillMode: 'forwards',
	},
}));

const BottomBox = styled(Box)(({ theme }) => ({
	position: 'absolute',
	bottom: 0,
	left: 0,
	right: 0,
	padding: theme.spacing(2),
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.primary.contrastText,
	textAlign: 'center',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

class PageLoading extends React.Component {
	constructor(props: Record<string, never>) {
		super(props);
	}

	render() {
		return (
			<SplashBox>
				<Grid2 container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
					<Grid2 xs={12} justifyContent="center" style={{ textAlign: 'center' }}>
						<Stack spacing={2} justifyContent="center" alignItems="center">
							<Box
								style={{
									textAlign: 'center',
									width: '400px',
									maxWidth: '90vw',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<AppLogo darkMode width="100%" />
							</Box>
							<Typography variant="h4">Vennligst vent</Typography>
						</Stack>
					</Grid2>
				</Grid2>
				<BottomBox>
					<Typography variant="body1">Laster innhold...</Typography>
				</BottomBox>
			</SplashBox>
		);
	}
}

export default React.memo(PageLoading);

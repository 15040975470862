import { createTheme, Theme, PaletteOptions, lighten, alpha, darken } from '@mui/material';
import { light } from '@mui/material/styles/createPalette';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { text } from 'stream/consumers';

// Felles palett (Light og Dark Mode)
const commonPalette: PaletteOptions = {
	error: { main: '#ff6b6b' },
	info: { main: '#3a91bb' },
	success: { main: '#3dd598' },
	warning: { main: '#f2994a' }, // Fallback-farge uten gradient
};

// Lys modus palett
const lightPalette: PaletteOptions = {
	mode: 'light',
	primary: {
		main: '#300c52',
		contrastText: '#ffffff',
	},
	secondary: {
		main: '#f2994a',
		contrastText: '#ffffff',
	},
	background: {
		default: '#f7f8fa',
		paper: '#ffffff',
	},
	text: {
		primary: '#14141f',
		secondary: '#4a4a5f',
	},
	divider: 'rgba(20, 20, 31, 0.12)',
	...commonPalette,
};

// Mørk modus palett
const darkPalette: PaletteOptions = {
	mode: 'dark',
	primary: {
		main: '#266dd3',
		contrastText: '#ffffff',
	},
	secondary: {
		main: '#f2994a',
		contrastText: '#ffffff',
	},
	background: {
		default: '#14141f',
		paper: '#1e1e2f',
	},
	text: {
		primary: '#ffffff',
		secondary: '#a1a1b5',
	},
	divider: 'rgba(255, 255, 255, 0.1)',
	...commonPalette,
};

const gradientBackground = (color: string) => `linear-gradient(90deg, ${color}, ${lighten(color, 0.3)})`; // Gradient bakgrunn

// Typografi
const typography: TypographyOptions = {
	fontFamily: "'Inter', 'Roboto', sans-serif",
	h1: {
		fontSize: '2.5rem',
		fontWeight: 700,
		background: gradientBackground((darkPalette.primary! as any).main),
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
	},
	h2: {
		fontSize: '2rem',
		fontWeight: 600,
		color: '#300c52',
	},
	button: {
		fontWeight: 600,
		textTransform: 'none',
	},
	body1: {
		fontSize: '1rem',
		color: 'inherit',
	},
};

// Komponentstilering
const components: any = {
	MuiLink: {
		styleOverrides: {
			root: {
				color: darken((darkPalette.primary! as any).contrastText!, 0.3),
				fontFamily: "'Inter', 'Roboto', sans-serif",
				fontWeight: 400,
				fontSize: '0.875rem',
				lineHeight: 1.43,
				cursor: 'pointer',
				textDecoration: 'none',
				position: 'relative',
				width: 'fit-content',
				'&:not(.no-underline)::before': {
					content: '""',
					position: 'absolute',
					width: '100%',
					height: '1px',
					bottom: '0px',
					left: '0px',
					backgroundColor: 'rgb(148, 160, 184)',
					opacity: 0.3,
					transition: 'width 0.3s, opacity 0.3s',
				},
				'&:hover::before': {
					width: '0px',
				},
			},
		},
	},
	MuiButton: {
		styleOverrides: {
			root: {
				borderRadius: '24px', // Standard stil for alle knapper
			},
			contained: {
				background: gradientBackground((darkPalette.primary! as any).main),
				color: '#fff',
				'&:hover': {
					background: `linear-gradient(90deg, ${lighten((darkPalette.primary! as any).main, 0.1)}, ${lighten((darkPalette.primary! as any).main, 0.4)})`,
					boxShadow: `0 4px 15px ${alpha((darkPalette.primary! as any).main, 0.3)}`,
				},
			},
			outlined: {
				borderColor: (darkPalette.primary! as any).main,
				color: (darkPalette.primary! as any).main,
				'&:hover': {
					borderColor: lighten((darkPalette.primary! as any).main, 0.3),
					color: lighten((darkPalette.primary! as any).main, 0.3),
					background: alpha((darkPalette.primary! as any).main, 0.1),
				},
			},
			text: {
				color: (darkPalette.primary! as any).main,
				'&:hover': {
					color: lighten((darkPalette.primary! as any).main, 0.3),
					background: alpha((darkPalette.primary! as any).main, 0.1),
				},
			},
		},
	},
	MuiCard: {
		styleOverrides: {
			root: {
				borderRadius: '16px',
				background: '#1e1e2f',
				boxShadow: '0 8px 24px rgba(0, 0, 0, 0.5)',
				padding: '20px',
			},
		},
	},
};

// Form for elementer
const shape = {
	borderRadius: 12,
};

// SmartTheme-funksjon
const smartTheme = (baseTheme: Theme, options: { isDarkMode: boolean }) => {
	const palette = options.isDarkMode ? darkPalette : lightPalette;

	return createTheme({
		...baseTheme,
		palette,
		typography,
		components,
		shape,
	});
};

// Eksporter basetheme og smartTheme
export default smartTheme;

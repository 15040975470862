import { AppBar, Backdrop, Box, Button, Fab, Stack, Toolbar, Typography, styled } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import ChatIcon from '@mui/icons-material/Chat';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import StoreContext from '../../stores/StoreContext';
import { throttle } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import './CustomerSupportChatFab.scss';
import CustomerSupportChat from './CustomerSupportChat';
import { RootStore } from '../../stores/RootStore';
import { ChannelTypeDTO } from '../../dto/channel.types';

const classes = {
	root: 'CustomerSupportChatFab',
	chatOpen: 'CustomerSupportChatFab--chatOpen',
	chatClosed: 'CustomerSupportChatFab--chatClosed',
};

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
	background: palette.background.paper,
}));

type Props = {};

const CustomerSupportChatFab = observer(
	class CustomerSupportChatFab extends React.Component<Props> {
		static readonly contextType = StoreContext;

		handleScrollThrottled: any;
		atTop: boolean = true;

		constructor(props: Props) {
			super(props);
			makeObservable(this, {
				atTop: observable,
				chatOpen: computed,
				toggleChat: action,
				rootStore: computed,
				chatStore: computed,
				customerSupportChatChannel: computed,
				hasUnread: computed,
			});

			this.handleScrollThrottled = throttle(this.handleScroll, 500);
		}

		componentDidMount() {
			// refresh stats on mount
			window.addEventListener('scroll', this.handleScrollThrottled);
		}

		componentWillUnmount(): void {
			window.removeEventListener('scroll', this.handleScrollThrottled);
		}

		handleScroll = () => {
			runInAction(() => {
				if (document?.scrollingElement?.scrollTop && document?.scrollingElement?.scrollTop > 100) {
					this.atTop = false;
				} else {
					this.atTop = true;
				}
			});
		};

		get rootStore() {
			return this.context as RootStore;
		}

		get chatStore() {
			return this.rootStore.chatStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get chatOpen() {
			return this.uiState.customerSupportChatOpen;
		}

		get customerSupportChatChannel() {
			return this.chatStore.channels.find((c) => c.channelType === ChannelTypeDTO.CustomerService);
		}

		get hasUnread() {
			if (this.customerSupportChatChannel) {
				return this.customerSupportChatChannel.unreadNum > 0;
			}

			return false;
		}

		get fabVariant(): 'extended' | 'circular' {
			return this.atTop ? 'extended' : 'circular';
		}

		toggleChat = () => {
			this.uiState.toggleCustomerSupportChat();
		};

		renderFab() {
			let className = classes.root;
			if (this.chatOpen !== undefined) {
				className = this.chatOpen
					? `${classes.root} ${classes.chatOpen}`
					: `${classes.root} ${classes.chatClosed}`;
			}

			return (
				<Box sx={{ position: 'relative' }}>
					<StyledBox className={className}>
						{this.chatOpen && (
							<Stack
								direction="column"
								sx={{
									display: 'flex',
									position: 'relative',
									flexDirection: 'column',
									height: '100%',
								}}
							>
								<AppBar position="relative">
									<Toolbar>
										<ChatIcon sx={{ mr: 1 }} />
										<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
											Kundeservice
										</Typography>
										<Button color="inherit" onClick={this.toggleChat}>
											Lukk
										</Button>
									</Toolbar>
								</AppBar>
								<Box sx={{ height: 'calc(100% - var(--top-app-bar-height))' }}>
									<CustomerSupportChat disableTopMargin />
								</Box>
							</Stack>
						)}
					</StyledBox>

					<Fab
						color="secondary"
						aria-label="Chat"
						variant={this.fabVariant}
						sx={(theme) => ({
							transition: 'all .25s ease-in-out',
							zIndex: 1,
							color: theme.palette.secondary.contrastText,
						})}
						onClick={this.toggleChat}
					>
						{this.hasUnread ? (
							<MarkUnreadChatAltIcon sx={{ mr: this.fabVariant === 'extended' ? 1 : 0 }} />
						) : (
							<ChatIcon sx={{ mr: this.fabVariant === 'extended' ? 1 : 0 }} />
						)}
						{this.fabVariant === 'extended' && 'Chat'}
					</Fab>
				</Box>
			);
		}

		render() {
			return (
				<>
					<Box className="CustomerSupportChatFabContainer">{this.renderFab()}</Box>
					<Backdrop open={Boolean(this.chatOpen)} onClick={this.toggleChat} sx={{ zIndex: 1299 }}></Backdrop>
				</>
			);
		}
	}
);

export default CustomerSupportChatFab;

import { alpha, Stack, styled } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { action, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import Xarrow, { pathType, Xwrapper } from "react-xarrows";
import StoreContext from "../../../stores/StoreContext";
import { RootStore } from "../../../stores/RootStore";
import { ConfirmationNumberOutlined, EmailOutlined, InsightsOutlined, IntegrationInstructionsOutlined, LinkOutlined, WebOutlined } from "@mui/icons-material";
import { AppLogo } from "../../logo/AppLogo";
import { smartOrange } from "./HomeSmart";

const DebugGrid = styled(Grid2)(({ theme }) => ({
    border: `1px dashed #${Math.floor(Math.random() * 16777215).toString(16)}`,
}));

const StyledGrid = styled(Grid2)(({ theme }) => ({
    color: theme.palette.text.primary,
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
}));

const LaneGrid = styled(StyledGrid)(({ theme }) => ({
    // backgroundColor: alpha(theme.palette.background.paper, 0.4),
    alignItems: 'flex-start',

}));

const ElementGrid = styled(StyledGrid)(({ theme }) => ({
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    width: '80%',
    marginTop: theme.spacing(1),
    userSelect: 'none',
    '&:hover': {
        cursor: 'pointer',
    },
    // alignItems: 'center',
    // padding: theme.spacing(1),
}));


type PipelineElement = {
    id: string;
    name: string | JSX.Element;
    description: string;
    icon?: JSX.Element;
    arrowToIds?: {
        id: string;
        path?: pathType;
    }[];
    eligibleParentId?: string;
}
type PipelineLane = {
    title: string;
    elements: PipelineElement[]
};
type PipelineData = PipelineLane[];


export const defaultPipelineThat100PercentShouldOnlyBeUsedForTesting: PipelineData = [
    {
        title: 'Kilder',
        elements: [
            {
                id: 'PipelineElement-1',
                name: 'SmartChat Demo',
                icon: <WebOutlined />,
                description: 'Element 1 description',
                arrowToIds: [{ id: 'PipelineElement-3', path: 'straight' }, { id: 'PipelineElement-4' }],
            }
        ],
    },
    {
        title: 'SmartChat Engine',
        elements: [
            {
                id: 'PipelineElement-3',
                name: <AppLogo height='10px' darkMode plainColors={false} />,
                description: 'Step 1 description',
                arrowToIds: [{ id: 'PipelineElement-5', path: 'straight' }],
            },
            {
                id: 'PipelineElement-4',
                name: 'Sentimentanalyse',
                icon: <InsightsOutlined />,
                description: 'Step 2 description',
                arrowToIds: [{ id: 'PipelineElement-6' }],
            },
        ],
    },
    {
        title: 'Mål',
        elements: [
            {
                id: 'PipelineElement-5',
                name: 'SmartChat Tickets',
                icon: <ConfirmationNumberOutlined />,
                description: 'Step 3 description',
                eligibleParentId: 'PipelineElement-3',
            },
            {
                id: 'PipelineElement-7',
                name: 'Slack',
                icon: <LinkOutlined />,
                description: 'Step 4 description',
                eligibleParentId: 'PipelineElement-3',
            },
            {
                id: 'PipelineElement-8',
                name: 'Teams',
                icon: <LinkOutlined />,
                description: 'Step 4 description',
                eligibleParentId: 'PipelineElement-3',
            },
            {
                id: 'PipelineElement-9',
                name: 'Email',
                icon: <EmailOutlined />,
                description: 'Step 4 description',
                eligibleParentId: 'PipelineElement-3',
            },
            {
                id: 'PipelineElement-10',
                name: 'API',
                icon: <IntegrationInstructionsOutlined />,
                description: 'Step 4 description',
                eligibleParentId: 'PipelineElement-3',
            },
            {
                id: 'PipelineElement-6',
                name: 'SmartChat Insight',
                icon: <InsightsOutlined />,
                description: 'Step 4 description',
            },
        ],
    },
]

type PipelineProps = {
    pipeline: PipelineData;
}


export default observer(class Pipeline extends React.Component<PipelineProps> {
    static readonly contextType = StoreContext;

    elementsThatShouldBeRed: string[] = [];
    elementsThatShouldBeBlue: string[] = [];
    potentialNewChild?: {
        element: PipelineElement,
        parent: PipelineElement,
    }


    constructor(props: PipelineProps) {
        super(props);

        makeObservable(this, {
            elementsThatShouldBeRed: observable,
            elementsThatShouldBeBlue: observable,
            potentialNewChild: observable,
            // onElementMouseEnter: action,
            onElementMouseLeave: action,
        });
    }

    get rootStore() {
        return this.context as RootStore;
    }

    findEligibleParentOfElement(element: PipelineElement): PipelineElement | undefined {
        const { pipeline } = this.props;

        for (const lane of pipeline) {
            for (const e of lane.elements) {
                if (e.id === element.eligibleParentId) {
                    return e;
                }
            }
        }

        return undefined;
    }

    findParentsOfElement(element: PipelineElement): PipelineElement[] {
        const { pipeline } = this.props;
        const parents: PipelineElement[] = [];

        for (const lane of pipeline) {
            for (const e of lane.elements) {
                if (e.arrowToIds?.map(e => e.id)?.includes(element.id)) {
                    parents.push(e);
                    parents.push(...this.findParentsOfElement(e));
                }
            }
        }

        return parents.flat();
    }

    findChildrenOfElement(element: PipelineElement): PipelineElement[] {
        const { pipeline } = this.props;
        const children: PipelineElement[] = [];

        for (const lane of pipeline) {
            for (const e of lane.elements) {
                if (element.arrowToIds?.map(e => e.id)?.includes(e.id)) {
                    children.push(e);
                    children.push(...this.findChildrenOfElement(e));
                }
            }
        }

        return children.flat();
    }

    hasEligibleParentAndIsNotChild(element: PipelineElement): boolean {
        return this.findEligibleParentOfElement(element) !== undefined && this.findParentsOfElement(element).length === 0;
    }

    buildOnElementMouseEnter = (element: PipelineElement) => (event: React.MouseEvent<HTMLDivElement>) => {
        const isLeaf = this.hasEligibleParentAndIsNotChild(element);
        const eligibleParent = this.findEligibleParentOfElement(element);
        const childrenIds: string[] = this.findChildrenOfElement(element)?.map(e => e.id);

        let parentsIds: string[] = [];
        if (isLeaf && eligibleParent) {
            const parents = this.findParentsOfElement(eligibleParent);
            parentsIds = [eligibleParent.id, ...parents.map(e => e.id)];
        } else {
            parentsIds = this.findParentsOfElement(element)?.map(e => e.id);
        }

        console.log(`[${parentsIds}] -> ${element.id} -> [${childrenIds}] (${eligibleParent?.id})`);


        runInAction(() => {
            if (isLeaf) {
                this.elementsThatShouldBeBlue = [element.id, parentsIds, childrenIds].flat();
                if (eligibleParent) {
                    this.potentialNewChild = {
                        element,
                        parent: eligibleParent,
                    }
                }
            } else {
                this.elementsThatShouldBeRed = [element.id, parentsIds, childrenIds].flat();
            }
        });
    }

    onElementMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
        this.elementsThatShouldBeRed = [];
        this.elementsThatShouldBeBlue = [];
        this.potentialNewChild = undefined;
    }

    onElementClicked = (element: PipelineElement) => (event: React.MouseEvent<HTMLDivElement>) => {
        const shouldAddChild = this.hasEligibleParentAndIsNotChild(element);

        if (shouldAddChild) {
            console.log(`Add ${element.id} as child of ${element.eligibleParentId}`);
            this.props.pipeline.forEach(lane => {
                lane.elements.forEach(e => {
                    if (e.id === element.eligibleParentId) {
                        e.arrowToIds = [...(e.arrowToIds ?? []), { id: element.id }];
                        runInAction(() => {
                            this.potentialNewChild = undefined;
                            this.elementsThatShouldBeRed = [...this.elementsThatShouldBeBlue]
                            this.elementsThatShouldBeBlue = [];
                        });
                    }
                })
            });
        }

        const eligibleParent = this.findEligibleParentOfElement(element);
        const shouldRemoveChild = !shouldAddChild && eligibleParent !== undefined;

        if (shouldRemoveChild) {
            console.log(`Remove ${element.id} as child of ${element.eligibleParentId}`);
            this.props.pipeline.forEach(lane => {
                lane.elements.forEach(e => {
                    if (e.id === element.eligibleParentId) {
                        e.arrowToIds = e.arrowToIds?.filter(entry => entry.id !== element.id);
                        runInAction(() => {
                            this.potentialNewChild = undefined;
                            this.elementsThatShouldBeBlue = [...this.elementsThatShouldBeRed];
                            this.elementsThatShouldBeRed = [];
                            this.potentialNewChild = {
                                element,
                                parent: eligibleParent,
                            }
                        });
                    }
                })
            });
        }
    }

    render() {
        // return null if mobile
        if (this.rootStore.uiState.isMobile) {
            return null;
        }

        const { pipeline } = this.props;
        return (

            // <>
            <Xwrapper>
                <Grid2 container sx={{
                    minWidth: `calc(300px * ${pipeline.length})`,
                }}>
                    {pipeline.map((lane) => (
                        <LaneGrid container xs key={lane.title}>
                            {/* <Typography variant="h6" pb={2}>{lane.title} ({lane.elements.length})</Typography> */}
                            {lane.elements.map((element) => (
                                <ElementGrid onClick={this.onElementClicked(element)} onMouseEnter={this.buildOnElementMouseEnter(element)} onMouseLeave={this.onElementMouseLeave} id={element.id} key={element.id} sx={{
                                    transition: 'all 0.2s',
                                    // border: this.elementsThatShouldBeLitUp.includes(element.id) ? '1px solid red' : 'none',
                                    // borderRadius: 2,
                                    boxShadow: this.elementsThatShouldBeRed.includes(element.id) ? `0 0 10px cyan` : this.elementsThatShouldBeBlue.includes(element.id) ? `0 0 5px red` : 'none',
                                }}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        {element.icon}
                                        <div>{element.name}</div>
                                    </Stack>
                                    {element.arrowToIds?.map((arrowToId) => (
                                        <Xarrow
                                            key={`from-${element.id}-to-${arrowToId.id}`}
                                            start={element.id} // ID of the starting element
                                            end={arrowToId.id} // ID of the target element
                                            color={smartOrange}
                                            strokeWidth={2}
                                            path={arrowToId.path ?? "smooth"} // Smooth curved arrow
                                            headSize={6} // Size of the arrowhead
                                        />
                                    ))}

                                    {this.potentialNewChild?.element.id === element.id && (
                                        <Xarrow
                                            start={this.potentialNewChild.parent.id}
                                            end={this.potentialNewChild.element.id}
                                            color={smartOrange}
                                            strokeWidth={2}
                                            dashness={true}
                                            path="smooth"
                                            headSize={6}
                                        />
                                    )}
                                </ElementGrid>
                            ))}
                        </LaneGrid>
                    ))}
                </Grid2>

                {/* <Xarrow
                    start="browser-emulator"
                    end="PipelineElement-1"
                    endAnchor={"top"}
                    color="#4DD0E1"
                    dashness={{ animation: 1 }}
                    strokeWidth={2}
                /> */}
            </Xwrapper>
            // </>
        );

    }

    static buildPipeline(title: string): PipelineData {
        const pipeline: PipelineData = [...defaultPipelineThat100PercentShouldOnlyBeUsedForTesting];

        pipeline[0].elements[0].name = title;

        return pipeline;
    }
})
import type React from 'react';
import { useState, useEffect, useRef } from 'react';
import {
	Box,
	TextField,
	Stack,
	IconButton,
	CircularProgress,
	useMediaQuery,
	Alert,
	AlertTitle,
	Grow,
	Typography,
} from '@mui/material';
import { Refresh, ArrowBack, ArrowForward } from '@mui/icons-material';
import config from '../../../config/config';
import Pipeline from './Pipeline';
import Xarrow, { Xwrapper } from 'react-xarrows';
import { smartOrange } from './HomeSmart';

type BrowserEmulatorProps = {
	previewUrl: string; // Start-bilde
	onUrlSubmit: (url: string, mode: 'mobile' | 'desktop') => void; // Callback når ny URL sendes inn
};

const BrowserEmulator: React.FC<BrowserEmulatorProps> = ({ previewUrl, onUrlSubmit }) => {
	const [url, setUrl] = useState(previewUrl);
	const [pageUrl, setPageUrl] = useState(previewUrl);
	const [isLoading, setIsLoading] = useState(false);
	const [viewMode, setViewMode] = useState<'desktop' | 'mobile'>('desktop');
	const [hasError, setHasError] = useState(false);
	const isMobile = useMediaQuery('(max-width:600px)');
	const iframeRef = useRef<HTMLIFrameElement>(null);

	// Oppdater bildet ved bytte av URL
	useEffect(() => {
		setIsLoading(true);
		setPageUrl(previewUrl);
		setUrl(previewUrl);
		// if mobile, set view mode to mobile
		if (isMobile) {
			setViewMode('mobile');
		}

		const timer = setTimeout(() => {
			console.log('timeout');
			if (isLoading) {
				setIsLoading(false);
				setHasError(true);
			}
		}, 10000);

		const messageHandler = (event: MessageEvent) => {
			// we care only about the domain
			const url = new URL(event.origin);
			if (url.host !== config.hosts.api) {
				return; // Sikkerhetssjekk
			}
			console.log(`Security check passed - ${event.data}`);
			if (event.data === 'iframe-loaded') {
				clearTimeout(timer);
				setIsLoading(false);
				setHasError(false);

				console.info('Setting loading to false');
			} else if (event.data === 'iframe-error') {
				setIsLoading(false);
				setHasError(true);
			}
		};

		window.addEventListener('message', messageHandler);

		return () => {
			clearTimeout(timer);
			window.removeEventListener('message', messageHandler);
		};
	}, []);

	const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUrl(event.target.value);
	};

	const handleUrlSubmit = () => {
		setIsLoading(true); // Start laster-visning
		onUrlSubmit(url, viewMode); // Informer parent om ny URL
	};

	const setModeDesktop = () => {
		setViewMode('desktop');
		handleUrlSubmit();
	};

	const setModeMobile = () => {
		setViewMode('mobile');
		handleUrlSubmit();
	};

	const onKeyUp = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			handleUrlSubmit();
		}
	};

	const renderContent = () => {
		if (!pageUrl) {
			return <CircularProgress />;
		}

		if (hasError) {
			return (
				<Alert severity="warning">
					<AlertTitle>Feil</AlertTitle>
					Kunne ikke laste siden
				</Alert>
			);
		}
		const url = `//${config.hosts.api}/v1/smart-chat/preview?url=${pageUrl}&mode=${viewMode}`;
		return (
			<Box
				sx={{
					width: '100%',
					height: '100%',
					// position: 'relative'
				}}
			>
				<iframe
					src={url}
					ref={iframeRef}
					title="Smartchat preview"
					style={{
						width: '100%',
						// height: viewMode === 'desktop' ? '100%' : '100%',
						aspectRatio: viewMode === 'desktop' ? '16/9' : '9/16',
						opacity: isLoading ? 0 : 1,
					}}
				/>
				{isLoading && <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%' }} />}
			</Box>
		);
	};

	const widthOfIframe = iframeRef.current?.clientWidth;
	const arrowXOffset = widthOfIframe ? widthOfIframe / 2 - 50 : 0;

	return (
		<Stack spacing={16}>
			<Xwrapper>
				<Box
					id="browser-emulator"
					sx={{
						border: '1px solid #ccc',
						borderRadius: '8px',
						overflow: 'hidden',
						width: '100%',
						maxWidth: '1280px',
						mx: 'auto',
						mt: { xs: 2, md: 4 },
					}}
				>
					{/* Fake Browser Header */}
					<Box
						sx={(theme) => ({
							display: 'flex',
							alignItems: 'center',
							bgcolor: theme.palette.background.default,
							py: 1,
							px: 2,
						})}
					>
						<Stack direction="row" spacing={1}>
							<IconButton
								size="small"
								sx={{
									display: {
										xs: 'none',
									},
								}}
							>
								<ArrowBack />
							</IconButton>
							<IconButton
								size="small"
								sx={{
									display: {
										xs: 'none',
									},
								}}
							>
								<ArrowForward />
							</IconButton>
							<IconButton size="small" onClick={handleUrlSubmit}>
								<Refresh />
							</IconButton>
						</Stack>
						<TextField
							variant="outlined"
							size="small"
							fullWidth
							value={url}
							onChange={handleUrlChange}
							onKeyUp={onKeyUp}
							placeholder="virtur.no"
							sx={{
								mx: 2,
							}}
						/>
					</Box>

					{/* Fake Browser Content */}
					<Box
						sx={{
							// position: 'relative',
							bgcolor: '#e0e0e0',
							aspectRatio: viewMode === 'desktop' ? '16/9' : '9/16',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							maxHeight: '80vh',
							width: '100%',
						}}
					>
						{renderContent()}
					</Box>
				</Box>
				<Box
					sx={{
						alignSelf: 'center',
						justifySelf: 'center',
						width: '300px',
					}}
				>
					<Typography
						id="browser-emulator-caption"
						component="h4"
						variant="h6"
						color={smartOrange}
						textAlign="center"
					>
						Åpne chat
					</Typography>
					<Typography id="browser-emulator-caption" variant="body1" color="text.secondary">
						Dette er en demo basert på <strong>noen få</strong> sider fra din nettside. Den er ikke
						live på {pageUrl}.
					</Typography>
				</Box>
				<Xarrow
					start="browser-emulator-caption"
					startAnchor={'top'}
					// end="browser-emulator"
					endAnchor={{ position: 'bottom', offset: { x: arrowXOffset, y: 0 } }}
					end={iframeRef}
					dashness={{ animation: 1 }}
					strokeWidth={2}
					headSize={6}
					color={smartOrange}
				/>
			</Xwrapper>
		</Stack>
	);
};

export default BrowserEmulator;

import { makeAutoObservable, runInAction } from 'mobx';
import OneSignalReact from 'react-onesignal';
import ChatApi from '../api/endpoints/ChatApi';
import { ProjectStatusDTO } from '../dto/project.types';
import { TodoDTO } from '../dto/todo.types';
import { ProjectSorting } from './ProjectStore';
import { RootStore } from './RootStore';
import { toNumber } from 'lodash';

export class TodoStore {
	rootStore: RootStore;
	todos: Todo[] = [];
	isLoading = true;

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, {
			rootStore: false,
			workspaceStore: false,
			profileStore: false,
			projectStore: false,
			partnershipManager: false,
			pricingPlanStore: false,
		});
		this.rootStore = rootStore; // Store that can resolve authors.
		// this.transportLayer = transportLayer; // Thing that can make server requests.
		// this.transportLayer.onReceiveTodoUpdate((updatedTodo) => this.updateTodoFromServer(updatedTodo));
		this.init();
	}

	init() {
		this.rootStore.userStore.UserIdChanged.on(() => {
			if (this.rootStore.workspaceStore.hasEmployeeAccess) {
				this.loadTodos();
			}
		});
		this.rootStore.userStore.SignedOut.on(() => (this.todos = []));
		this.projectStore.ProjectsLoaded.on(() => {
			this.checkAutoTodos();
		});
		this.projectStore.ProjectUpdated.on(() => {
			this.checkAutoTodos();
		});

		this.pricingPlanStore.PricingPlanUpdated.on(() => {
			this.checkAutoTodos();
		});

		this.partnershipManager.PartnershipUpdated.on(() => {
			this.checkAutoTodos();
		});
	}

	get workspaceStore() {
		return this.rootStore.workspaceStore;
	}

	get pricingPlanStore() {
		return this.rootStore.pricingPlanStore;
	}

	get profileStore() {
		return this.rootStore.profileStore;
	}

	get projectStore() {
		return this.rootStore.projectStore;
	}

	get partnershipManager() {
		return this.rootStore.partnershipManager;
	}

	async getUnreadMessagesFromCustomer() {
		const response = await ChatApi.getUnreadInWorkspace();
		if (response.statusCode === 200) {
			runInAction(() => {
				response.data.forEach((message: any) => {
					const id = `unread-channel-${message.channelId}`;
					this.updateTodoFromServer({
						id: id,
						completed: false,
						task: `Melding fra ${message?.name ?? 'kunde'}`,
						description: `Ulest: ${message.text}`,
						required: true,
						severity: 3,
						date: null,
						actionTextLabel: 'Vis',
						actionLink: `/app/pro/projects/channel/${message.channelId}`,
						created: new Date(message.created),
						updated: new Date(),
						deleted: null,
					});

					let todo = this.todos.find((todo) => todo.id === id);
					if (todo) {
						todo.action.onClick = () => {
							runInAction(() => {
								this.removeTodoById(id);
							});
						};
					}
				});
			});
		}
	}

	async checkAutoTodos() {
		const { hasAdminAccess } = this.workspaceStore;
		const { currentPricingPlan, isLoading } = this.pricingPlanStore;

		if (hasAdminAccess) {
			// check pricing plan
			if (currentPricingPlan && !isLoading) {
				this.removeTodoById('pricingPlanMissing');
			} else {
				this.updateTodoFromServer({
					id: 'pricingPlanMissing',
					completed: false,
					task: 'Velg en prisplan',
					description: null,
					required: true,
					severity: 5,
					date: null,
					actionTextLabel: 'Prisplaner',
					actionLink: '/app/pro/admin/pricing',
					created: new Date(),
					updated: new Date(),
					deleted: null,
				});
			}

			// search visibility
			if (this.workspaceStore.getSetting('search', 'disabled') === false) {
				this.removeTodoById('searchDisabled');
			} else {
				this.updateTodoFromServer({
					id: 'searchDisabled',
					completed: false,
					task: 'Aktiver synlighet',
					description: 'Når synlighet er deaktivert, kan ikke kunder sende deg henvendelser',
					required: true,
					severity: 8,
					date: null,
					actionTextLabel: 'Aktiver',
					actionLink: '/app/pro/admin/settings',
					created: new Date(),
					updated: new Date(),
					deleted: null,
				});
			}

			// missing company email
			if (this.workspaceStore.workspace?.company?.email) {
				this.removeTodoById('missingCompanyEmail');
			} else {
				this.updateTodoFromServer({
					id: 'missingCompanyEmail',
					completed: false,
					task: 'Legg til en e-postadresse for bedriften',
					description:
						'Varsel om nye jobber og annen viktig informasjon sendes hit dersom dette ikke overstyres fra innstillinger.',
					required: true,
					severity: 10,
					date: null,
					actionTextLabel: 'E-post',
					actionLink: '/app/pro/admin/settings',
					created: new Date(),
					updated: new Date(),
					deleted: null,
				});
			}

			// missing setting for notify about new projects
			if (
				this.workspaceStore.getSetting('notifications', 'usersNewProject') ||
				this.workspaceStore.getSetting('notifications', 'emailNewProject')
			) {
				this.removeTodoById('notifyNewProjects');
			} else {
				this.updateTodoFromServer({
					id: 'notifyNewProjects',
					completed: false,
					task: 'Motta varsler om nye jobber',
					description:
						'Sett opp at varsel for nye jobber skal gå til spesifikke brukere, eller til f.eks. en felles e-post for bedriften.',
					required: true,
					severity: 9,
					date: null,
					actionTextLabel: 'Varsler',
					actionLink: '/app/pro/admin/settings',
					created: new Date(),
					updated: new Date(),
					deleted: null,
				});
			}

			// partnerships
			if (this.workspaceStore.workspace) {
				// we only care about incoming requests
				const incomingPartnershipRequests = this.partnershipManager.partnershipRequests.filter(
					(request) =>
						request.requestorWorkspaceId !== toNumber(this.workspaceStore.workspace?.id) &&
						request.status === 'pending'
				);
				if (incomingPartnershipRequests.length === 0) {
					this.removeTodoById('partnershipRequests');
				} else if (incomingPartnershipRequests.length > 0) {
					this.updateTodoFromServer({
						id: 'partnershipRequests',
						completed: false,
						task: 'Forespørsler om partnerskap',
						description: `Du har ${incomingPartnershipRequests.length} forespørsler om å inngå partnerskap.`,
						required: true,
						severity: 7,
						date: null,
						actionTextLabel: 'Se forespørsler',
						actionLink: '/app/pro/admin/company/partners',
						created: new Date(),
						updated: new Date(),
						deleted: null,
					});
				}
			}
		}

		// users profile
		if (this.profileStore.currentUserProfile?.profilePicture) {
			this.removeTodoById('missingProfilePictore');
		} else {
			this.updateTodoFromServer({
				id: 'missingProfilePicture',
				completed: false,
				task: 'Last opp et profilbilde',
				description: null,
				required: false,
				severity: 0,
				date: null,
				actionTextLabel: 'Profil',
				actionLink: '/app/profile',
				created: new Date(),
				updated: new Date(),
				deleted: null,
			});
		}

		// Unprocessed projects
		const requestedProjects = this.projectStore.sortProjects(ProjectSorting.CreatedDESC, undefined, [
			ProjectStatusDTO.Requested,
		]);
		if (requestedProjects.length < 1) {
			this.removeTodoById('requestedProjects');
		} else {
			this.updateTodoFromServer({
				id: 'requestedProjects',
				completed: false,
				task: 'Ubehandlede jobber',
				description: `Du har ${requestedProjects.length} ubehandlede jobber. Svar kunden og oppdater status.`,
				required: true,
				severity: 6,
				date: null,
				actionTextLabel: 'Vis',
				actionLink: `/app/pro/projects/?status=${ProjectStatusDTO.Requested}`,
				created: new Date(),
				updated: new Date(),
				deleted: null,
			});
		}

		// Enable push notifications
		const isPushEnabled = OneSignalReact.Notifications.permission;
		if (isPushEnabled) {
			this.removeTodoById('onesignal-push');
		} else {
			runInAction(() => {
				this.updateTodoFromServer({
					id: 'onesignal-push',
					completed: false,
					task: 'Push-varsler',
					description: `Aktiver push-varsler for å få beskjed om nye jobber og meldinger.`,
					required: true,
					severity: 5,
					date: null,
					actionTextLabel: 'Aktiver',
					actionLink: null,
					created: new Date(),
					updated: new Date(),
					deleted: null,
				});

				let todo = this.todos.find((todo) => todo.id === 'onesignal-push');
				if (todo) {
					todo.action.onClick = () => {
						runInAction(() => {
							this.rootStore.profileStore.requestPushPermission(true);
						});
					};
				}
			});
		}
	}

	getTodoById(todoId: string): Todo | undefined {
		return this.todos.find((t) => t.id === todoId);
	}

	removeTodoById = (todoId: string) => {
		runInAction(() => {
			const index = this.todos.findIndex((t) => t.id === todoId);
			if (index >= 0) {
				this.todos.splice(index, 1);
			}
		});
	};

	// Fetches all Todos from the server.
	loadTodos() {
		this.isLoading = true;
		this.getUnreadMessagesFromCustomer();

		// this.transportLayer.fetchTodos().then((fetchedTodos) => {
		// 	runInAction(() => {
		// 		fetchedTodos.forEach((json) => this.updateTodoFromServer(json));
		// 		this.isLoading = false;
		// 	});
		// });

		// fetchedTodos.forEach((json) => this.updateTodoFromServer(json));
		this.checkAutoTodos();
		this.isLoading = false;
	}

	// Update a Todo with information from the server. Guarantees a Todo only
	// exists once. Might either construct a new Todo, update an existing one,
	// or remove a Todo if it has been deleted on the server.
	updateTodoFromServer(json: TodoDTO) {
		runInAction(() => {
			let todo = this.todos.find((todo) => todo.id === json.id);

			if (!todo) {
				todo = new Todo(this, json.id);
				this.todos.push(todo);
			}

			if (json.deleted) {
				this.removeTodo(todo);
			} else {
				todo.updateFromJson(json);
			}
		});
	}

	// // Creates a fresh Todo on the client and the server.
	// createTodo() {
	// 	const todo = new Todo(this);
	// 	this.todos.push(todo);
	// 	return todo;
	// }

	// A Todo was somehow deleted, clean it from the client memory.
	removeTodo(todo: Todo) {
		this.todos.splice(this.todos.indexOf(todo), 1);
		// todo.dispose();
	}
}

// Domain object Todo.
type Action = {
	textLabel: string | null;
	link: string | null;
	onClick?: () => void;
};

export class Todo {
	id: string; // Unique id of this Todo, immutable.
	completed: boolean = false;
	task: string = '';
	description: string | null = null;
	required: boolean = false;
	severity: number = 0;
	date: Date | null = null;
	action: Action = {
		textLabel: null,
		link: null,
	};
	store: TodoStore;
	autoSave: boolean = true; // Indicator for submitting changes in this Todo to the server.
	// saveHandler: IReactionDisposer; // Disposer of the side effect auto-saving this Todo (dispose).

	constructor(store: TodoStore, id: string) {
		makeAutoObservable(this, {
			id: false,
			store: false,
			autoSave: false,
			// saveHandler: false,
			// dispose: false,
		});

		this.store = store;
		this.id = id;

		// this.saveHandler = reaction(
		// 	() => this.asJson, // Observe everything that is used in the JSON.
		// 	(json) => {
		// 		// If autoSave is true, send JSON to the server.
		// 		if (this.autoSave) {
		// 			this.store.transportLayer.saveTodo(json);
		// 		}
		// 	}
		// );
	}

	// Remove this Todo from the client and the server.
	delete() {
		// this.store.transportLayer.deleteTodo(this.id);
		this.store.removeTodo(this);
	}

	get asJson() {
		return {
			id: this.id,
			completed: this.completed,
			task: this.task,
			description: this.description,
			required: this.required,
			severity: this.severity,
			date: this.date,
			action: {
				textLabel: this.action.textLabel,
				link: this.action.link,
			},
		};
	}

	// Update this Todo with information from the server.
	updateFromJson(json: TodoDTO) {
		this.autoSave = false; // Prevent sending of our changes back to the server.
		this.completed = json.completed;
		this.task = json.task;
		this.description = json.description;
		this.required = json.required;
		this.severity = json.severity;
		this.date = json.date;
		this.action = {
			textLabel: json.actionTextLabel,
			link: json.actionLink,
		};
		this.autoSave = true;
	}

	// // Clean up the observer.
	// dispose() {
	// 	this.saveHandler();
	// }
}

import { makeAutoObservable, runInAction } from 'mobx';
import APIWorkspace, { AccountType, ProjectLeadDto } from '../api/endpoints/APIWorkspace';
import LiteEvent from '../helpers/LiteEvent';
import LogUtil from '../helpers/LogUtil';
import { RootStore } from './RootStore';

import { Workspace as WorkspaceDto } from '../types';
import { CompanyDTO } from '../dto/company.types';

export class WorkspaceStore {
	rootStore: RootStore;
	members: any[] = [];
	accountTypes: AccountType[] = [];
	workspace: Workspace | null = null;
	stats: any = null;
	grant: any;
	bill: any = null;
	settings: any = null;
	isLoading: boolean = false;
	isLoadingSettings: boolean = false;
	_isLoadingGrants: boolean = false;
	workspacesMetadata: any[] = [];
	children: Workspace[] = [];
	lockedWorkspace: any = null;
	hasCompletedInitialLoad: boolean = false;

	projectLeads: ProjectLead[] = [];

	private readonly hasLoadedWorkspace = new LiteEvent<any>();

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, {
			rootStore: false,
		});
		this.rootStore = rootStore;

		this.init();
	}

	public get WorkspaceLoaded() {
		return this.hasLoadedWorkspace.expose();
	}

	get hasAdminAccess(): boolean {
		return this.grant && this.grant.key === 'admin';
	}

	get hasManagerAccess(): boolean {
		return this.grant && ['admin', 'manager'].indexOf(this.grant.key) >= 0;
	}

	get hasEmployeeAccess(): boolean {
		return this.grant && ['admin', 'manager', 'employee'].indexOf(this.grant.key) >= 0;
	}

	get isLoadingGrants() {
		return Boolean(this.rootStore.userStore.befJwt && this._isLoadingGrants);
	}

	async init() {
		this.rootStore.userStore.UserIdChanged.on(() => {
			this.reset();
			this.initialLoad();
		});

		this.rootStore.userStore.SignedOut.on(() => {
			this.reset();
		});
	}

	reset = () => {
		runInAction(() => {
			this.members = [];
			this.projectLeads = [];
			this.grant = null;
			this.bill = null;
			this.stats = null;
			this.workspace = null;
			this.settings = null;
			this.hasCompletedInitialLoad = false;
			this._isLoadingGrants = false;
		});
	};

	initialLoad = async () => {
		try {
			if (this._isLoadingGrants || this.hasCompletedInitialLoad) {
				return;
			}

			this.reset();
			this._isLoadingGrants = true;
			const response = await APIWorkspace.getGrants();
			runInAction(async () => {
				if (response.statusCode === 200) {
					this.grant = response.data.grant;
				}
				this._isLoadingGrants = false;

				this.loadResourcesForEmployees();
				this.hasCompletedInitialLoad = true;
				this.hasLoadedWorkspace.trigger();
			});
		} catch (err) {
			runInAction(() => {
				this._isLoadingGrants = false;
			});
			LogUtil.error(`Error while init workspaceStore - this is bad`, err);
		}
	};

	async loadResourcesForEmployees() {
		if (this.hasEmployeeAccess) {
			this.rootStore.profileStore.loadColleagueProfiles();
			await Promise.all([this.loadSettings(), this.loadWorkspace(), this.loadMembers()]);
		}
	}
	getSetting(category: string, key: string) {
		if (this.settings && this.settings[category] && this.settings[category][key] !== undefined) {
			return this.settings[category][key];
		}
		return undefined;
	}

	async getProjectLeads(skip: number = 0, limit: number = 25) {
		try {
			const result = await APIWorkspace.getProjectLeads(skip, limit);
			if (result.statusCode === 200) {
				runInAction(() => {
					// add or update
					result.data.forEach((json: any) => {
						const projectLead = this.projectLeads.find((x) => x.id === json.id);
						if (projectLead) {
							projectLead.updateFromJson(json);
						} else {
							this.projectLeads.push(new ProjectLead(json));
						}
					});
				});
			}
		} catch (err) {
			LogUtil.error(err);
		}
	}

	async loadSettings() {
		if (this.hasAdminAccess) {
			this.isLoadingSettings = true;
			const result = await APIWorkspace.getSettings(); // workspaceId is pulled from jwt
			if (result?.statusCode === 200) {
				runInAction(() => {
					this.settings = result.data;
					this.isLoadingSettings = false;
				});
			}
		}
	}

	async loadChildren() {
		if (this.hasAdminAccess) {
			const result = await APIWorkspace.getChildren();
			if (result?.statusCode === 200) {
				runInAction(() => {
					result.data.forEach(this.updateWorkspaceChildFromJson);
				});
			}
		}
	}

	findWorkspaceChild(workspaceId: string): Workspace | undefined {
		return this.children.find((x) => x.id === '' + workspaceId);
	}

	updateWorkspaceChildFromJson = (json: any) => {
		let child = this.findWorkspaceChild(json.id);
		if (!child) {
			child = new Workspace(json.id, json.name);
			this.children.push(child);
		}
		child.updateFromJson(json);
	};

	async saveSettings() {
		if (this.hasAdminAccess) {
			return await APIWorkspace.saveSettings(this.settings); // workspaceId is pulled from jwt
		}

		return {
			statusCode: 403, // No access = no point in even making the request
		};
	}

	async saveSetting(category: string, key: string, value: string) {
		if (this.hasAdminAccess) {
			const response = await APIWorkspace.saveSetting(category, key, value); // workspaceId is pulled from jwt
			if (response.statusCode === 200) {
				runInAction(() => {
					this.settings = response.data;
				});
			}

			return response;
		}

		return {
			statusCode: 403, // No access = no point in even making the request
		};
	}

	updateSetting(category: string, key: string, value: string) {
		if (this.isLoadingSettings) {
			return false;
		}
		runInAction(() => {
			if (!this.settings) {
				this.settings = {};
			}

			if (!this.settings[category]) {
				this.settings[category] = {};
			}

			this.settings[category][key] = value;
		});
		return true;
	}

	async loadWorkspaceFromName(workspaceName: string) {
		if (!workspaceName) {
			return null;
		}
		try {
			const workspacesMetadata = await this.getMetadataFromDomain(workspaceName);
			if (workspacesMetadata.statusCode === 200 && workspacesMetadata.data?.domain) {
				runInAction(() => {
					this.lockedWorkspace = workspacesMetadata.data;
				});

				return workspacesMetadata.data;
			}
		} catch (err) {
			LogUtil.error(err);
		}

		return null;
	}

	async loadWorkspaceStats() {
		try {
			const stats = await APIWorkspace.getWorkspaceStats();
			runInAction(() => {
				this.stats = stats;
			});
		} catch (err) {
			LogUtil.error(err);
		}
	}

	async loadBill() {
		try {
			const bill = await APIWorkspace.getBill();
			runInAction(() => {
				this.bill = bill;
			});
		} catch (err) {
			LogUtil.error(err);
		}
	}

	async loadWorkspace() {
		if (!this.hasEmployeeAccess) {
			console.info('WorkspaceStore:loadWorkspace - missing access.', this.grant);
			return;
		}
		try {
			this.isLoading = true;
			const result = await APIWorkspace.getWorkspace();
			if (result.statusCode === 200 && result.data) {
				const workspace = result.data;
				runInAction(() => {
					this.workspace = new Workspace(workspace.id);
					this.workspace.updateFromJson(workspace);
					const { currentPricingPlan, upcomingPricingPlan } = workspace;

					if (currentPricingPlan?.id) {
						this.rootStore.pricingPlanStore.setCurrentPricingPlan(currentPricingPlan.id);
					}

					if (upcomingPricingPlan?.id && upcomingPricingPlan?.validFrom) {
						this.rootStore.pricingPlanStore.setUpcomingPricingPlan(
							upcomingPricingPlan.id,
							new Date(upcomingPricingPlan.validFrom)
						);
					}
				});
			}
		} catch (err) {
			LogUtil.error(err);
		}

		runInAction(() => {
			this.isLoading = false;
		});
	}

	async loadMembers() {
		if (!this.hasEmployeeAccess) {
			console.info('WorkspaceStore:loadMembers - no access.', this.grant);
			return;
		}
		try {
			const response = await APIWorkspace.getMembers();
			if (response.statusCode === 200) {
				runInAction(() => {
					this.members = response.data;
				});
			}
		} catch (err) {
			LogUtil.error(err);
		}
	}

	async getAccountTypes() {
		if (this.accountTypes && this.accountTypes.length > 0) {
			return this.accountTypes;
		}

		const result = await APIWorkspace.getAccountTypes();
		if (result.statusCode === 200) {
			runInAction(() => {
				this.accountTypes = result.data;
			});
		}

		return this.accountTypes;
	}

	async removeChildWorkspace(childId: string): Promise<boolean> {
		if (!this.hasAdminAccess) {
			console.warn('WorkspaceStore:removeChildWorkspace - no access.', this.grant);
			return false;
		}

		if (!this.workspace?.id || !childId) {
			console.log('WorkspaceStore:removeChildWorkspace - missing workspace or child id.');
			return false;
		}

		try {
			const response = await APIWorkspace.removeChild(childId);
			if (response.statusCode === 200) {
				runInAction(() => {
					this.children = this.children.filter((childWorkspace) => childWorkspace.id !== childId);
				});
				return true;
			} else {
				console.error('Failed to remove child workspace', response);
				return false;
			}
		} catch (err) {
			console.error(err);
		}

		return false;
	}

	async updateMember(userId: string, accountType: AccountType) {
		await APIWorkspace.updateMember(userId, {
			accountType: accountType,
		});

		await this.loadMembers();
	}

	async addMember(countryCode: string = '47', phoneNumber: string, accountType: AccountType, profileData: any) {
		const result = await APIWorkspace.addWorkspaceMember(countryCode, phoneNumber, accountType, profileData);

		if (result.statusCode === 200) {
			await this.loadMembers();
		}

		return result;
	}

	async saveCompany(company: any) {
		try {
			await APIWorkspace.saveCompany(company);
			// reload workspace info to make sure the change is updated everywhere
			await this.loadWorkspace();
			return true;
		} catch (err) {
			LogUtil.error(err);
			return false;
		}
	}

	async saveCompanyLogo(companyId: string, fileId: string) {
		try {
			await APIWorkspace.saveCompanyLogo(companyId, fileId);
			// reload workspace info to make sure the change is updated everywhere
			await this.loadWorkspace();
			return true;
		} catch (err) {
			LogUtil.error(err);
			return false;
		}
	}

	async getMetadataFromDomain(domain: string) {
		const data = this.workspacesMetadata?.find((m: any) => m?.domain === domain);
		if (data) {
			return {
				statusCode: 200,
				data,
			};
		}
		const response = await APIWorkspace.getMetadata(domain);
		if (response.statusCode === 200) {
			runInAction(() => {
				this.workspacesMetadata.push(response.data);
			});
		}
		return response;
	}

	getMetadataFromWorkspaceId(workspaceId: string) {
		if (!workspaceId) {
			return null;
		}
		return this.workspacesMetadata.find((m: any) => m?.id.toString() === workspaceId.toString());
	}
}

export class Workspace {
	id: string;
	name?: string;
	domain?: string;
	userId?: number; // added april 10th 2024 - each workspace get a dedicated user that can be used as customerId for jobs
	companyId?: string;
	companyName?: string;
	coordinates?: { latitude: number; longitude: number };
	pricingPlan?: {
		id: string;
		title: string;
		validFrom: string;
		validTo: string;
	};
	searchRadius?: number = 0;
	searchDisabled?: boolean = false;
	company?: CompanyDTO;

	constructor(workspaceId: string, name?: string) {
		this.id = '' + workspaceId;
		this.name = name;
	}

	get workspaceId() {
		return this.id;
	}

	updateFromJson(json: any) {
		//  todo: clean up and type what we get from server
		this.name = json.name ?? this.name;
		this.domain = json.domain ?? this.domain;
		this.companyId = json.companyId ?? this.companyId;
		this.companyName = json.companyName ?? this.companyName;
		this.coordinates = json.coordinates ?? this.coordinates;
		this.searchRadius = json.searchRadius ?? this.searchRadius;
		this.searchDisabled = json.searchDisabled ?? this.searchDisabled;
		this.pricingPlan = {
			id: json.pricingPlanId ?? this.pricingPlan?.id,
			title: json.pricingPlanTitle ?? this.pricingPlan?.title,
			validFrom: json.validFrom ?? this.pricingPlan?.validFrom,
			validTo: json.validTo ?? this.pricingPlan?.validTo,
		};
		this.userId = json.userId ?? this.userId;
		this.company = json.company ?? this.company;
	}

	toDto(): WorkspaceDto {
		if (!this.name || !this.domain) {
			throw new Error('Workspace is missing name or domain');
		}

		return {
			id: this.id,
			name: this.name,
			domain: this.domain,
			loginMethods: ['phone'],
		};
	}
}

export class ProjectLead {
	id: number = 0;
	status: string = '';
	workspaceId: string = '';
	projectId: string = '';
	workspaceName: string = '';
	name: string = '';
	createDate: Date = new Date();
	updateDate: Date = new Date();
	created: string = '';
	updated: string = '';
	category?: string;
	urgency?: string;
	tags: string[] = [];
	estimatedBudget?: number;
	estimatedHours?: number;
	hasUnhandledCustomerMessages: boolean = false;

	constructor(json: ProjectLeadDto) {
		this.updateFromJson(json);
	}

	toJson(): ProjectLeadDto {
		return {
			id: this.id,
			status: this.status,
			workspaceId: this.workspaceId,
			workspaceName: this.workspaceName,
			name: this.name,
			hasUnhandledCustomerMessages: this.hasUnhandledCustomerMessages,
			created: this.created,
			updated: this.updated,
			category: this.category,
			urgency: this.urgency,
			estimatedBudget: this.estimatedBudget ?? 0,
			estimatedHours: this.estimatedHours ?? 0,
		};
	}

	updateFromJson(json: ProjectLeadDto) {
		this.id = json.id;
		this.status = json.status;
		this.workspaceId = json.workspaceId;
		this.projectId = json.id ? '' + json.id : '';
		this.workspaceName = json.workspaceName;
		this.hasUnhandledCustomerMessages = json.hasUnhandledCustomerMessages;
		this.name = json.name;
		this.createDate = new Date(json.created);
		this.updateDate = new Date(json.updated);
		this.created = json.created;
		this.updated = json.updated;
		this.category = json.category;
		this.urgency = json.urgency;
		this.estimatedBudget = json.estimatedBudget ? +json.estimatedBudget : 0;
		this.estimatedHours = json.estimatedHours ? +json.estimatedHours : 0;
		this.tags = json.tags ?? [];
	}
}

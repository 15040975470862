import AgentsApi from '../../api/endpoints/AgentsApi';
import {
	AgentDTO as AgentDTOType,
	AgentTaskType,
	AgentQuickActionType,
	AgentFunctionType,
} from '../../components/dashboard2/assistant/agent.types';
import { computed, makeAutoObservable, observable } from 'mobx';

class Agent {
	agentId!: number;
	workspaceId!: number;
	name!: string;
	description!: string;
	persona!: string;
	isActive!: boolean;
	interactionObjectives!: string;
	aiModel!: string;
	contextSize!: number;
	created!: Date;
	updated!: Date;
	userId!: number | null;
	tasks: AgentTaskType[] = [];
	quickActions: AgentQuickActionType[] = [];
	functions: AgentFunctionType[] = [];
	isSidekick!: boolean;
	isPublic!: boolean;

	constructor(agentData: Partial<AgentDTOType>) {
		makeAutoObservable(this, {
			tasks: observable,
			quickActions: observable,
			functions: observable,
			dynamicSidekickType: computed,
		});

		this.updateFromDTO(agentData);
	}

	get asDTO(): AgentDTOType {
		return {
			agent: {
				agentId: this.agentId,
				workspaceId: this.workspaceId,
				name: this.name,
				description: this.description,
				persona: this.persona,
				isActive: this.isActive,
				interactionObjectives: this.interactionObjectives,
				aiModel: this.aiModel,
				contextSize: this.contextSize,
				created: this.created,
				updated: this.updated,
				userId: this.userId,
				isPublic: this.isPublic,
				isSidekick: this.isSidekick,
			},
			tasks: this.tasks,
			quickActions: this.quickActions,
			functions: this.functions,
		};
	}

	get dynamicSidekickType(): string {
		if (this.isPublic) {
			return 'Synlig for alle';
		}
		if (this.isSidekick) {
			return 'Sidekick';
		}

		return 'Ikke sidekick';
	}

	save(): void {
		if (!this.agentId) {
			AgentsApi.createAgent(this.asDTO)
				.then((response) => {
					if (response.statusCode === 200 && response.data) {
						this.updateFromDTO(response.data);
					}
				})
				.catch((error) => console.error('Error creating agent: ', error));
		} else {
			AgentsApi.updateAgent(this.agentId, this.asDTO)
				.then((response) => {
					if (response.statusCode === 200 && response.data) {
						this.updateFromDTO(response.data);
					}
				})
				.catch((error) => console.error('Error updating agent: ', error));
		}
	}

	/**
	 * Updates the agent data from a given partial DTO.
	 * @param agentData The partial data to update the agent.
	 */
	updateFromDTO(agentData: Partial<AgentDTOType>): void {
		if (agentData.agent) {
			const agentInfo = agentData.agent;
			// Directly update properties from agentData.agent
			this.agentId = agentInfo.agentId ?? this.agentId;
			this.workspaceId = agentInfo.workspaceId ?? this.workspaceId;
			this.name = agentInfo.name ?? this.name;
			this.description = agentInfo.description ?? this.description;
			this.persona = agentInfo.persona ?? this.persona;
			this.isActive = agentInfo.isActive ?? this.isActive;
			this.interactionObjectives = agentInfo.interactionObjectives ?? this.interactionObjectives;
			this.aiModel = agentInfo.aiModel ?? this.aiModel;
			this.contextSize = agentInfo.contextSize ?? this.contextSize;
			this.created = agentInfo.created instanceof Date ? agentInfo.created : this.created;
			this.updated = agentInfo.updated instanceof Date ? agentInfo.updated : this.updated;
			this.userId = agentInfo.userId !== undefined ? agentInfo.userId : this.userId;
			this.isSidekick = agentInfo.isSidekick ?? this.isSidekick;
			this.isPublic = agentInfo.isPublic ?? this.isPublic;
		}

		if (Array.isArray(agentData.tasks)) {
			this.tasks = agentData.tasks;
		}
		if (Array.isArray(agentData.quickActions)) {
			this.quickActions = agentData.quickActions;
		}
		if (Array.isArray(agentData.functions)) {
			this.functions = agentData.functions;
		}
	}

	// Additional methods can be added here, similar to the Assistant class
}

export default Agent;

import { Component } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Grid2 from '@mui/material/Unstable_Grid2';

const tiers = [
	{
		title: 'Light',
		price: '499',
		description: [
			'Egendefinert kunnskap (opptil 20 artikler)',
			'Automatisk kunnskap fra nettside ved oppstart',
			'10 NOK per samtale',
			'+15 NOK per konvertering',
		],
		buttonText: 'Velg Light',
		buttonVariant: 'outlined',
		buttonColor: 'primary',
	},
	{
		title: 'Professional',
		subheader: 'Anbefalt',
		price: '799',
		description: [
			'Kunnskap (opptil 100 elementer)',
			'Automatisk kunnskap fra nettside ved oppstart',
			'Automatisk oppdatert kunnskap basert på din nettside',
			'AI innsiktsanalyse',
			'Sentimentanalyse',
			'Emneanalyse',
			'AI ytelsesanalyse',
			'10 NOK per samtale',
			'+15 NOK per konvertering',
		],
		buttonText: 'Velg Professional',
		buttonVariant: 'contained',
		buttonColor: 'secondary',
	},
	{
		title: 'Enterprise',
		price: 'Kontakt oss',
		description: [
			'Alt i Professional',
			'Ubegrenset auto-kunnskapsbase',
			'Ubegrenset manuell kunnskapsbase',
			'Bildeprosessering',
			'Kobling til API og fagsystemer',
			'Chat med Sharepoint',
			'Tilpassede integrasjoner',
			'Tilpassede Sidekicks for teamet ditt',
			'SSO Login',
			'Egendefinert modell (Bring Your Own Model)',
		],
		buttonText: 'Kontakt oss',
		buttonVariant: 'outlined',
		buttonColor: 'primary',
	},
];

class Pricing extends Component {
	openChat = () => {
		try {
			if ((window as any).BefareChat) {
				(window as any).BefareChat.open();
			}
		} catch (error) {
			console.log('BefareAiChat', error);
		}
	};

	SendMessageAndOpen(message: string) {
		try {
			if ((window as any).BefareChat) {
				(window as any).BefareChat.sendMessageAndOpen(message);
			}
		} catch (error) {
			console.log('BefareAiChat', error);
		}
	}

	tryLightPlan = () => {
		this.SendMessageAndOpen('Hei, jeg er interessert SmartChat Light');
	};

	tryProPlan = () => {
		this.SendMessageAndOpen('Hei, jeg er interessert SmartChat Professional');
	};

	tryEnterprisePlan = () => {
		this.SendMessageAndOpen('Hei, jeg er interessert SmartChat Enterprise');
	};
	render() {
		return (
			<Container
				id="pricing"
				sx={{
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: { xs: 3, sm: 6 },
				}}
			>
				<Box
					sx={{
						width: { sm: '100%', md: '60%' },
						textAlign: { sm: 'left', md: 'center' },
					}}
				>
					<Typography component="h2" variant="h4" gutterBottom sx={{ color: 'text.primary' }}>
						Priser
					</Typography>
					<Typography variant="body1" sx={{ color: 'text.secondary' }}>
						Velg den planen som passer best for din bedrift. <br />
						Alle planer er bygget med fleksibilitet og skalerbarhet i tankene, og gir deg tilgang til
						kraftige verktøy for å forbedre kundeopplevelsen.
					</Typography>
				</Box>
				<Grid2 container spacing={3} sx={{ alignItems: 'stretch', justifyContent: 'center', width: '100%' }}>
					{tiers.map((tier) => (
						<Grid2
							xs={12}
							sm={tier.title === 'Enterprise' ? 12 : 6}
							md={4}
							key={tier.title}
							sx={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<Card
								sx={[
									{
										p: 2,
										display: 'flex',
										flexDirection: 'column',
										gap: 4,
										flexGrow: 1,
									},
									tier.title === 'Professional' &&
									((theme) => ({
										border: 'none',
										background:
											'radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))',
										boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
										...theme.applyStyles('dark', {
											background:
												'radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))',
											boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
										}),
									})),
								]}
							>
								<CardContent>
									<Box
										sx={[
											{
												mb: 1,
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
												gap: 2,
											},
											tier.title === 'Professional' ? { color: 'grey.100' } : { color: '' },
										]}
									>
										<Typography component="h3" variant="h6">
											{tier.title}
										</Typography>
										{tier.title === 'Professional' && (
											<Chip icon={<AutoAwesomeIcon />} label={tier.subheader} />
										)}
									</Box>
									{!isNaN(Number.parseInt(tier.price)) && (
										<Typography variant="overline" color="textPrimary">
											fra
										</Typography>
									)}
									<Box
										sx={[
											{
												display: 'flex',
												alignItems: 'baseline',
											},
											tier.title === 'Professional' ? { color: 'grey.50' } : { color: null },
										]}
									>
										<Typography component="h3" variant="h2" color="primary">
											{!isNaN(Number.parseInt(tier.price)) && 'NOK'} {tier.price}
										</Typography>
										{!isNaN(Number.parseInt(tier.price)) && (
											<Typography component="h3" variant="h6" color="textPrimary">
												&nbsp; per mnd
											</Typography>
										)}
									</Box>
									<Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
									{tier.description.map((line) => (
										<Box
											key={line}
											sx={{
												py: 1,
												display: 'flex',
												gap: 1.5,
												alignItems: 'center',
											}}
										>
											<CheckCircleRoundedIcon
												sx={[
													{
														width: 20,
													},
													tier.title === 'Professional'
														? { color: 'primary.light' }
														: { color: 'primary.main' },
												]}
											/>
											<Typography
												variant="subtitle2"
												component={'span'}
												sx={[
													tier.title === 'Professional'
														? { color: 'grey.50' }
														: { color: null },
												]}
											>
												{line}
											</Typography>
										</Box>
									))}
								</CardContent>
								<CardActions>
									<Button
										fullWidth
										variant={tier.buttonVariant as 'outlined' | 'contained'}
										color={tier.buttonColor as 'primary' | 'secondary'}
										onClick={
											tier.title === 'Light'
												? this.tryLightPlan
												: tier.title === 'Professional'
													? this.tryProPlan
													: this.tryEnterprisePlan
										}
									>
										{tier.buttonText}
									</Button>
								</CardActions>
							</Card>
						</Grid2>
					))}
				</Grid2>
			</Container>
		);
	}
}

export default Pricing;

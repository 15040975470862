import { Stack } from '@mui/material';
import { ReactNode } from 'react';

type LayoutContentProps = {
	children: ReactNode;
};

const LayoutContent = ({ children }: LayoutContentProps) => {
	return (
		<Stack
			spacing={2}
			direction="column"
			sx={(theme) => ({
				mt: 2,
				mb: 2,
				width: '100%',
				borderRadius: `${theme.shape.borderRadius}px`, // Bruker border-radius fra tema
				border: `1px solid ${theme.palette.divider}`, // Bruker divider fra palette
				backgroundColor: theme.palette.background.paper,
				flexGrow: 1,
				[theme.breakpoints.down('sm')]: {
					padding: 0,
				},
				color: theme.palette.text.primary,
			})}
		>
			<Stack
				spacing={2}
				direction="column"
				sx={{
					padding: 0,
					width: '100%',
				}}
			>
				{children}
			</Stack>
		</Stack>
	);
};

export default LayoutContent;

import { Divider, Paper, Stack, Typography, styled } from '@mui/material';
import React from 'react';

export type ServiceCardItem = {
	title: string;
	description: string;
	buttonTitle?: string;
	type?: any;
	icon?: React.ReactNode;
};

const StyledPaper = styled(Paper)(({ theme }) => ({
	padding: '1rem',
	flexGrow: 1,
	display: 'flex',
	alignItems: 'stretch',
	transition: 'all 0.25s ease-in-out',
	backgroundColor: theme.palette.background.paper,
	borderRadius: '8px',
	boxShadow: theme.shadows[2],
	cursor: 'pointer',
	[`&:hover`]: {
		boxShadow: theme.shadows[4],
		transform: 'translate(0, -5%)',
		['& .MuiSvgIcon-root']: {
			color: theme.palette.primary.main,
			transform: 'scale(1.05)',
		},
		['& .MuiButton-root']: {
			transform: 'scale(1.05)',
			['&::after']: {
				transform: 'translate(-50%, -50%) scale(2)',
			},
		},
	},
	['& .MuiSvgIcon-root']: {
		transition: 'all 0.25s ease-in-out',
	},
	['& .MuiButton-root']: {
		overflow: 'hidden',
		transition: 'all 0.25s ease-in-out',
		['&::after']: {
			content: '""',
			position: 'absolute',
			top: '50%',
			left: '50%',
			width: '100%',
			height: '100%',
			background: 'radial-gradient(circle, rgba(0,150,255,0.05) 0%, rgba(255,255,255,0) 100%)',
			transform: 'translate(-50%, -50%) scale(0)',
			transition: 'all 0.3s ease-in-out',
			borderRadius: '50%',
			pointerEvents: 'none',
		},
	},
}));

type ServiceCardProps = {
	item: ServiceCardItem;
	// eslint-disable-next-line no-unused-vars
	onClick?: (item: any) => void;
};
export default class ServiceCard extends React.Component<ServiceCardProps> {
	onClick = () => {
		const { item } = this.props;
		this.props.onClick?.(item);
	};

	render() {
		const { item } = this.props;
		return (
			<StyledPaper elevation={4} onClick={this.onClick}>
				<Stack alignItems="center" flexGrow={1} justifyContent="flex-start">
					{item?.icon}
					<Typography variant="h6" color="inherit" sx={{ marginTop: '0.5rem' }}>
						{item.title}
					</Typography>
					{!item.icon && (
						<>
							<Divider
								color="secondary"
								sx={{ width: '50%', margin: '1rem 0', backgroundColor: 'rgba(255,255,255,0.5)' }}
							/>
							<Typography variant="body2">{item.description}</Typography>
						</>
					)}
					<div style={{ display: 'flex', flexGrow: 1 }} />
				</Stack>
			</StyledPaper>
		);
	}
}

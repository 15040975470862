import './index.scss';

import App from './components/App';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import React from 'react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { RootStore } from './stores/RootStore';
import ScrollToTop from './components/ScrollToTop';
import StoreContext from './stores/StoreContext';
import ThemeProvider from './themes/ThemeProvider';
import { createRoot } from 'react-dom/client';
import LogUtil from './helpers/LogUtil';
import ErrorBoundary from './utils/ErrorBoundary';
import { PWAProvider } from './stores/PWAContext';

const container = document.getElementById('root');
const root = createRoot(container!);

// Current version of the app, injected during build
const appVersion = document.querySelector('meta[build-version]');
let currentVersion = 'utdatert';
if (appVersion?.getAttribute('build-version') != null) {
	currentVersion = appVersion.getAttribute('build-version') ?? 'utdatert';
}

// Function to check version.json for updates
const checkForUpdates = async () => {
	try {
		const response = await fetch('/version.json', { cache: 'no-cache' });
		if (!response.ok) {
			console.error('Failed to fetch version.json');
			return;
		}

		const data = await response.json();
		const latestVersion = data.version;
		if (!(window as any).befare) {
			(window as any).befare = {};
		}
		(window as any).befare.latestVersion = latestVersion;
		(window as any).befare.currentVersion = currentVersion;
		(window as any).befare.updateAvailable = currentVersion !== latestVersion;
		(window as any).befare.versionData = data;

		// Compare the current version with the latest version
		if (currentVersion !== latestVersion) {
			console.log('New version detected. Current:', currentVersion, 'Latest:', latestVersion, data);
			// don't notify on local development
			localStorage.removeItem('reloaded_after_update');

			if (process.env.NODE_ENV === 'development') {
				return;
			}
			// notifyUserAboutUpdate();
		}
	} catch (error) {
		console.warn('Error checking version.json:', error);
	}
};
checkForUpdates();
// Periodically check for new version
setInterval(checkForUpdates, 600000); // Check every 600 seconds

root.render(
	<React.StrictMode>
		<ErrorBoundary>
			<PWAProvider>
				<StoreContext.Provider value={new RootStore()}>
					<ThemeProvider>
						<BrowserRouter>
							<ScrollToTop />
							<CssBaseline />

							<App />
						</BrowserRouter>
					</ThemeProvider>
				</StoreContext.Provider>
			</PWAProvider>
		</ErrorBoundary>
	</React.StrictMode>
);

// Function to check whether the page has already been reloaded after an update
const hasReloaded = () => localStorage.getItem('reloaded_after_update') === 'true';

// Function to mark the page as reloaded after an update
const markReloaded = () => localStorage.setItem('reloaded_after_update', 'true');

// Register the service worker and handle updates
serviceWorkerRegistration.register({
	onUpdate: (registration) => {
		if (registration && registration.waiting) {
			console.log('New version available.');
			// Add an event listener for when the new service worker takes control
			registration.waiting.addEventListener('statechange', (event: any) => {
				if (event.target.state === 'activated' && !hasReloaded()) {
					console.log('Service worker activated, reloading page.');
					// Mark the page as reloaded to prevent further reloads
					markReloaded();
					// Reload the page after the new service worker activates
					window.location.reload();
				}
			});

			// Tell the waiting service worker to skip waiting
			registration.waiting.postMessage({ type: 'SKIP_WAITING' });
		}
	},
	onSuccess: () => {
		console.log('Service worker registered successfully.');
	},
});

// Listen for when the new service worker takes control
if (navigator.serviceWorker) {
	navigator.serviceWorker.addEventListener('controllerchange', () => {
		if (!hasReloaded()) {
			console.log('New service worker has taken control. Reloading page.');
			// Mark the page as reloaded to prevent further reloads
			markReloaded();
			// Reload the page only the first time the service worker takes control
			window.location.reload();
		}
	});
}

// Override console.error to log with LogUtil
const originalConsoleError = console.error;
console.error = (...params) => {
	originalConsoleError(...params);
	LogUtil.error(...params);
};

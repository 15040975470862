import {
	Alert,
	AppBar,
	Button,
	Checkbox,
	Chip,
	Dialog,
	DialogContent,
	Divider,
	Grid,
	IconButton,
	Toolbar,
	Typography,
	Unstable_Grid2 as Grid2,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VideocamIcon from '@mui/icons-material/Videocam';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Channel } from '../../../stores/ChatStore';
import { RootStore } from '../../../stores/RootStore';
import StoreContext from '../../../stores/StoreContext';
import ProfilePicture from '../../common/ProfilePicture';
import { Profile } from '../../../stores/ProfileStore';

import { Box } from '@mui/system';

type Props = {
	open: boolean;
	channel: Channel;
	// eslint-disable-next-line no-unused-vars
	onStartCall: (channel: Channel, options: any) => void;
	onClose: () => void;
};

const CreateVideoCallDialog = observer(
	class CreateVideoCallDialog extends React.Component<Props> {
		static readonly contextType = StoreContext;

		membersToPing: any = [];
		fillMembersToPing: boolean = true;
		pingMembersColorArray: any = {};

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				membersToPing: observable,
				rootStore: computed,
				profileStore: computed,
				uiState: computed,
				open: computed,
				channel: computed,
				onlineMembers: computed,
				offlineMembers: computed,
				startCall: action,
				handleChange: action,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get open() {
			return this.props.open;
		}

		get channel() {
			return this.props.channel;
		}

		get onlineMembers(): Profile[] {
			return (this.channel.groupMembers
				.map((member) => {
					const profile = this.profileStore.getProfile(member.userId);
					if (profile?.lastOnline ?? 0 > Date.now() - RootStore.ONLINE_INTERVAL_MS * 2) {
						if (this.membersToPing.includes(profile?.id)) {
							const index = this.membersToPing.indexOf(profile?.id);
							if (index > -1) {
								this.membersToPing.splice(index, 1);
							}
						}
						return profile;
					} else {
						return false;
					}
				})
				.filter(Boolean) ?? []) as Profile[];
		}

		get offlineMembers(): Profile[] {
			return (this.channel.groupMembers
				.map((member) => {
					const profile = this.profileStore.getProfile(member.userId);
					if (profile?.lastOnline ?? 0 > Date.now() - RootStore.ONLINE_INTERVAL_MS * 2) {
						return false;
					} else {
						return profile;
					}
				})
				.filter(Boolean) ?? []) as Profile[];
		}

		getColor(member: any) {
			if (this.membersToPing.includes(member.userId)) {
				return '#5EA327';
			} else {
				return 'default';
			}
		}

		isChecked(memberID: any) {
			if (this.membersToPing.includes(memberID)) {
				return true;
			} else {
				return false;
			}
		}

		handleChange(e: any, v: any) {
			if (!this.isChecked(v.userId)) {
				this.membersToPing.push(v.userId);
				this.pingMembersColorArray[v.userId.toString()] = '#5EA327';
				console.log('MEMBER ADDED');
				console.log(this.membersToPing);
			} else {
				const index = this.membersToPing.indexOf(v.userId);
				if (index > -1) {
					this.membersToPing.splice(index, 1);
					this.pingMembersColorArray[v.userId.toString()] = 'default';
					console.log('MEMBER DELETED');
					console.log(this.membersToPing);
				}
			}
		}

		renderOnline() {
			return (
				<React.Fragment>
					<Divider
						sx={(theme) => ({
							m: 2,
							'&::before, &::after': {
								borderColor: theme.palette.divider,
							},
						})}
					>
						<Chip label="KLAR TIL Å DELTA" sx={{ color: '#222' }} />
					</Divider>
					<Grid2 container spacing={1} sx={{ m: 1, display: 'flex', justifyContent: 'center' }}>
						{this.onlineMembers.map((m, index) => (
							<Grid2 key={index}>
								<ProfilePicture profile={m} chip />
							</Grid2>
						))}
						{/* no members online */}
						{this.onlineMembers.length < 1 && (
							<Grid2>
								<Typography variant="caption" sx={{ m: 2, display: 'flex', justifyContent: 'center' }}>
									Ingen medlemmer er tilgjengelig for å delta i videosamtalen.
								</Typography>
							</Grid2>
						)}
					</Grid2>
				</React.Fragment>
			);
		}

		renderOffline() {
			if (this.fillMembersToPing) {
				for (const member of this.offlineMembers) {
					if (member.id) {
						this.membersToPing.push(member.id);
						const key = member.id.toString();
						this.pingMembersColorArray[key] = '#5EA327';
					}
				}
				this.fillMembersToPing = false;
			}

			if (this.offlineMembers?.length < 1) {
				return null;
			}

			// @todo option to send SMS to participants
			// @todo option to invite randoms?
			// @todo show busy/in call

			return (
				<Box>
					<Divider
						sx={(theme) => ({
							m: 2,
							'&::before, &::after': {
								borderColor: theme.palette.divider,
							},
						})}
					>
						<Chip label="IKKE PÅLOGGET" sx={{ color: '#222' }} />
					</Divider>
					<Typography variant="caption" sx={{ m: 2, display: 'flex', justifyContent: 'center' }}>
						Send SMS til valgte personer med en lenke til videosamtalen:
					</Typography>
					<Grid2 container spacing={1} sx={{ m: 1, display: 'flex', justifyContent: 'center' }}>
						{this.offlineMembers
							.filter((m) => m.id)
							.map((m) => (
								<Grid2 key={m.userId}>
									<Grid container spacing={3}>
										<Grid item>
											<Chip
												sx={{
													color: 'white',
													bgcolor: this.pingMembersColorArray[m.id!.toString()],
												}}
												onClick={(e) => this.handleChange(e, m)}
												onDelete={(e) => this.handleChange(e, m)}
												deleteIcon={
													<Checkbox
														checked={this.isChecked(m.userId)}
														value={m.id}
														sx={{
															'&.Mui-checked': {
																color: 'white',
															},
															zIndex: 10,
														}}
													/>
												}
												icon={<ProfilePicture profile={m} chip />}
											/>
										</Grid>
									</Grid>
								</Grid2>
							))}
					</Grid2>
				</Box>
			);
		}

		startCall = () => {
			this.props.onStartCall(this.channel, this.membersToPing);

			// send SMS to these members
			console.log(this.membersToPing);
		};

		render() {
			const { isMobile, workspaceTheme } = this.uiState;
			const { onClose } = this.props;
			return (
				<Dialog
					open={this.open}
					onClose={onClose}
					BackdropProps={{ style: { backgroundColor: 'rgb(0,0,0,0.9)' } }}
					fullScreen={isMobile}
					fullWidth={true}
					maxWidth={'md'}
				>
					<AppBar position={isMobile ? 'fixed' : 'relative'}>
						<Toolbar>
							<VideocamIcon sx={{ mr: 3 }} />
							<Typography variant="h6">Start en ny videosamtale</Typography>
							<IconButton
								color="inherit"
								edge="start"
								onClick={onClose}
								aria-label="close"
								sx={{
									position: 'absolute',
									right: 8,
									top: 8,
								}}
							>
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</AppBar>
					<DialogContent
						dividers
						sx={{
							overflowY: 'auto',
							marginTop: isMobile ? 'var(--top-app-bar-height)' : '',
							// height: 'calc(100vh - var(--top-app-bar-height))',
							maxHeight: 'calc(100dvh - var(--top-app-bar-height))',
						}}
					>
						<Grid container spacing={0}>
							<Grid item xs={12} md={6}>
								<Typography
									variant="body2"
									sx={{ m: isMobile ? 1 : 5, textAlign: 'center', fontWeight: 'bold' }}
								>
									Når du starter en ny samtale vil alle kanalmedlemmer få mulighet til å delta.
								</Typography>
								{!isMobile && (
									<div style={{ textAlign: 'center', margin: '20px' }}>
										<img alt="" src={`/illustrations/${workspaceTheme}/phone.svg`} width="240" />
									</div>
								)}
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
								sx={{ background: 'white', color: '#212121', borderRadius: '10px', mt: 3 }}
							>
								<Grid container spacing={1}>
									<Grid item xs={12} sx={{ mt: 1 }}>
										{this.renderOnline()}
									</Grid>
									<Grid item xs={12}>
										{this.renderOffline()}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</DialogContent>

					<Alert severity="info" color="info" sx={{ m: 1, display: 'flex', justifyContent: 'center' }}>
						Videosamtalen blir automatisk tatt opp og delt i denne samtalen.
					</Alert>

					<Box sx={{ textAlign: 'center', m: 1 }}>
						<Button
							variant="contained"
							size="large"
							autoFocus
							className="md-light call-icon"
							onClick={this.startCall}
							startIcon={<VideocamIcon />}
							sx={{ mb: 2, background: '#5EA327' }}
						>
							Ring
						</Button>
					</Box>
				</Dialog>
			);
		}
	}
);

export default CreateVideoCallDialog;

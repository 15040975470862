import { makeAutoObservable, runInAction } from 'mobx';
import { MessageBlock, MessageContent, MessageDTO, MessageType, MessageWidget, ProfileType, Reaction } from '../types';
import ChatApi from '../api/endpoints/ChatApi';
import AiApi from '../api/endpoints/AiApi';
import { DateUtil } from '../helpers/DateUtil';
import { toNumber } from 'lodash';

class Message {
	id!: number;
	userId!: number;
	profileId?: number;
	channelId!: number;
	type?: MessageType;
	content?: MessageContent[];
	text: string = '';
	isSeen: number = 0;
	created!: Date;
	updated?: Date;
	deleted?: Date;
	reactions?: Reaction[] = [];
	sender!: ProfileType;
	widgets?: MessageWidget[];
	widget?: MessageWidget;
	attachments?: MessageContent[];
	blocks: MessageBlock[] = [];
	pinnedBy?: string;
	sessionId?: string;
	status: 'SENT' | 'PENDING' | 'PROCESSING' = 'SENT';

	loading = false;
	messageSuggestion = '';
	messageSuggestionReference = '';

	constructor(messageData: Partial<MessageDTO>) {
		makeAutoObservable(this, {});
		this.updateFromDTO(messageData);
	}

	async addContent(content: MessageContent) {
		this.content = this.content ? [...this.content, content] : [content];
	}

	get hasPurifiedCurrentMessage() {
		return this.messageSuggestionReference === this.text;
	}

	removeContent(content: MessageContent) {
		if (this.content && content?.file?.id !== undefined) {
			this.content = this.content.filter((c) => c.file?.id !== content.file!.id);
		}
	}

	addReaction(reaction: Reaction) {
		this.reactions = this.reactions ? [...this.reactions, reaction] : [reaction];
	}

	removeReaction(reaction: Partial<Reaction>) {
		if (this.reactions) {
			if (!reaction.id && reaction.userId) {
				// remove if userId and reaction.reaction is the same
				this.reactions = this.reactions.filter((r) => {
					const isSame = '' + r.userId === '' + reaction.userId && r.reaction === reaction.reaction;

					return !isSame;
				});
			} else {
				this.reactions = this.reactions.filter((r) => r.id !== reaction.id);
			}
		}
	}

	clearMessageSuggestion() {
		this.messageSuggestion = '';
		this.messageSuggestionReference = '';
	}

	useMessageSuggestion() {
		this.text = this.messageSuggestion;
		this.clearMessageSuggestion();
	}

	async purify() {
		this.clearMessageSuggestion();
		this.loading = true;
		const response = await AiApi.purifyMessage(this.text);
		if (response.statusCode === 200) {
			if (response.data?.choices?.[0]) {
				runInAction(() => {
					this.messageSuggestion = (response.data.choices[0].text ?? '').trim();
					this.messageSuggestionReference = this.text;
					this.loading = false;
				});
			}
		}
	}

	/**
	 * Updates the message data from a given partial DTO.
	 * @param messageData The partial data to update the message.
	 */
	updateFromDTO(messageData: Partial<MessageDTO>): void {
		type MessageKeys = keyof MessageDTO;

		Object.entries(messageData).forEach(([key, value]) => {
			if (value !== undefined && key in this) {
				const messageKey = key as MessageKeys;
				if (messageKey === 'id') {
					this.id = +value;
				} else if (messageKey === 'attachments' && Array.isArray(value)) {
					this.attachments = value as MessageContent[];
				} else if (messageKey === 'profileId') {
					this.profileId = toNumber(value);
				} else if (messageKey === 'created' || messageKey === 'updated') {
					if (typeof value === 'string') {
						this[messageKey] = DateUtil.createDateWithTimezoneOffset(value);
					}
				} else {
					this[messageKey] = value as never;
				}
			}
		});
	}

	async pin() {
		const result = await ChatApi.pinMessage(this.channelId, this.id);
		if (result.statusCode === 200) {
			// update message
			runInAction(() => {
				this.updateFromDTO(result.data);
			});

			return true;
		}
	}

	async unpin() {
		const result = await ChatApi.unpinMessage(this.channelId, this.id);
		if (result.statusCode === 200) {
			// update message
			runInAction(() => {
				this.updateFromDTO(result.data);
			});

			return true;
		}
	}

	async delete() {
		const result = await ChatApi.deleteMessage(this.channelId, this.id);
		if (result.statusCode === 200) {
			// update message
			console.log('delete message', result.data);
			runInAction(() => {
				this.deleted = new Date();
				this.text = 'This message has been deleted';
			});

			return true;
		}
	}

	async save() {
		const result = await ChatApi.updateMessage(this.channelId, this);
		if (result.statusCode === 200) {
			// update message
			runInAction(() => {
				this.updateFromDTO(result.data);
			});

			return true;
		}
	}

	/**
	 * Converts the Message instance back to a DTO.
	 * @returns The DTO representation of the message.
	 */
	toDTO(): MessageDTO {
		return {
			id: this.id,
			userId: this.userId,
			profileId: this.profileId ? this.profileId : undefined,
			channelId: this.channelId,
			type: this.type,
			content: this.content,
			text: this.text,
			isSeen: this.isSeen,
			created: this.created,
			updated: this.updated,
			reactions: this.reactions,
			sender: this.sender,
			widgets: this.widgets,
			widget: this.widget,
			attachments: this.attachments,
			pinnedBy: this.pinnedBy,
			sessionId: this.sessionId,
			status: this.status,
			blocks: this.blocks,
		};
	}
}

export default Message;

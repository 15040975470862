import { Box, styled } from '@mui/material';
import { useMemo } from 'react';

// Define custom props for the FloatingOrb component
interface FloatingOrbProps {
	size: number; // Size of the orb
	duration: number; // Duration of the animation
	delay: number; // Delay before animation starts
}
// Extend Box with FloatingOrbProps
const FloatingOrb = styled(Box)<FloatingOrbProps>(({ size, duration, delay }) => ({
	width: `${size}px`,
	height: `${size}px`,
	background: 'radial-gradient(circle, rgba(255,255,255,0.3), rgba(255,255,255,0))',
	borderRadius: '50%',
	position: 'absolute',
	animation: `orbit ${duration}s linear infinite`,
	animationDelay: `${delay}s`,
	opacity: 0,
	transition: 'opacity 0.5s',
	'@keyframes orbit': {
		'0%': {
			transform: `rotate(0deg) translate(${size * 2}px) rotate(0deg)`,
			opacity: 0.4,
		},
		'50%': {
			transform: `rotate(180deg) translate(${size * 2}px) rotate(-180deg)`,
			opacity: 0.8,
		},
		'100%': {
			transform: `rotate(360deg) translate(${size * 2}px) rotate(-360deg)`,
			opacity: 0.6,
		},
	},
}));

type FloatingOrbsProps = {
	numOrbs: number;
};

const generateOrbs = (numOrbs: number) => {
	return Array.from({ length: numOrbs }).map((_) => ({
		size: Math.random() * 40 + 20, // Orb size between 20-60px
		duration: Math.random() * 5 + 3, // Animation duration between 3-8s
		delay: Math.random() * 2, // Random delay
		position: {
			top: `${Math.random() * 40 + 10}%`, // Random vertical position
			left: `${Math.random() * 80 + 10}%`, // Random horizontal position
		},
	}));
};

const FloatingOrbs = ({ numOrbs }: FloatingOrbsProps) => {
	const orbs = useMemo(() => generateOrbs(numOrbs), []);

	return (
		<Box
			sx={{
				position: 'absolute',
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				zIndex: -1,
			}}
		>
			{' '}
			{orbs.map((orb, index) => (
				<FloatingOrb
					key={index}
					size={orb.size}
					duration={orb.duration}
					delay={orb.delay}
					sx={{
						top: orb.position.top,
						left: orb.position.left,
						transformOrigin: 'center',
					}}
				/>
			))}
		</Box>
	);
};

export default FloatingOrbs;

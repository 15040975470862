import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { hotjar } from 'react-hotjar';
import { io, Socket } from 'socket.io-client';
import TrakApi, { TrakEvent, TrakEventDTO, TrakMeta } from '../api/endpoints/TrakApi';
import AsyncStorageHelper from '../auth/AsyncStorageHelper';
import config from '../config/config';
import { ConnectionMetadataExternal } from '../Connection.types';
import LiteEvent from '../helpers/LiteEvent';
import LogUtil from '../helpers/LogUtil';
import { Trigger } from '../Trigger.types';
import { ChatStore } from './ChatStore';
import { CompanyStore } from './company/CompanyStore';
import { EventStore } from './EventStore';
import { FileStore } from './FileStore';
import { InsightStore } from './InsightStore';
import { RatingStore } from './RatingStore';
import { NotificationStore } from './NotificationStore';
import { PricingPlanStore } from './PricingPlanStore';
import { ProfileStore } from './ProfileStore';
import { ProjectStore } from './ProjectStore';
import { SettingsStore } from './SettingsStore';
import { TodoStore } from './TodoStore';
import { UiState } from './UiState';
import { UserStore } from './UserStore';
import { VideoChatStore } from './VideoChatStore';
import { WorkspaceStore } from './WorkspaceStore';
import { DocumentSigningStore } from './DocumentSigningStore';
import KobbrQuotesStore from './kobbr/KobbrQuoteStore';
import AgentStore from './agent/AgentStore';
import PartnershipManager from './partnership/PartnershipManager';
import { TemplateStore } from './TemplateStore';
import { ChecklistStore } from './checklist/ChecklistStore';
import CustomerStore from './customer/CustomerStore';
import { CampaignStore } from './campaign/CampaignStore';

/**
 * RootStore
 */
export class RootStore {
	private readonly onNavigate = new LiteEvent<any>();
	private readonly onOnline = new LiteEvent<any>();
	private readonly onOffline = new LiteEvent<any>();
	private readonly onPageVisible = new LiteEvent<any>();
	private readonly onPageHidden = new LiteEvent<any>();
	sessionId: string | undefined;

	static readonly MAX_SOCKET_REAUTH_ATTEMPTS = 20;
	static readonly ONLINE_INTERVAL_MS = 10000;
	static readonly TRAK_INTERVAL_MS = 30000;

	userStore: UserStore = new UserStore(this);
	profileStore: ProfileStore = new ProfileStore(this);
	projectStore: ProjectStore = new ProjectStore(this);
	chatStore: ChatStore = new ChatStore(this);
	settingsStore: SettingsStore = new SettingsStore(this);
	videoChatStore: VideoChatStore = new VideoChatStore(this);
	companyStore: CompanyStore = new CompanyStore(this);
	uiState: UiState = new UiState(this);
	workspaceStore: WorkspaceStore = new WorkspaceStore(this);
	notificationStore: NotificationStore = new NotificationStore(this);
	fileStore: FileStore = new FileStore(this);
	pricingPlanStore: PricingPlanStore = new PricingPlanStore(this);
	eventStore: EventStore = new EventStore(this);

	ratingStore: RatingStore = new RatingStore(this);
	templateStore: TemplateStore = new TemplateStore(this);
	documentStore: DocumentSigningStore = new DocumentSigningStore(this);
	kobbrQuoteStore: KobbrQuotesStore = new KobbrQuotesStore(this);
	agentStore: AgentStore = new AgentStore(this);
	partnershipManager: PartnershipManager = new PartnershipManager(this);
	todoStore: TodoStore = new TodoStore(this);
	checklistStore: ChecklistStore = new ChecklistStore(this);
	customerStore: CustomerStore = new CustomerStore(this);

	insightStore: InsightStore = new InsightStore(this);

	campaignStore: CampaignStore = new CampaignStore(this);

	socket: Socket | undefined;
	socketReauthAttempts: number = 0;
	isOnlineInterval: any;
	isOnline: boolean = true;
	isInitiated: boolean = false;

	trakUid: string = '';
	trakSid: string = '';
	trakMeta: Partial<TrakMeta> = {};
	trakOnline: TrakEvent | undefined;
	trakPageUrl: string = '';
	trakInterval: any;

	constructor() {
		makeAutoObservable(this, {
			connectionMetadata: false,
			trakUid: false,
			trakSid: false,
			trakOnline: false,
			trakPageUrl: false,
		});

		this.processTrigger = this.processTrigger.bind(this);

		this.init();
	}

	init() {
		if (this.isInitiated) {
			return;
		}

		this.isInitiated = true;
		this.sessionId = AsyncStorageHelper.getCache('sessionId');
		// wait for user sign in before connecting to Websocket
		this.setupSocketConnection();
		this.userStore.UserIdChanged.on(() => {
			this.reauthSocket();
		});

		//  We need to do this as early as possible to get the right colors and logos
		this.uiState.autoselectTheme();

		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);

		document.addEventListener('visibilitychange', this.updateVisibilityStatus);

		this.initAnalytics();
		this.initMarketing();
		this.initTrak();
	}

	public get Navigate() {
		return this.onNavigate.expose();
	}

	public get Online() {
		return this.onOnline.expose();
	}

	public get Offline() {
		return this.onOffline.expose();
	}

	public get PageVisible() {
		return this.onPageVisible.expose();
	}

	public get PageHidden() {
		return this.onPageHidden.expose();
	}

	get connectionMetadata(): ConnectionMetadataExternal {
		return {
			userAgent: navigator?.userAgent,
			platform: navigator?.platform,
			network: {
				downlink: (navigator as any)?.connection?.downlink,
				effectiveType: (navigator as any)?.connection?.effectiveType,
				rtt: (navigator as any)?.connection?.rtt,
			},
			pageVisible: !document.hidden,
		};
	}

	navigate(options: { path: string; historyOnly?: boolean }) {
		this.onNavigate.trigger(options);
	}

	initTrak = async () => {
		this.trakUid = await AsyncStorageHelper.getCache('trakUid');
		const result = await TrakApi.init(this.trakUid, this.trakSid);
		if (result.statusCode === 200) {
			this.trakUid = result.data.uid;
			this.trakSid = result.data.sid;

			AsyncStorageHelper.setCache('trakUid', this.trakUid);
		}
		runInAction(() => this.setTrakInterval);
	};

	setTrakMeta = (meta: Partial<TrakMeta>) => {
		this.trakMeta = meta;
	};

	trakEvent = async (event: Partial<TrakEventDTO>, meta?: Partial<TrakMeta>) => {
		if (!(this.trakUid && this.trakSid)) {
			return undefined;
		}
		event.apiKey = config.appId;
		meta = { ...this.trakMeta, ...meta, url: window.location.href };
		const result = await TrakApi.track(this.trakUid, this.trakSid, event as TrakEventDTO, meta as TrakMeta);

		if (result.statusCode === 200) {
			return {
				...event,
				meta: {
					...meta,
					...result.data?.meta,
				},
				...result.data,
			};
		}
		return undefined;
	};

	async initAnalytics() {
		const consent = await AsyncStorageHelper.getCachedCookieConsent();
		if (consent?.allowAnalytics) {
			console.info('Analytics allowed 😄');
			if (window.location.host?.includes('dev.')) {
				return;
			}
			runInAction(() => {
				if (this.uiState.workspaceTheme === 'befare') {
					hotjar.initialize(config.hotjar.appId.befare, config.hotjar.softwareVersion);
				} else if (this.uiState.workspaceTheme === 'norgeseliten') {
					hotjar.initialize(config.hotjar.appId.minelektriker, config.hotjar.softwareVersion);
				}
			});

			// @todo init google analytics
			if ((window as any).initGA) {
				(window as any).initGA();
			}
		}
	}

	async initMarketing() {
		const consent = await AsyncStorageHelper.getCachedCookieConsent();
		if (consent?.allowMarketing) {
			console.info('Marketing allowed 😄');
			// Init fb tracking, google ads, etc
		}
	}

	updateVisibilityStatus = () => {
		if (document.hidden) {
			this.onPageHidden.trigger();
		} else {
			this.onPageVisible.trigger();
		}
	};

	updateOnlineStatus = () => {
		console.log(`%cI'm ${navigator.onLine ? 'ONLINE' : 'OFFLINE'}`, 'color: green;');
		// @todo consider checking this.userStore.lastOnlineIndicator
		if (navigator.onLine) {
			this.onOnline.trigger();
			this.isOnline = true;
		} else {
			this.onOffline.trigger();
			this.isOnline = false;
		}
	};

	/**
	 * Setup socket connection
	 */
	setupSocketConnection() {
		if (this.socket) {
			return;
		}

		this.socket = io(`//${config.hosts.socket}`, {
			transports: ['websocket'],
			secure: true,
		});

		this.socket.on('connect_error', (error: any) => {
			LogUtil.debug('Socket connect error', error);
		});

		this.socket.on('connect', () => {
			runInAction(() => {
				this.setIsOnlineInterval();
				this.isOnline = true;
				this.onOnline.trigger();
				this.socket?.emit('session-id', this.sessionId);
			});
		});

		this.socket.on('disconnect', () => {
			runInAction(() => {
				this.socketReauthAttempts = 0;
				this.userStore.lastOnlineIndicator = 0;
				this.isOnline = false;
				this.onOffline.trigger();
				clearInterval(this.isOnlineInterval);
			});
		});

		this.socket.on('auth', (data: any) => {
			runInAction(() => {
				if (data.status === 403) {
					this.userStore.lastOnlineIndicator = 0;
					this.reauthSocket();
				} else {
					this.socketReauthAttempts = 0;
				}
			});
		});

		this.socket.on('pong', () => {
			runInAction(() => {
				this.userStore.lastOnlineIndicator = Date.now();
				this.userStore.checkLoginStatus();
			});
		});

		// this.socket.on('session-id', (sessionId: string) => {
		// 	runInAction(() => {
		// 		if (!this.sessionId && sessionId.length > 0) {
		// 			this.sessionId = sessionId;
		// 			AsyncStorageHelper.setCache('sessionId', this.sessionId);
		// 		}
		// 		this.socket?.emit('session-id', this.sessionId);
		// 	});
		// });

		this.socket.on('session-id', (sessionId: string) => {
			// console.log(`GOT SESSION ID FROM SOCKET: `, {
			// 	incomingSessionId: sessionId,
			// 	currentSessionId: this.sessionId,
			// });

			runInAction(() => {
				// If we don't have a session id, start a new session with the incoming sessionId
				if (!this.sessionId) {
					this.sessionId = sessionId;
				}

				// If the incoming session id is different from the current session id, reauth the socket
				if (this.sessionId !== sessionId) {
					this.reauthSocket();
					// console.log(`WE HATE THE INCOMING SESSION ID! We like this one: ${this.sessionId}`);
				} else if (this.sessionId) {
					// If the incoming session id is the same as the current session id, set the session id and init chat
					this.sessionId = sessionId;
					AsyncStorageHelper.setCache('sessionId', this.sessionId);
					// this.socket?.emit('session-id', this.sessionId);
					// console.log(`GOT SESSION ID FROM SOCKET: ${this.sessionId}`);
				}
			});
		});

		this.socket.on('trigger', (trigger) => {
			this.processTrigger(trigger);
		});

		this.socket?.emit('ping', { message: 'nonsense' });
	}

	setIsOnlineInterval = () => {
		clearInterval(this.isOnlineInterval);
		this.isOnlineInterval = setInterval(this.onOnlineInterval, RootStore.ONLINE_INTERVAL_MS);
		this.onOnlineInterval();
	};

	onOnlineInterval = () => {
		this.emitEvent('user-online', this.connectionMetadata);
	};

	setTrakInterval = () => {
		clearInterval((document as any).trakInterval);
		(document as any).trakInterval = setInterval(this.onTrakInterval, RootStore.TRAK_INTERVAL_MS);
		this.onTrakInterval();
	};

	onTrakInterval = () => {
		this.trakPageUrl = window.location.href;
		this.trakEvent(
			{
				id: this.trakOnline?.id,
				type: 'UserOnline',
				outcome: 'PageVisible',
				value: !document.hidden ? 'visible' : 'hidden',
			},
			this.trakOnline?.meta
		)
			.then((event) => {
				if (event) {
					runInAction(() => {
						this.trakOnline = event;
					});
				}
			})
			.catch((err) => {
				LogUtil.warn('Trak error', err);
			});
	};

	async processTrigger(trigger: Trigger<any>) {
		try {
			const triggerParts = trigger.urn.split(':');
			// eslint-disable-next-line no-unused-vars
			const [_, __, ___, type] = triggerParts;
			if (type) {
				switch (type) {
					case 'channel':
						this.chatStore.processTrigger(trigger);
						break;
					case 'project':
						this.projectStore.processTrigger(trigger);
						break;
					case 'notification':
						this.notificationStore.processTrigger(trigger);
						break;
					case 'video-chat':
						this.videoChatStore.processTrigger(trigger);
						break;
					case 'event':
						this.eventStore.processTrigger(trigger);
						break;
					case 'trak':
						this.insightStore.processTrakTrigger(trigger);
						break;
					case 'user':
						this.profileStore.processUserTrigger(trigger);
						break;
					case 'document_signing':
						this.documentStore.processTrigger(trigger);
						break;
					case 'kobbr':
						this.kobbrQuoteStore.processTrigger(trigger);
						break;
					case 'agent':
						this.agentStore.processTrigger(trigger);
						break;
					case 'partnership':
						this.partnershipManager.processTrigger(trigger);
						break;
					case 'campaign':
						this.campaignStore.processTrigger(trigger);
						break;
					case 'scrape':
						this.campaignStore.processScraperTrigger(trigger);
						break;
					default:
						console.info('Unhandled trigger', trigger.urn);
				}
			}
		} catch (err) {
			LogUtil.error(`error processing trigger`, trigger, err);
		}
	}

	/**
	 * Run reauth for socket connection
	 */
	async reauthSocket() {
		if (this.socketReauthAttempts > RootStore.MAX_SOCKET_REAUTH_ATTEMPTS) {
			LogUtil.warn(`Reached max reauth attempts for socket: ${RootStore.MAX_SOCKET_REAUTH_ATTEMPTS}`);
			return;
		}

		const befareJWT = await AsyncStorageHelper.getCachedBefareJWT();

		if (befareJWT && befareJWT.length > 0) {
			runInAction(() => this.socketReauthAttempts++);
			this.emitEvent('auth', {
				jwt: befareJWT,
			});
		}
	}

	/**
	 * @param  {String} channel
	 * @param  {any} event
	 */
	emitEvent(channel: string, event: any) {
		if (this.socket) {
			this.socket.emit(channel, event);
		}
	}
}

import {
	Button,
	CircularProgress,
	Stack,
	styled,
	StyledComponentProps,
	Link as MuiLink,
	Typography,
	Box,
	AlertColor,
	keyframes,
	Slide,
	Dialog,
	DialogContent,
	DialogContentText,
} from '@mui/material';

import { darken } from '@mui/material/styles';
import { observer } from 'mobx-react';
import React from 'react';
import StoreContext from '../../../stores/StoreContext';
import { HookTypes, withHooks } from '../../../utils/withHooks';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import Spotlight from './Spotlight';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import FloatingOrbs from './FloatingOrbs';

import BrowserEmulator from './BrowserEmulator';
import { toast } from 'react-toastify';
import config from '../../../config/config';
import { FetchWrapper } from '../../../fetch/FetchWrapper';
import SmartChatDemoUrlInput from './SmartChatDemoUrlInput';
import { Link } from 'react-router-dom';
import type { RootStore } from '../../../stores/RootStore';
import { ScrapeResultStatus } from '../../../dto/scraper.types';
import { AppLogo } from '../../logo/AppLogo';
import FullWidthAlert from '../../common/FullWidthAlert';
import { ScrapeResult } from '../../../stores/campaign/CampaignStore';
import { TransitionProps } from '@mui/material/transitions';

const rainbowBorderAnimation = keyframes`
	0%, 100% {
		background-position: 0 0;
	}

	50% {
		background-position: 300%;
	}
`;

const Grid2Section = styled(Grid2)(({ theme }) => ({
	// padding: theme.spacing(2),
	maxWidth: '1024px',
	paddingBottom: '3rem',
}));

const TransitionUp = React.forwardRef(function Transition(
	props: TransitionProps & { children: any },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} in={true} {...props} />;
});

type Props = {
	onWebUrlSubmit?: (url: string, mode: 'desktop' | 'mobile') => void;
	asComponent?: boolean;
	preventNavigation?: boolean;
} & HookTypes &
	StyledComponentProps;

const SmartDemo = observer(
	class SmartDemo extends React.Component<Props> {
		static readonly contextType = StoreContext;
		previewUrl?: string;
		BefareChatId = '488FB28F-A1A1-4725-ACCF-29AF461445E6';
		isLoading = true;
		errorUrl?: string;
		jobId?: string;
		injectedDomain?: string;
		injectedMode?: 'desktop' | 'mobile';
		hasReadInfo: boolean = false;
		hideInfoContainer: boolean = false;

		get params() {
			return this.props.params!;
		}

		get navigate() {
			return this.props.navigate!;
		}

		get location() {
			return this.props.location!;
		}

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				errorUrl: observable,
				previewUrl: observable,
				isLoading: observable,
				jobId: observable,
				injectedDomain: observable,
				injectedMode: observable,
				hasReadInfo: observable,
				hideInfoContainer: observable,
				onWebUrlSubmit: action,
				processNewUrl: action,
				domain: computed,
				mode: computed,
				queryParams: computed,
				scrapeResult: computed,
				mostRecentScrapeResultLog: computed,
				reprocessUrl: action,
				onInfoCloseClick: action,
				showInfoContainer: action,
			});

			reaction(
				() => this.scrapeResult?.status,
				(result) => {
					if (result) {
						if (result === ScrapeResultStatus.ALL_OK) {
							toast.success('Forhåndsvisning klar');
							this.isLoading = false;
						} else if (result === ScrapeResultStatus.FAIL) {
							toast.error('Kunne ikke generere forhåndsvisning');
							this.SendMessageAndOpen(
								`Det gikk ikke å generere forhåndsvisning av ${this.domain}. Kan dere gi meg en demo og hjelpe meg med å sette opp SmartChat?`
							);
							this.isLoading = false;
							this.errorUrl = this.domain ?? undefined;
						}
					}
				}
			);
		}

		get queryParams() {
			return new URLSearchParams(this.location.search);
		}

		// get domain from query params
		get domain() {
			return this.injectedDomain ?? this.queryParams.get('domain');
		}
		// get mode from query params
		get mode() {
			return this.injectedMode ?? this.queryParams.get('mode') ?? 'desktop';
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get campaignStore() {
			return this.rootStore.campaignStore;
		}

		get scrapeResult(): ScrapeResult | null {
			if (!this.jobId) {
				return null;
			}

			const scrapeResultRaw = this.campaignStore.findScrapeResult(this.jobId);

			if (!scrapeResultRaw) {
				return null;
			}

			return {
				...scrapeResultRaw,
				log: scrapeResultRaw.log.filter((log) => !log.suppressMessageDisplay),
			};
		}

		get mostRecentScrapeResultLog() {
			if (!this.scrapeResult) {
				return null;
			}
			return this.scrapeResult.log[0];
		}

		componentDidUpdate(): void {
			this.reprocessUrl();
		}

		reprocessUrl = () => {
			if (this.domain && this.domain !== this.previewUrl) {
				this.processNewUrl();
			}
		};

		componentDidMount() {
			try {
				if ((window as any).BefareChat) {
					(window as any).BefareChat.init(this.BefareChatId);
				}
			} catch (error) {
				console.log('BefareAiChat', error);
			}
		}

		componentWillUnmount(): void {
			try {
				if ((window as any).BefareChat) {
					(window as any).BefareChat.destroy();
				}
				this.processNewUrl();
			} catch (error) {
				console.log('BefareAiChat', error);
			}
		}

		openChat = () => {
			try {
				if ((window as any).BefareChat) {
					(window as any).BefareChat.open();
				}
			} catch (error) {
				console.log('BefareAiChat', error);
			}
		};

		showInfoContainer = () => {
			this.hideInfoContainer = false;
			this.hasReadInfo = false;
		};

		onWebUrlSubmit = (url: string, mode: 'desktop' | 'mobile' = 'desktop') => {
			// we want just the domain, so let's strip the protocol and path using URL constructor
			// if missing https://, we'll add it
			if (!url.startsWith('http')) {
				url = 'https://' + url;
			}
			try {
				if (this.props.onWebUrlSubmit) {
					this.props.onWebUrlSubmit(url, mode);
				}

				const domain = new URL(url).hostname;

				this.previewUrl = undefined;
				// if we are in the same domain, we don't need to navigate
				if (domain === this.domain) {
					this.processNewUrl().catch(console.error);
				} else if (!this.props.preventNavigation) {
					this.navigate(
						`/smart-demo/?domain=${domain}&mode=${mode}${this.rootStore.sessionId ? `&sessionId=${this.rootStore.sessionId}` : ''}`
					);
				} else {
					this.injectedDomain = domain;
					this.injectedMode = mode;
				}
			} catch (error) {
				console.error('Invalid URL', error);
				toast.error('Ugyldig URL');
			}
		};

		processNewUrl = async () => {
			let url = this.domain;
			if (!url) {
				return;
			}
			const mode = this.mode;
			this.isLoading = true;
			if (!url.startsWith('http')) {
				url = 'https://' + url;
			}
			const domain = new URL(url).hostname;
			if (this.previewUrl === domain) {
				toast.info('Henter forhåndsvisning');
				return;
			}

			if (this.errorUrl === domain) {
				toast.warn(`Får ikke til å hente forhåndsvisning av ${domain}`);
				return;
			}

			this.errorUrl = undefined;
			this.previewUrl = undefined;
			const result = await FetchWrapper.get(
				`//${config.hosts.api}/v1/smart-chat/demo?url=${domain}&mode=${mode}${this.rootStore.sessionId ? `&sessionId=${this.rootStore.sessionId}` : ''}`
			).catch((err) => {
				console.warn('Error fetching preview:', err);

				return {
					statusCode: 500,
					data: { jobId: undefined },
				};
			});
			runInAction(() => {
				if (result.statusCode === 200) {
					this.previewUrl = domain;
					this.errorUrl = undefined;
					this.jobId = result.data.jobId;
				} else {
					this.isLoading = false;
					this.previewUrl = undefined;
					this.jobId = undefined;
					this.errorUrl = domain;
					toast.error('Kunne ikke hente forhåndsvisning');
				}
			});
		};

		SendMessageAndOpen(message: string) {
			try {
				if ((window as any).BefareChat) {
					(window as any).BefareChat.sendMessageAndOpen(message);
				}
			} catch (error) {
				console.log('BefareAiChat', error);
			}
		}

		trySmartChat = () => {
			this.SendMessageAndOpen('Hei, jeg er interessert i en demo av SmartChat');
		};

		onInfoCloseClick = () => {
			this.hasReadInfo = true;
			// if error, open chat
			if (this.scrapeResult?.status === ScrapeResultStatus.FAIL) {
				this.SendMessageAndOpen(
					`Hei, jeg har problemer med å hente forhåndsvisning av ${this.previewUrl}. Kan dere hjelpe meg?`
				);
			}

			setTimeout(() => {
				runInAction(() => {
					this.hideInfoContainer = true;
				});
			}, 500);
		};

		renderUrlInput() {
			const { asComponent } = this.props;

			return (
				<Grid2Section
					id="smart-demo-input-lad"
					container
					direction="column"
					xs={12}
					justifyContent="center"
					alignItems="center"
					sx={{
						display: 'flex',
						minHeight: asComponent ? 'inherit' : '80vh',
						position: 'relative',
						opacity: this.domain ? '0' : '1',
						transition: 'opacity 0.5s',
					}}
				>
					{/* <FloatingOrbs numOrbs={5} /> */}
					<SmartChatDemoUrlInput
						onWebUrlSubmit={this.onWebUrlSubmit}
						preventNavigation={this.props.preventNavigation}
					/>
				</Grid2Section>
			);
		}

		renderContent() {
			if (this.isLoading) {
				return (
					<Grid2Section xs={12} justifyContent="center">
						<Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
							<FloatingOrbs numOrbs={5} />
							<CircularProgress />
							<Typography
								variant="h4"
								sx={(theme) => ({
									color: theme.palette.primary.contrastText,
								})}
							>
								Vi henter <strong>Kunnskap</strong> om {this.domain}...
							</Typography>
							{this.mostRecentScrapeResultLog &&
								// <Typography
								// 	variant="body1"
								// 	sx={(theme) => ({
								// 		color: theme.palette.primary.contrastText,
								// 	})}
								// >
								// 	{this.mostRecentScrapeResultLog?.summaryForUser ??
								// 		this.mostRecentScrapeResultLog?.message}
								// </Typography>
								this.renderAutoKnowledge({
									excludeSystemMessages: true,
									action: (
										<Button
											onClick={this.showInfoContainer}
											variant="outlined"
											size="small"
											color="secondary"
											sx={(theme) => ({
												color: darken(theme.palette.text.primary, 0.2),
												borderColor: darken(theme.palette.text.primary, 0.2),
												borderRadius: '.5rem',
												'&:hover': {
													color: theme.palette.text.primary,
													borderColor: theme.palette.text.primary,
												},
											})}
										>
											Hva er dette?
										</Button>
									),
								})}
						</Stack>
					</Grid2Section>
				);
			}

			if (!this.previewUrl) {
				return (
					<Grid2Section
						xs={12}
						justifyContent="center"
						alignItems="center"
						sx={{ display: 'flex', flexDirection: 'column' }}
					>
						<Typography
							variant="h4"
							sx={(theme) => ({
								color: theme.palette.primary.contrastText,
								textAlign: 'center',
							})}
						>
							Woops!
						</Typography>
						<Typography
							variant="h4"
							sx={(theme) => ({
								color: theme.palette.primary.contrastText,
								textAlign: 'center',
							})}
						>
							Vi klarte ikke å lage forhåndsvisning
						</Typography>
						<br />
						<Typography
							variant="body1"
							sx={(theme) => ({
								color: theme.palette.primary.contrastText,
								textAlign: 'center',
							})}
						>
							Det kan være at nettsiden ikke støttes. SmartChat vil fungere likevel, men vi klarer
							dessverre ikke å lage en forhåndsvisning.
						</Typography>
						<br />
						<Typography
							variant="body1"
							sx={(theme) => ({
								color: theme.palette.primary.contrastText,
								textAlign: 'center',
							})}
						>
							Prøv en annen nettside, eller{' '}
							<MuiLink
								href="/smart-demo"
								sx={(theme) => ({
									color: theme.palette.primary.contrastText,
									textDecoration: 'underline',
								})}
							>
								gå tilbake
							</MuiLink>
						</Typography>
						<br />
						<Button component={Link} to="/smart-demo" variant="outlined">
							Prøv en annen nettside
						</Button>
					</Grid2Section>
				);
			}

			return (
				<>
					{this.renderAutoKnowledge({
						excludeSystemMessages: true,
						action: (
							<Button
								onClick={this.showInfoContainer}
								variant="outlined"
								size="small"
								sx={(theme) => ({
									color: darken(theme.palette.text.primary, 0.2),
									borderColor: darken(theme.palette.text.primary, 0.2),
									borderRadius: '.5rem',
									'&:hover': {
										color: theme.palette.text.primary,
										borderColor: theme.palette.text.primary,
									},
								})}
							>
								Hva er dette?
							</Button>
						),
					})}
					<Grid2Section xs={12} alignItems="center" justifyContent="center">
						<BrowserEmulator previewUrl={this.previewUrl} onUrlSubmit={this.onWebUrlSubmit} />
					</Grid2Section>
					{/* <Grid2Section
						xs={12}
						sx={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Button size="large" onClick={this.trySmartChat} variant="outlined">
							Jeg vil prøve SmartChat på min side
						</Button>
					</Grid2Section> */}
				</>
			);
		}

		renderAutoKnowledge(options?: {
			concatenateLogs?: boolean;
			excludeSystemMessages?: boolean;
			action?: React.ReactNode;
		}) {
			let severity: AlertColor = 'info';

			if (
				[ScrapeResultStatus.ALL_OK, ScrapeResultStatus.SUCCESS].includes(
					this.scrapeResult?.status ?? ScrapeResultStatus.PROCESSING
				)
			) {
				severity = 'success';
			}

			if (this.scrapeResult?.status === ScrapeResultStatus.FAIL) {
				severity = 'warning';
			}

			// const contentt = (
			// 	options?.concatenateLogs ?
			// 		this.scrapeResult?.log.map((log) => (log.summaryForUser ?? (options?.excludeSystemMessages ? undefined : log.message)))
			// 			.filter(el => isString(el))
			// 			.reverse()
			// 		: this.mostRecentScrapeResultLog?.summaryForUser ?? (options?.excludeSystemMessages ? undefined : this.mostRecentScrapeResultLog?.message)
			// ) ?? 'Henter data...';

			let contentt: string | string[] =
				this.mostRecentScrapeResultLog?.summaryForUser ??
				(options?.excludeSystemMessages ? undefined : this.mostRecentScrapeResultLog?.message) ??
				'Henter data...';

			if (options?.concatenateLogs) {
				contentt = [];

				this.scrapeResult?.log.forEach((log) => {
					if (log.message && !options.excludeSystemMessages) {
						(contentt as string[]).unshift(`SYSTEM: ${log.message}`);
					}
					if (log.summaryForUser) {
						(contentt as string[]).unshift(log.summaryForUser);
					}

					if (!log.message && !log.summaryForUser) {
						console.warn(`Log entry with no message or summaryForUser`, log);
					}
				});
			}

			return (
				<FullWidthAlert
					severity={severity}
					title={`Auto-Kunnskap for ${this.domain}`}
					customContent={contentt}
					action={options?.action}
				/>
			);
		}

		// renderInfoContainer() {
		// 	return (
		// 		<Box
		// 			sx={(theme) => ({
		// 				transition: "opacity 0.5s",
		// 				opacity: this.hasReadInfo ? "0" : "1",
		// 				position: "absolute",
		// 				top: "10%",
		// 				left: "50%",
		// 				transform: "translateX(-50%)",
		// 				textAlign: "center",
		// 				backgroundColor: "rgba(0,0,0,0.8)",
		// 				backdropFilter: "blur(5px)",
		// 				borderRadius: "1rem",
		// 				padding: 0,
		// 				border: `1px solid ${theme.palette.primary.main}`,
		// 				"&::before, &::after": {
		// 					content: '""',
		// 					position: "absolute",
		// 					zIndex: -1,
		// 					width: "calc(100% + 6px)",
		// 					height: "calc(100% + 6px)",
		// 					top: "-3px",
		// 					left: "-3px",
		// 					background:
		// 						"linear-gradient(45deg, #6C63FF, #9D4EDD, #5A55D6, #4C1D95, #8B5CF6, #4A90E2, #00B4D8, #6C63FF, #8B5CF6, #4C1D95, #5A55D6, #9D4EDD, #6C63FF)",
		// 					backgroundSize: "300% 300%",
		// 					borderRadius: "0.5rem",
		// 					animation: `${rainbowBorderAnimation} 15s linear infinite`,
		// 				},
		// 				"&::after": {
		// 					filter: "blur(5px)",
		// 					transform: "translate3d(0, 0, 0)",
		// 				},
		// 			})}
		// 		>
		// 			<Box
		// 				sx={(theme) => ({
		// 					backgroundColor: theme.palette.background.paper,
		// 					margin: "2px",
		// 					padding: theme.spacing(4),
		// 					borderRadius: "0.8rem",
		// 				})}
		// 			>
		// 				<Stack
		// 					spacing={4}
		// 					sx={(theme) => ({
		// 						color: theme.palette.text.primary,
		// 						justifyContent: "center",
		// 						alignItems: "center",
		// 					})}
		// 				>
		// 					{/* <Typography variant="h1" sx={(theme) => ({
		// 							color: theme.palette.text.disabled
		// 						})}>SmartChat</Typography> */}
		// 					<Stack direction="column" spacing={1}>
		// 						<AppLogo
		// 							height="24px"
		// 							width="auto"
		// 							darkMode={this.rootStore.uiState.isDarkMode}
		// 							disableBanner={false}
		// 						/>
		// 						<Typography variant="caption">Online Demo</Typography>
		// 					</Stack>
		// 					<Typography variant="body1">
		// 						Denne demoen viser hvordan SmartChat vil fungere på{" "}
		// 						{this.domain}.
		// 					</Typography>
		// 					{/* <Typography variant="body1">
		// 								Mens du leser dette, henter en vi ut informasjon fra nettsiden, og genererer en forhåndsvisning av SmartChat
		// 							</Typography> */}
		// 					{/* <Box sx={(theme) => ({
		// 							width: '100%',
		// 							height: '100px',
		// 							// backgroundColor: theme.palette.background.paper,
		// 							borderRadius: '.5rem',
		// 							margin: theme.spacing(2),
		// 						})}>
		// 							<Typography variant="overline" sx={(theme) => ({
		// 								color: theme.palette.text.primary,
		// 								textAlign: 'center',
		// 								padding: theme.spacing(4),
		// 							})}>
		// 								{this.mostRecentScrapeResultLog?.summaryForUser ??
		// 									this.mostRecentScrapeResultLog?.message ?? 'Henter data...'}
		// 							</Typography>
		// 						</Box> */}
		// 					{this.renderAutoKnowledge({ concatenateLogs: true, excludeSystemMessages: true })}
		// 					{/* <Typography variant="body1">
		// 								Vi analyserer automatisk opptil 20 sider og genererer <strong>Kunnskap</strong>, som integreres i SmartChat
		// 							</Typography>
		// 							<Typography variant="body1">
		// 								Når chatten er fullført, vil resultatet sendes til <strong>SmartChat Tickets</strong>
		// 							</Typography> */}
		// 					<Typography variant="body1">
		// 						Prøv den selv ved å klikke på Chatboblen nederst til høyre
		// 						i demo-vinduet.
		// 					</Typography>

		// 					<Button
		// 						onClick={this.onInfoCloseClick}
		// 						variant="outlined"
		// 						size="large"
		// 						sx={(theme) => ({
		// 							color: darken(theme.palette.text.primary, .2),
		// 							borderColor: darken(theme.palette.text.primary, .2),
		// 							borderRadius: '.5rem',
		// 							'&:hover': {
		// 								color: theme.palette.text.primary,
		// 								borderColor: theme.palette.text.primary,
		// 							}
		// 						})}>
		// 						Prøv nå!
		// 					</Button>
		// 				</Stack>
		// 			</Box>
		// 		</Box>
		// 	)
		// }

		renderInfoDialogContent() {
			return (
				// <Box
				// 	sx={(theme) => ({
				// 		transition: "opacity 0.5s",
				// 		opacity: this.hasReadInfo ? "0" : "1",
				// 		position: "absolute",
				// 		top: "10%",
				// 		left: "50%",
				// 		transform: "translateX(-50%)",
				// 		textAlign: "center",
				// 		backgroundColor: "rgba(0,0,0,0.8)",
				// 		backdropFilter: "blur(5px)",
				// 		borderRadius: "1rem",
				// 		padding: 0,
				// 		border: `1px solid ${theme.palette.primary.main}`,
				// 		"&::before, &::after": {
				// 			content: '""',
				// 			position: "absolute",
				// 			zIndex: -1,
				// 			width: "calc(100% + 6px)",
				// 			height: "calc(100% + 6px)",
				// 			top: "-3px",
				// 			left: "-3px",
				// 			background:
				// 				"linear-gradient(45deg, #6C63FF, #9D4EDD, #5A55D6, #4C1D95, #8B5CF6, #4A90E2, #00B4D8, #6C63FF, #8B5CF6, #4C1D95, #5A55D6, #9D4EDD, #6C63FF)",
				// 			backgroundSize: "300% 300%",
				// 			borderRadius: "0.5rem",
				// 			animation: `${rainbowBorderAnimation} 15s linear infinite`,
				// 		},
				// 		"&::after": {
				// 			filter: "blur(5px)",
				// 			transform: "translate3d(0, 0, 0)",
				// 		},
				// 	})}
				// >
				<Box
					sx={(theme) => ({
						backgroundColor: theme.palette.background.paper,
						margin: { xs: 0, md: '2px' },
						padding: { xs: theme.spacing(0), md: theme.spacing(4) },
						pt: { xs: theme.spacing(2), md: theme.spacing(0) },
						pb: { xs: theme.spacing(2), md: theme.spacing(0) },
						borderRadius: '0.8rem',
					})}
				>
					<Stack
						spacing={4}
						sx={(theme) => ({
							color: theme.palette.text.primary,
							justifyContent: 'center',
							alignItems: 'center',
						})}
					>
						{/* <Typography variant="h1" sx={(theme) => ({
									color: theme.palette.text.disabled
								})}>SmartChat</Typography> */}
						<Stack direction="column" spacing={0}>
							<AppLogo
								height="24px"
								width="auto"
								darkMode={this.rootStore.uiState.isDarkMode}
								disableBanner={false}
							/>
							<Typography variant="caption" sx={{ textAlign: 'end', fontStyle: 'italic' }}>
								Online Demo
							</Typography>
						</Stack>

						<DialogContentText>
							Vi lager <strong>AI-kunnskap</strong> om {this.domain} nå!
						</DialogContentText>
						{/* <Typography variant="body1">
										Mens du leser dette, henter en vi ut informasjon fra nettsiden, og genererer en forhåndsvisning av SmartChat
									</Typography> */}
						{/* <Box sx={(theme) => ({
									width: '100%',
									height: '100px',
									// backgroundColor: theme.palette.background.paper,
									borderRadius: '.5rem',
									margin: theme.spacing(2),
								})}>
									<Typography variant="overline" sx={(theme) => ({
										color: theme.palette.text.primary,
										textAlign: 'center',
										padding: theme.spacing(4),
									})}>
										{this.mostRecentScrapeResultLog?.summaryForUser ??
											this.mostRecentScrapeResultLog?.message ?? 'Henter data...'}
									</Typography>
								</Box> */}
						{this.renderAutoKnowledge({ concatenateLogs: true, excludeSystemMessages: true })}
						{/* <Typography variant="body1">
										Vi analyserer automatisk opptil 20 sider og genererer <strong>Kunnskap</strong>, som integreres i SmartChat
									</Typography>
									<Typography variant="body1">
										Når chatten er fullført, vil resultatet sendes til <strong>SmartChat Tickets</strong>
									</Typography> */}
						<DialogContentText>
							Når forhåndsvisningen er klar, vil SmartChat ha <strong>AI-kunnskap</strong> om {this.domain}.
						</DialogContentText>
						<DialogContentText>
							Prøv selv ved å klikke på Chatboblen nederst til høyre i demo-vinduet.
						</DialogContentText>

						<Button
							onClick={this.onInfoCloseClick}
							variant="outlined"
							size="large"
							sx={(theme) => ({
								color: darken(theme.palette.text.primary, 0.2),
								borderColor: darken(theme.palette.text.primary, 0.2),
								borderRadius: '.5rem',
								'&:hover': {
									color: theme.palette.text.primary,
									borderColor: theme.palette.text.primary,
								},
							})}
						>
							Prøv nå!
						</Button>
					</Stack>
				</Box>
				// </Box>
			);
		}

		renderDynamicContent() {
			return (
				<>
					<Box
						sx={{
							transition: 'opacity 1.5s',
							position: 'relative',
							opacity: this.domain ? '1' : '0',
						}}
					>
						<Box
							sx={{
								display: this.domain ? 'initial' : 'none',
								transition: 'filter 0.5s',
								filter: this.hasReadInfo ? 'initial' : 'blur(5px)',
							}}
						>
							<Grid2Section container xs={12} alignItems="center" justifyContent="center">
								{this.renderContent()}
							</Grid2Section>
						</Box>
					</Box>
					{this.renderUrlInput()}

					<Dialog
						open={Boolean(this.domain) && !this.hasReadInfo}
						TransitionComponent={TransitionUp}
						onClose={this.onInfoCloseClick}
					>
						{/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
						<DialogContent
							sx={(theme) => ({
								backgroundColor: theme.palette.background.paper,
							})}
						>
							{this.renderInfoDialogContent()}
						</DialogContent>
						{/* <DialogActions>
							<Button onClick={this.onInfoCloseClick}>Prøv nå</Button>
						</DialogActions> */}
					</Dialog>
				</>
			);
		}

		render() {
			const { asComponent } = this.props;

			if (asComponent) {
				return this.renderDynamicContent();
			}

			return (
				<Spotlight>
					<Grid2Section
						container
						xs={12}
						direction="row"
						sx={(theme) => ({
							minHeight: '100vh',
							justifyContent: 'center',
							alignItems: 'center',
							paddingTop: theme.spacing(4),
							padding: { xs: 0, md: theme.spacing(4) },
							margin: 'auto',
						})}
					>
						{this.renderDynamicContent()}
					</Grid2Section>
				</Spotlight>
			);
		}
	}
);

export default withHooks(SmartDemo);

import { makeAutoObservable, runInAction } from 'mobx';
import EventApi from '../api/endpoints/EventApi';
import { EventDTO, EventParticipantDTO, MeetingType } from '../dto/event.types';
import LogUtil from '../helpers/LogUtil';
import { Trigger, TriggerType } from '../Trigger.types';
import { RootStore } from './RootStore';

export class EventStore {
	events: BefareEvent[] = [];
	isLoading: string[] = [];
	isLoadingAll: boolean = false;
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, {
			rootStore: false,
		});

		this.rootStore = rootStore;

		this.loadEvents = this.loadEvents.bind(this);
		this.processTrigger = this.processTrigger.bind(this);

		this.init();
	}

	init() {
		this.rootStore.userStore.UserIdChanged.on(() => {
			this.reset();
			this.loadEvents();
		});

		this.rootStore.userStore.SignedOut.on(() => {
			this.reset();
		});
	}

	reset() {
		this.events = [];
	}

	async processTrigger(trigger: Trigger<EventDTO>) {
		try {
			switch (trigger.urn) {
				case TriggerType.EVENT_UPDATED:
				case TriggerType.EVENT_CREATED:
				case TriggerType.EVENT_DELETED:
					if (trigger.event?.data?.id) {
						this.addOrUpdateEventFromJson(trigger.event.data);
					}
					break;
			}
		} catch (err) {
			LogUtil.error(err);
		}
	}

	async loadEvents() {
		if (this.isLoadingAll) {
			return;
		}
		this.isLoadingAll = true;
		const result = await EventApi.getEvents();
		if (result.statusCode === 200 && Array.isArray(result.data)) {
			result.data.forEach((jsonEvent: any) => this.addOrUpdateEventFromJson(jsonEvent));
		} else {
			// Failed loadding events - why??
		}

		runInAction(() => (this.isLoadingAll = false));
	}

	async getEvent(eventId: string) {
		eventId = '' + eventId;
		let event = this.events.find((e) => e.id === eventId);
		if (event) {
			return event;
		} else {
			// cheating - we just assume that we get it
			//  WILL cause  issues if the user does not  have access to this event  - > it will never be populated with  data
			event = this.addOrUpdateEventFromJson({ id: eventId });
		}
		if (this.isLoading.indexOf(eventId) > -1) {
			return event;
		}
		this.isLoading.push(eventId);
		const result = await EventApi.getEvent(eventId);
		if (result.statusCode === 200 && result.data) {
			this.addOrUpdateEventFromJson(result.data);
		} else {
			// Failed loading events - why??
		}

		runInAction(() => (this.isLoading = this.isLoading.filter((id) => id !== eventId)));

		return this.events.find((e) => e.id === eventId);
	}

	async createEvent(eventData: Partial<EventDTO>, channelId?: number) {
		const result = await EventApi.createEvent(eventData, channelId);
		if (result.statusCode === 200) {
			this.addOrUpdateEventFromJson(result.data);
		}
	}

	async respondToEvent(eventId: string, status: string) {
		const result = await EventApi.respondToEvent(eventId, status);
		if (result.statusCode === 200) {
			this.addOrUpdateEventFromJson(result.data);
		}
	}

	async cancelEvent(eventId: string) {
		const result = await EventApi.cancelEvent(eventId);
		if (result.statusCode === 200) {
			this.addOrUpdateEventFromJson(result.data);
		}
	}

	addOrUpdateEventFromJson(json: any) {
		let befEvent = this.events.find((evt: BefareEvent) => evt.id === '' + json.id);
		if (!befEvent) {
			befEvent = new BefareEvent(json.id);
			runInAction(() => {
				this.events.push(befEvent!);
			});
		}
		runInAction(() => {
			befEvent!.updateFromJson(json);
		});

		return befEvent;
	}
}

export class BefareEvent {
	id: string = '';
	channelId: string | undefined;
	created: Date | undefined;
	updated: Date | undefined;
	deleted: Date | undefined;

	duration: number = 0;
	description: string = '';
	inviterId: string = '';

	start: Date | undefined;
	type?: MeetingType;
	workspaceId: string = '';

	invitees: EventParticipantDTO[] = [];
	videoChatSessionId?: string;

	constructor(id?: number) {
		makeAutoObservable(this, {});

		this.id = '' + id;
	}

	updateFromJson(json: EventDTO) {
		this.id = '' + json.id;
		this.channelId = '' + json.channelId;
		this.created = json.created ? new Date(json.created) : undefined;
		this.updated = json.updated ? new Date(json.updated) : undefined;
		this.deleted = json.deleted ? new Date(json.deleted) : undefined;
		this.duration = json.duration;
		this.description = json.description ?? '';
		this.inviterId = '' + json.inviterId;
		this.videoChatSessionId = json.videoChatSessionId;

		this.start = json.start ? new Date(json.start) : undefined;
		this.type = json.type;
		this.workspaceId = '' + json.workspaceId;

		if (Array.isArray(json.invitees)) {
			json.invitees.forEach((invitee: any) => this.addOrUpdateInvitee(invitee));
		}
	}

	addOrUpdateInvitee(invitee: any) {
		let befInvitee = this.invitees.find((i) => i.userId === '' + invitee.userId);
		if (!befInvitee) {
			this.invitees.push(BefareEvent.inviteeFromJson(invitee));
		} else {
			befInvitee = BefareEvent.inviteeFromJson(invitee);
		}
	}

	static inviteeFromJson(json: any): EventParticipantDTO {
		return {
			id: '' + json.id,
			comment: json.comment,
			created: json.created ? new Date(json.created) : undefined,
			updated: json.updated ? new Date(json.updated) : undefined,
			deleted: json.deleted ? new Date(json.deleted) : undefined,
			required: json.required,
			status: json.status,
			userId: '' + json.userId,
		} as EventParticipantDTO;
	}
}

import { Box, Divider, List, ListItem, ListItemText, SwipeableDrawer, styled } from '@mui/material';
import { HookTypes, withLocation } from '../../utils/withHooks';
import { computed, makeObservable } from 'mobx';

import ListItemLink from '../common/ListItemLink';
import React from 'react';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import { matchPath } from 'react-router';
import { observer } from 'mobx-react';

import AssignmentIcon from '@mui/icons-material/Assignment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChatIcon from '@mui/icons-material/Chat';
import config from '../../config/config';
import { AppId } from '../../config/appId.enum';
import { AppLogo } from '../logo/AppLogo';

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'center',
}));

type Props = HookTypes;

const NavigationDrawer = observer(
	class NavigationDrawer extends React.Component<Props> {
		static readonly contextType = StoreContext;
		get location() {
			return this.props.location!;
		}

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				rootStore: computed,
				userStore: computed,
				uiState: computed,
				workspaceStore: computed,
				companyStore: computed,
				location: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get workspaceStore() {
			return this.rootStore.workspaceStore;
		}

		get companyStore() {
			return this.rootStore.companyStore;
		}

		renderPrimaryNavigationDestinationGroup() {
			let companiesLinkLabel = 'Finn håndverker';
			switch (config.appId) {
				case AppId.Smart:
					companiesLinkLabel = 'Get in touch';
					break;
				case AppId.MinElektriker:
					companiesLinkLabel = 'Finn elektriker';
					break;
				case AppId.MinRorlegger:
					companiesLinkLabel = 'Finn rørlegger';
					break;
			}

			const listItems = [
				{
					to: '/home',
					text: 'Forside',
				},
				{
					to: '/how-it-works',
					text: 'Slik fungerer det',
				},
				{
					to: '/order-chat',
					text: 'Send forespørsel',
				},
				{
					to: '/companies',
					text: companiesLinkLabel,
				},
			];

			const { pathname } = this.location;

			return (
				<List component="nav">
					{listItems.map((listItem, index) => (
						<ListItemLink key={index} selected={Boolean(matchPath(pathname, listItem.to))} to={listItem.to}>
							<ListItemText primary={listItem.text} />
						</ListItemLink>
					))}
				</List>
			);
		}

		renderLogo() {
			return (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginBottom: 2,
						width: '300px',
						maxWidth: '100%',
						padding: '1rem',
					}}
				>
					<AppLogo darkMode={this.uiState.isDarkMode} />
				</Box>
			);
		}

		render() {
			const { isNavigationDrawerOpen, toggleNavigationDrawer } = this.uiState;
			const { pathname } = this.location;
			const isBrowser = typeof window !== 'undefined';
			const iOS = isBrowser && /iPad|iPhone|iPod/.test(navigator.userAgent);

			return (
				<SwipeableDrawer
					disableBackdropTransition={!iOS}
					disableDiscovery={iOS}
					open={isNavigationDrawerOpen}
					onClose={toggleNavigationDrawer}
					onOpen={toggleNavigationDrawer}
				>
					<div onClick={toggleNavigationDrawer} onKeyDown={toggleNavigationDrawer} role="presentation">
						<DrawerHeader>{this.renderLogo()}</DrawerHeader>
						{this.userStore.isLoggedIn && (
							<>
								<List component="nav">
									<ListItemLink to="/dashboard">
										<DashboardIcon fontSize="small" sx={{ mr: 1 }} />
										<ListItemText primary="Dashbord" />
									</ListItemLink>
									{this.rootStore.profileStore.isCraftsman && (
										<>
											<ListItemLink to="/app/pro/projects">
												<AssignmentIcon fontSize="small" sx={{ mr: 1 }} />
												<ListItemText primary="Alle jobber" />
											</ListItemLink>
											<ListItemLink to="/app/pro/channels">
												<ChatIcon fontSize="small" sx={{ mr: 1 }} />
												<ListItemText primary="Chat" />
											</ListItemLink>
										</>
									)}
								</List>
								<Divider />
							</>
						)}
						{this.renderPrimaryNavigationDestinationGroup()}
						<Divider />
						<List component="nav">
							{this.userStore.isLoggedIn ? (
								<>
									{/* <ListItemLink to="/dashboard">
										<ListItemText primary="Min side" />
									</ListItemLink> */}
									<ListItem onClick={this.userStore.logOut}>
										<ListItemText primary="Logg ut" />
									</ListItem>
								</>
							) : (
								<ListItemLink selected={Boolean(matchPath(pathname, '/sign-in'))} to="/sign-in">
									<ListItemText primary="Logg inn" />
								</ListItemLink>
							)}
						</List>
					</div>
				</SwipeableDrawer>
			);
		}
	}
);

export default withLocation(NavigationDrawer);

import { IReactionDisposer, makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import AiApi from '../api/endpoints/AiApi';
import ProjectApi from '../api/endpoints/ProjectApi';
import RatingApi, { ProjectRatingDTO } from '../api/endpoints/RatingApi';
import { ChannelDTO, ChannelTypeDTO } from '../dto/channel.types';
import { CompanyDTO } from '../dto/company.types';
import { EventDTO } from '../dto/event.types';
import { ProjectMemberDTO, ProjectStatusDTO, ProjectDTO } from '../dto/project.types';
import { Address } from '../types';
import { ProjectStore, ProjectStatusCategory } from './ProjectStore';
import ProjectTemplate, { ProjectUrgency } from './TemplateStore';
import { ProjectTemplateDTO } from '../api/endpoints/TemplateApi';
import { toNumber } from 'lodash';
import { DateUtil } from '../helpers/DateUtil';
import { FileEntryDTO } from '../dto/file.types';
import Checklist from './checklist/Checklist';
import { ProfileDTO } from '../dto/profile.types';
import { GranteeAccess, GranteeType } from '../dto/sharing.types';

/**
 * PROJECT
 * Domain object
 */
export class Project {
    id?: number;
    ownerId: number = 0;
    workspaceId: number = 0;
    created: Date = new Date();
    updated: Date = new Date();
    deleted: Date | null = null;
    // We can change this type to eg. Address, however right now it's set to what we get from server
    // Similar for all types ending in DTO. If we just set it to what we get from server we should use the DTO type
    address: Address | undefined;
    company: CompanyDTO | undefined;
    meetings: EventDTO[] = [];
    channels: ChannelDTO[] = [];
    projectMembers: ProjectMemberDTO[] = [];
    isCompleted: boolean = false;
    name: string | null = null;
    description: string | null = null;
    status: ProjectStatusDTO = ProjectStatusDTO.Requested;
    files: FileEntryDTO[] = [];
    numFilesTotal: number = 0;
    totalFileSize: number = 0;
    progress: number = 0;
    tags: string[] = [];
    category: string | null = null;
    urgency: ProjectUrgency = ProjectUrgency.Normal;
    startDate: Date | null = null; // ISO DateTime
    deadline: Date | null = null; // ISO DateTime
    priorities: string | null = null;
    hasUnhandledCustomerMessages: boolean = false;
    specialRequirements: string | null = null;

    // from new DTO april 2024
    customerId: number | null = null;
    estimatedBudget: number | null = null;
    estimatedHours: number | null = null;
    budgetCurrency: string | null = null;
    templateId: number | null = null;
    industryId: number | null = null;
    termsId: number | null = null;
    creatingWorkspaceId: number | null = null;
    contactPersonId: number | null = null;
    requestReview: boolean = true;
    requireComplianceConfirmation: boolean = false;
    addressId: number | null = null;
    source: string | null = null;
    constructionAddress: string | null = null;
    postalCode: string | null = null;

    billableWorkspaceId: number | null = null;
    billingDatetime: Date | null = null;
    billedDatetime: Date | null = null;

    summary: string | undefined;

    projectRating: ProjectRatingDTO | undefined;

    checklists: Checklist[] = [];

    store: ProjectStore;
    autoSave: boolean = true;
    saveHandler: IReactionDisposer;

    pinned: boolean = false;

    isLoadingRating: boolean = false;

    // only used  during creation, is not persisted server side

    notifyCustomerOnCreate: boolean = true;
    notifyContactPersonOnCreate: boolean = true;

    // archived
    archivedAt: Date | null = null;
    archivedBy: number | null = null;

    // shared access
    sharedAccess: GranteeAccess[] = [];

    constructor(store: ProjectStore, id?: number) {
        makeAutoObservable(this, {
            id: false,
            store: false,
            autoSave: false,
            saveHandler: false,
        });

        this.id = id ? +id : undefined;
        this.store = store;

        this.saveHandler = reaction(
            () => this.asJson,
            () => {
                if (this.autoSave) {
                    // I think this might trigger a positive feedbackLoop and make things really interesting...
                    // A save triggers a new save sometimes/somehow and it quickly snowballs into 1000s of updates/requests
                    // console.log(`SAVE HANDLER TRIGGERED FOR PROJECT ${this.id}`);
                    // this.save();
                    // TODO Create new or update existing project
                }
            }
        );
    }

    get asJson(): ProjectDTO {
        return this.toDTO;
    }

    get lastActivity() {
        const timestamp = this.channels
            ?.map((c: any) => {
                const channel = this.store.rootStore.chatStore.findChannel(c.id);
                const msgTimestamp = channel?.lastMessage ? channel.lastMessage.created?.getTime() : 0; // Message created or project updated
                const projectTimestamp = this.updated?.getTime() ?? Date.now();
                return msgTimestamp > projectTimestamp ? msgTimestamp : projectTimestamp;
            })
            .reduce((max: number, current: number) => (current > max ? current : max), 0);
        if (!timestamp) {
            return new Date(this.updated ?? this.created);
        }

        const lastActivity = this.updated.getTime() > timestamp ? this.updated : new Date(timestamp);
        // consider archivedAt
        if (this.archivedAt && this.archivedAt.getTime() > lastActivity.getTime()) {
            return this.archivedAt;
        }

        return lastActivity;
    }

    get numUnread() {
        return this.channels
            ?.map((c: any) => {
                const channel = this.store.rootStore.chatStore.findChannel(c.id);
                return channel?.unreadNum ?? 0;
            })
            .reduce((total: number, current: number) => total + current, 0);
    }

    get unreadInCustomerChannel() {
        const customerChannel = this.channels.find((c) => c.channelType === ChannelTypeDTO.ProjectCustomer);
        if (!customerChannel) {
            return 0;
        }
        const channel = this.store.rootStore.chatStore.findChannel(customerChannel.id);
        return channel?.unreadNum ?? 0;
    }

    get statusCategory() {
        switch (this.status) {
            case ProjectStatusDTO.Aborted:
            case ProjectStatusDTO.Completed:
            case ProjectStatusDTO.ProjectLost:
                return ProjectStatusCategory.Done;
            case ProjectStatusDTO.Assigned:
            case ProjectStatusDTO.Planned:
            case ProjectStatusDTO.Received:
                return ProjectStatusCategory.ToDo;
            case ProjectStatusDTO.Requested:
            case ProjectStatusDTO.Unknown:
                return ProjectStatusCategory.Unprocessed;
            case ProjectStatusDTO.Active:
            default:
                return ProjectStatusCategory.InProgress;
        }
    }

    get formattedCreatedDate() {
        return this.created.toLocaleDateString();
    }

    get isArchived() {
        return !!this.archivedAt;
    }

    get formattedAddress() {
        let addressString = ``;
        if (this.address?.street) {
            addressString += this.address.street;
        }

        if (this.address?.houseNumber) {
            // addressString += ` ${this.address.houseNumber}`;
        }

        if (this.address?.entrance) {
            // addressString += this.address.entrance;
        }

        if (this.address?.postCode) {
            addressString += `, ${this.address.postCode}`;
        }

        if (this.address?.postArea) {
            addressString += ` ${this.address.postArea}`;
        }

        return addressString ? addressString : (this.constructionAddress ?? 'Ukjent adresse');
    }

    get customerChannel() {
        return this.channels.find((channel) => channel.channelType === ChannelTypeDTO.ProjectCustomer);
    }

    get hours() {
        return this.estimatedHours ?? 0;
    }

    get budget() {
        return this.estimatedBudget ?? 0;
    }

    get hoursLeft() {
        return this.hours - (this.hours * (this.progress ?? 0)) / 100;
    }

    get isFinished() {
        return this.progress === 100;
    }

    getSummary = async () => {
        if (this.customerChannel?.id) {
            const result = await AiApi.getSummary(this.customerChannel?.id);
            if (result.statusCode === 200 && result.data.choices && result.data.choices[0]?.text) {
                runInAction(() => {
                    this.summary = result.data.choices[0].text;
                });
            }
        }

        return this.summary;
    };

    async getChecklists() {
        if (!this.id) {
            return;
        }
        const result = await ProjectApi.getChecklists(this.id);
        if (result.statusCode === 200) {
            runInAction(() => {
                this.checklists = [];
                result.data.forEach((checklist) => {
                    this.checklists.push(new Checklist(checklist));
                });
            });
        }
    }

    async save() {
        if (!this.id) {
            const result = await ProjectApi.craftsmanCreateProject(
                this.asJson,
                this.notifyCustomerOnCreate,
                this.notifyContactPersonOnCreate
            );
            if (result.statusCode === 200) {
                runInAction(() => {
                    this.id = result.data.id;
                    this.updateFromJson(result.data);
                });
                return true;
            }
        }

        if (this.status === ProjectStatusDTO.Completed) {
            this.progress = 100;
        }

        const result = await ProjectApi.updateProject(this.asJson);
        if (result.statusCode === 200) {
            return true;
        }

        throw new Error('Failed to save project');
    }

    async reject(reason: string) {
        if (!this.id) {
            return false;
        }
        const result = await ProjectApi.rejectProject(this.id, reason);
        if (result.statusCode === 200) {
            // todo: remove project from list
            return true;
        }

        return false;
    }

    async saveMembers() {
        if (!this.id) {
            return false;
        }
        const result = await ProjectApi.updateProjectMembers(this.id, this.projectMembers);
        if (result.statusCode === 200) {
            return true;
        }

        return false;
    }

    async addProjectMemberByUserId(role: string, userId: string) {
        if (!this.id) {
            return false;
        }
        const result = await ProjectApi.addProjectMemberByUserId(this.id, userId, role);
        if (result.statusCode === 200) {
            return result.data;
        }

        return false;
    }

    async addProjectMemberByPhoneNumber(role: string, phoneNumber: string, countryCode: string = '47') {
        if (!this.id) {
            return false;
        }
        const result = await ProjectApi.addProjectMemberByPhoneNumber(this.id, phoneNumber, countryCode, role);
        if (result.statusCode === 200) {
            return result.data;
        }

        return false;
    }

    async getRating(forceReload: boolean = false) {
        if (!this.id) {
            return undefined;
        }

        if (this.projectRating && !forceReload) {
            return this.projectRating;
        }

        if (this.isLoadingRating) {
            return undefined;
        }
        this.isLoadingRating = true;

        const result = await RatingApi.getProjectRating(this.id);
        if (result.statusCode === 200) {
            runInAction(() => {
                this.projectRating = result.data;
                this.isLoadingRating = false;
            });
            return result.data;
        }

        return undefined;
    }

    /**
     * @param  {any} json
     */
    updateFilesFromJson(json: any) {
        json.files.forEach((file: any) => {
            this.addFile(file);
        });

        this.numFilesTotal = json.numFiles;
        this.totalFileSize = json.totalSize;
        this.sortFiles();
    }

    /**
     * @param fileId
     * @returns {any | undefined} file
     */
    findFile(fileId: string): any {
        return this.files.find((file: any) => file.id == fileId); // double ==, not triple on purpose
    }

    /**
     * @param fileId
     * @returns {number} index
     */
    findFileIndex(fileId: string | number): number {
        return this.files.findIndex((file: any) => file.id == fileId); // double ==, not triple on purpose
    }

    /**
     * Adds or updates file
     * @param file
     */
    addFile(file: FileEntryDTO) {
        const index = this.findFileIndex(file.id);
        if (index >= 0) {
            this.files[index] = Object.assign(this.files[index], this.createFile(file));
        } else {
            this.files.push(this.createFile(file));
        }
    }

    createFile(fileJson: FileEntryDTO) {
        return fileJson;
    }

    updateProjectFileMetadata = async (file: FileEntryDTO) => {
        if (!this.id) {
            throw new Error('Project ID is missing');
        }
        const result = await ProjectApi.updateProjectFileMetadata(this.id, file.id, file);
        if (result.statusCode === 200 && result.data) {
            runInAction(() => {
                this.addFile(result.data);
            });

            return result.data;
        }

        throw new Error('Failed to update project file metadata');
    };

    updateAddress = async (address: Address) => {
        if (!this.id) {
            return null;
        }
        address.id = 'NEW';
        const result = await ProjectApi.updateAddress(this.id, address);
        if (result.statusCode === 200 && result.data) {
            runInAction(() => {
                this.updateFromJson(result.data as ProjectDTO);
            });

            return result.data;
        }
        return null;
    };

    /**
     * Sort files
     */
    sortFiles() {
        this.files.sort((a, b) => {
            const aTimestamp: Date = new Date(a.created);
            const bTimestamp: Date = new Date(b.created);

            if (aTimestamp > bTimestamp) {
                return 1;
            }
            if (aTimestamp < bTimestamp) {
                return -1;
            }

            return 0;
        });
    }

    addOrUpdateProjectMembers(members: any) {
        if (!Array.isArray(members)) {
            return;
        }
        try {
            members.forEach((member: any) => {
                if (!this.projectMembers.find((m) => m.userId == member.userId && m.role == member.role)) {
                    this.projectMembers.push(member);
                }
            });
        } catch (e) {
            console.error('ProjectStore: error while trying to add members', e);
        }
    }

    async setPinned(pinned: boolean) {
        if (!this.id) {
            return false;
        }
        // this.pinned = pinned;
        const result = await ProjectApi.pinProject(this.id, pinned);
        if (result.statusCode === 200) {
            this.updateFromJson(result.data);
            return true;
        }

        return false;
    }

    async removeFile(fileId: number) {
        if (!this.id) {
            return false;
        }
        const result = await ProjectApi.removeFile(this.id, fileId);
        if (result.statusCode === 200) {
            runInAction(() => {
                const index = this.findFileIndex(fileId);
                if (index >= 0) {
                    this.files.splice(index, 1);
                }
            });
            return true;
        }

        throw new Error('Failed to remove file');
    }

    async renameFile(fileId: number, name: string) {
        if (!this.id) {
            return false;
        }
        const result = await ProjectApi.renameFile(this.id, fileId, name);
        if (result.statusCode === 200) {
            runInAction(() => {
                const index = this.findFileIndex(fileId);
                if (index >= 0) {
                    this.files[index].name = name;
                }
            });
            return true;
        }

        throw new Error('Failed to rename file');
    }

    hasMember(userId?: string) {
        if (!userId) {
            return false;
        }
        return Boolean(this.projectMembers.find((member) => '' + member.userId === '' + userId));
    }

    clearHasUnhandledCustomerMessages() {
        this.hasUnhandledCustomerMessages = false;
        this.save().catch((err) => {
            console.error(err);
        });
    }

    get toDTO(): ProjectDTO {
        return {
            id: this.id,
            ownerId: this.ownerId,
            workspaceId: this.workspaceId,
            created: this.created?.toISOString(),
            updated: this.updated?.toISOString(),
            deleted: this.deleted?.toISOString() ?? null,
            progress: this.progress,
            hasUnhandledCustomerMessages: this.hasUnhandledCustomerMessages,
            name: this.name,
            description: this.description,
            status: this.status,
            category: this.category ?? null,
            urgency: this.urgency,
            startDate: this.startDate?.toISOString() ?? null,
            deadline: this.deadline?.toISOString() ?? null,
            priorities: this.priorities ?? null,
            specialRequirements: this.specialRequirements ?? null,
            customerId: this.customerId,
            estimatedBudget: this.estimatedBudget,
            estimatedHours: this.estimatedHours,
            budgetCurrency: this.budgetCurrency,
            templateId: this.templateId,
            industryId: this.industryId,
            termsId: this.termsId,
            creatingWorkspaceId: this.creatingWorkspaceId,
            contactPersonId: this.contactPersonId,
            requestReview: this.requestReview,
            requireComplianceConfirmation: this.requireComplianceConfirmation,
            tags: this.tags,
            projectMembers: this.projectMembers,
            // company: this.company,
            address: this.address,
            channels: this.channels,
            addressId: this.addressId,
            source: this.source,
            constructionAddress: this.constructionAddress,
            postalCode: this.postalCode,
            pinned: this.pinned,
            archivedAt: this.archivedAt?.toISOString() ?? null,
            archivedBy: this.archivedBy,
            billableWorkspaceId: this.billableWorkspaceId,
            billingDatetime: this.billingDatetime?.toISOString() ?? null,
            billedDatetime: this.billedDatetime?.toISOString() ?? null,
        };
    }

    addOrUpdateChannels(channels?: ChannelDTO[]) {
        if (!Array.isArray(channels)) {
            return;
        }

        const currentWorkspaceId = this.store.rootStore.workspaceStore.workspace?.id;

        channels
            .filter((c) => !!c?.id)
            .forEach((channel) => {
                const cleanedChannel = {
                    ...channel,
                    workspaceId: channel.workspaceId ?? currentWorkspaceId,
                    id: Number.parseInt(`${channel.id}`),
                };
                const skipChannel =
                    cleanedChannel.channelType === ChannelTypeDTO.ProjectInternal &&
                    cleanedChannel.workspaceId !== currentWorkspaceId;
                if (!skipChannel && !this.channels.find((c) => c.id == cleanedChannel.id)) {
                    this.channels.push(cleanedChannel);
                }
            });
        // a bit messy? YES
        // why the heck do we need runInAction here?
        runInAction(() => {
            this.store.rootStore.chatStore.addChannelsIfNotExists(this.channels);
        });
    }

    async inviteCustomer(message: string) {
        if (!this.id) {
            return false;
        }
        const result = await ProjectApi.inviteCustomer(this.id, message);
        if (result.statusCode === 200) {
            return true;
        }

        return false;
    }

    async inviteContactPerson(message: string) {
        if (!this.id) {
            return false;
        }
        const result = await ProjectApi.inviteContactPerson(this.id, message);
        if (result.statusCode === 200) {
            return true;
        }

        return false;
    }

    /**
     * @param  {any} json
     */
    updateFromJson(json: ProjectDTO) {
        this.autoSave = false;
        this.ownerId = json.ownerId!;
        this.workspaceId = json.workspaceId!;
        this.created = DateUtil.createDateWithTimezoneOffset(json.created);
        this.updated = DateUtil.createDateWithTimezoneOffset(json.updated);
        this.deleted = json.deleted ? DateUtil.createDateWithTimezoneOffset(json.deleted) : null;
        this.address = json.address;
        this.progress = json.progress ?? this.progress;
        // this.meetings = json.events ?? []; // @todo should we call this meetings?
        this.hasUnhandledCustomerMessages = Boolean(json.hasUnhandledCustomerMessages);

        // @todo - currently this is ChannelDTO type, should probably create a reference to Chat?
        // this.channels = Array.isArray(json.channels) ? json.channels.filter((c: any) => !!c?.id) : [];

        this.addOrUpdateProjectMembers(json.projectMembers);
        this.addOrUpdateChannels(json.channels);
        // this.company = json.company;

        this.name = json.name;
        // patch
        if (('' + this.name).length < 2) {
            this.name = null;
        }
        this.description = json.description;
        this.status = json.status;
        this.tags = json.tags ?? [];
        this.category = json.category;
        this.urgency = json.urgency as ProjectUrgency;
        this.startDate = json.startDate ? DateUtil.createDateWithTimezoneOffset(json.startDate) : null;
        this.deadline = json.deadline ? DateUtil.createDateWithTimezoneOffset(json.deadline) : null;
        this.priorities = json.priorities;
        this.specialRequirements = json.specialRequirements;

        this.pinned = Boolean(json.pinned);

        this.customerId = (json as any).customerId;
        this.estimatedBudget = +((json as any).estimatedBudget ?? 0);
        this.estimatedHours = +((json as any).estimatedHours ?? 4); // default to 4 hours
        this.budgetCurrency = (json as any).budgetCurrency;
        this.templateId = (json as any).templateId;
        this.industryId = (json as any).industryId;
        this.termsId = (json as any).termsId;
        this.creatingWorkspaceId = (json as any).creatingWorkspaceId;
        this.contactPersonId = (json as any).contactPersonId;
        this.requestReview = (json as any).requestReview;
        this.requireComplianceConfirmation = (json as any).requireComplianceConfirmation;
        this.source = json.source;
        this.addressId = json.addressId;

        this.billableWorkspaceId = json.billableWorkspaceId;
        this.billingDatetime = json.billingDatetime
            ? DateUtil.createDateWithTimezoneOffset(json.billingDatetime)
            : null;
        this.billedDatetime = json.billedDatetime ? DateUtil.createDateWithTimezoneOffset(json.billedDatetime) : null;

        if (!this.customerId && this.ownerId) {
            // todo: remove this when customerId is always set
            this.customerId = this.ownerId;
        }

        this.archivedAt = json.archivedAt ? DateUtil.createDateWithTimezoneOffset(json.archivedAt) : null;
        this.archivedBy = json.archivedBy;

        this.autoSave = true;
    }

    updateFromTemplate(template: ProjectTemplate) {
        const templateRaw = toJS(template);

        // do not allow undefined or null values for fields that likely will be printed; it breaks MobX's reactive behavior
        this.templateId = template.templateId ?? null;
        this.urgency = templateRaw.urgency;
        this.priorities = templateRaw.priorities ?? '';
        this.name = templateRaw.name;
        this.description = templateRaw.description ?? '';
        this.category = templateRaw.category ?? '';
        this.estimatedBudget = templateRaw.estimatedBudget ?? 0;
        this.budgetCurrency = templateRaw.budgetCurrency ?? 'NOK';
        this.industryId = templateRaw.industryId ?? null;
        this.priorities = templateRaw.priorities ?? '';
        this.specialRequirements = templateRaw.specialRequirements ?? '';
        this.tags = templateRaw.tags ?? [];
        // this.startDate = template.startDate ?? this.startDate;
        // this.deadline = template.deadline ?? this.deadline;
    }

    get asTemplate(): ProjectTemplate {
        return new ProjectTemplate({
            templateId: this.templateId ?? undefined,
            workspaceId: toNumber(this.workspaceId),
            name: this.name ?? '',
            description: this.description ?? undefined,
            category: this.category ?? undefined,
            estimatedBudget: this.estimatedBudget ?? undefined,
            budgetCurrency: this.budgetCurrency ?? undefined,
            urgency: this.urgency ?? ProjectUrgency.Normal,
            priorities: this.priorities,
            specialRequirements: this.specialRequirements ?? undefined,
            tags: this.tags,
            created: this.created.toISOString(),
            updated: this.updated.toISOString(),
            deleted: this.deleted?.toISOString(),
        } as ProjectTemplateDTO);
    }

    dispose() {
        this.saveHandler();
    }

    async getSharedAccess(): Promise<GranteeAccess[]> {
        if (!this.id) {
            return [];
        }
        const result = await ProjectApi.getSharedAccess(this.id);
        if (result.statusCode === 200) {
            runInAction(() => {
                this.sharedAccess = result.data.granteeAccessList;
            });
            return result.data.granteeAccessList;
        }

        return [];
    }

    async searchSharedAccess(search: string): Promise<ProfileDTO[]> {
        if (!this.id) {
            return [];
        }
        const result = await ProjectApi.searchSharedAccess(this.id, search);
        if (result.statusCode === 200) {
            return result.data;
        }

        return [];
    }

    async addSharedAccess(access: GranteeAccess) {
        if (!this.id) {
            return false;
        }
        const result = await ProjectApi.addSharedAccess(this.id, access);
        if (result.statusCode === 200) {
            runInAction(() => {
                // replace or add
                const index = this.findGrantAccessIndex(access);
                if (index >= 0) {
                    this.sharedAccess[index] = access;
                } else {
                    this.sharedAccess.push(access);
                }
            });
            return true;
        }

        return false;
    }

    async removeSharedAccess(access: GranteeAccess) {
        if (!this.id) {
            return false;
        }

        const result = await ProjectApi.removeSharedAccess(this.id, access);
        if (result.statusCode === 200) {
            runInAction(() => {
                const index = this.findGrantAccessIndex(access);
                if (index >= 0) {
                    this.sharedAccess.splice(index, 1);
                }
            });
            return true;
        }

        return false;
    }

    findGrantAccessIndex(access: GranteeAccess): number {
        // depends on grantee type, look at profileId or workspaceId
        if (access.granteeType === GranteeType.Profile) {
            return this.sharedAccess.findIndex((a) => a.profileId === access.profileId);
        } else if (access.granteeType === GranteeType.Workspace) {
            return this.sharedAccess.findIndex((a) => a.workspaceId === access.workspaceId);
        } else {
            return -1;
        }
    }
}

import {
	AppBar,
	Badge,
	Box,
	Button,
	Hidden,
	IconButton,
	Stack,
	Toolbar,
	Tooltip,
	Typography,
	useScrollTrigger,
} from '@mui/material';
import { HookTypes, withHooks } from '../../utils/withHooks';
import { Link, matchPath } from 'react-router-dom';
import { action, computed, makeObservable, observable } from 'mobx';

import AccountMenu from './AccountMenu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LoginButton from '../common/LoginButton';
import MenuIcon from '@mui/icons-material/Menu';

import NavigationDrawer from '../navigation-drawer/NavigationDrawer';
import NotificationsMenu from './NotificationsMenu';
import ProfilePicture from '../common/ProfilePicture';
import React from 'react';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import { observer } from 'mobx-react';
import { throttle } from 'lodash';
import config from '../../config/config';
import { AppId } from '../../config/appId.enum';
import CraftsmanGuard from '../../utils/CraftsmanGuard';
import { motion } from 'framer-motion';
import { AppLogo } from '../logo/AppLogo';
import FeatureGuard from '../../utils/FeatureGuard';

// const darkBackgroundPaths = [
// 	'/',
// 	'/how-it-works',
// 	'/order-chat',
// 	'/order-chat/confirmation',
// 	'/sign-in/verification-code',
// 	'/sign-in',
// 	'/sign-in-sso',
// ];

export const behindLoginWallPaths = [
	'/sign-in/workspace',
	'/permissions',
	'/dashboard',
	'/profile',
	'/profile/*',
	'/inbox',
	'/inbox/*',
	'/new-project',
	'/projects',
	'/projects/*',
	'/channel',
	'/settings',
	'/settings/*',
	'/privacy-policy',
	'/admin',
	'/admin/*',
	'/pro/*',
	'/app/*',
];

// eslint-disable-next-line no-unused-vars
enum MenuType {
	Notifications, // eslint-disable-line no-unused-vars
	Overflow, // eslint-disable-line no-unused-vars
}

type Props = HookTypes & {
	projectId?: string;
	channelId?: string;
};

function ElevationScroll(props: {
	isCurrentPathBehindLoginWall: boolean;
	children: React.ReactElement;
	color?: 'default' | 'transparent' | 'primary' | 'secondary';
	defaultTextColor?: 'textPrimary' | 'textSecondary' | 'white';
}) {
	const { isCurrentPathBehindLoginWall, children, defaultTextColor, color } = props;

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
	});

	let config: (Partial<any> & React.Attributes) | undefined;

	let textColor = defaultTextColor ?? 'textPrimary';
	if (trigger && ['primary', 'secondary'].includes(color ?? 'default')) {
		textColor = 'white';
	}

	if (isCurrentPathBehindLoginWall) {
		config = {
			elevation: 4,
			color: 'primary',
			sx: {
				color: textColor,
			},
		};
	} else {
		config = {
			elevation: trigger ? 4 : 0,
			color: trigger ? (color ?? 'default') : 'transparent',
			sx: {
				color: textColor,
			},
		};
	}

	return React.cloneElement(children, config);
}

const TopAppBar = observer(
	class TopAppBar extends React.Component<Props> {
		static readonly contextType = StoreContext;

		get location() {
			return this.props.location!;
		}

		get history() {
			return this.props.navigate!;
		}

		notificationsMenuAnchorElement: null | HTMLElement = null;
		overflowMenuAnchorElement: null | HTMLElement = null;

		isScrolled: boolean = false;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				notificationsMenuAnchorElement: observable,
				overflowMenuAnchorElement: observable,
				isScrolled: observable,
				uiState: computed,
				chatStore: computed,
				userStore: computed,
				rootStore: computed,
				profileStore: computed,
				workspaceStore: computed,
				notificationStore: computed,
				projectStore: computed,
				currentPathname: computed,
				channelId: computed,
				isCurrentPathBehindLoginWall: computed,
				isChat: computed,
				isChannelInfo: computed,
				isInboxChat: computed,
				isAdmin: computed,
				history: computed,
				location: computed,
				isHomeScreen: computed,
				onRouteChanged: action,
				scrollChecker: action,
				openMenu: action,
				closeMenu: action,
			});

			window.onscroll = throttle(this.scrollChecker, 250);

			this.handleOrder = this.handleOrder.bind(this);
		}

		componentDidUpdate(prevProps: any) {
			this.onRouteChanged(prevProps);
		}

		onRouteChanged(prevProps: any) {
			if (this.location !== prevProps.location) {
				this.notificationsMenuAnchorElement = null;
			}
		}

		scrollChecker = () => {
			this.isScrolled = document.scrollingElement?.scrollTop! > 0;
		};

		get rootStore() {
			return this.context as RootStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get chatStore() {
			return this.rootStore.chatStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get companyStore() {
			return this.rootStore.companyStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get workspaceStore() {
			return this.rootStore.workspaceStore;
		}

		get notificationStore() {
			return this.rootStore.notificationStore;
		}

		get projectStore() {
			return this.rootStore.projectStore;
		}

		get isHomeScreen() {
			const pathname = this.location.pathname;
			return pathname === '/' || pathname === '/home';
		}

		get company() {
			if (!this.workspaceStore?.workspace?.id) {
				return undefined;
			}

			return this.companyStore.findCompanyByWorkspaceId(this.workspaceStore.workspace.id);
		}

		get currentPathname() {
			return this.location.pathname;
		}

		get channelId(): number | undefined {
			// @todo do this in a clever way instead of this mess
			const paths = this.currentPathname.split('/');
			if (!paths) return undefined;

			const channelId = paths[paths.length - 1];

			if (!channelId) return undefined;

			return parseInt(channelId);
		}

		get isCurrentPathBehindLoginWall() {
			return Boolean(matchPath('app/*', this.location.pathname));
		}

		get isChat() {
			return this.isCurrentLocation(['/app/inbox/chat/:channelId', '/app/projects/:projectId/chat/:channelId']);
		}

		get isChannelInfo() {
			return this.isCurrentLocation(['/app/channel/:channelId/info']);
		}

		get isInboxChat() {
			return this.isCurrentLocation('/app/inbox/chat/:channelId');
		}

		get isAdmin() {
			return this.isCurrentLocation('/app/admin/:path');
		}

		isCurrentLocation = (paths: string | string[]) => {
			return Boolean(
				(Array.isArray(paths) ? paths : [paths]).some((path: string) => matchPath(this.currentPathname, path))
			);
		};

		goBack = () => {
			// @todo this needs a proper fix
			if (this.isAdmin || this.isChat) {
				this.history('/dashboard');
				return;
			}

			this.history(-1);
		};

		openMenu = (menuType: MenuType) => (event: React.MouseEvent<HTMLElement>) => {
			switch (menuType) {
				case MenuType.Overflow:
					this.overflowMenuAnchorElement = event.currentTarget;
					break;
				case MenuType.Notifications:
					this.notificationsMenuAnchorElement = event.currentTarget;
					break;
			}
		};

		closeMenu = () => {
			this.notificationsMenuAnchorElement = null;
			this.overflowMenuAnchorElement = null;
		};

		toggleCreateProjectDialog = () => {
			this.uiState.toggleCreateProjectDialog();
		};

		async handleOrder() {
			try {
				const newProject = await this.projectStore.newProject(this.uiState.pendingOrder);
				console.log('TopAppBar got new project from new order', newProject);
				this.goBack();
			} catch (_e) {
				this.uiState.pendingOrder.status = 'ERROR';
				this.uiState.pendingOrder.error = 'Unable to create new project';
			}
		}

		renderLogo() {
			const useWhiteLogo =
				this.isCurrentPathBehindLoginWall || (this.isHomeScreen && config.appId === AppId.Smart);

			return <AppLogo width="120px" height="100%" darkMode={useWhiteLogo} />;
		}

		renderStartSection() {
			// eslint-disable-next-line no-undef
			const commonStartSection = (icon: React.JSX.Element, title: string) => (
				<>
					<IconButton color="inherit" edge="start" onClick={this.goBack}>
						{icon}
					</IconButton>
					<Badge badgeContent={this.workspaceStore.getSetting('beta', 'disabled') === false ? 'BETA' : ''}>
						<Typography variant="h6">{title}</Typography>
					</Badge>
				</>
			);

			// eslint-disable-next-line no-undef
			const buttonLinkStartSection = (icon: React.JSX.Element, title: string, link: string) => (
				<>
					<IconButton color="inherit" edge="start" onClick={this.goBack}>
						{icon}
					</IconButton>
					<Badge badgeContent={this.workspaceStore.getSetting('beta', 'disabled') === false ? 'BETA' : ''}>
						<Button color="inherit" endIcon={<KeyboardArrowDownIcon />} component={Link} to={link}>
							{title}
						</Button>
					</Badge>
				</>
			);

			if (this.isCurrentLocation('/app/projects/:projectId/info')) {
				return commonStartSection(<ChevronLeftIcon />, 'Jobbinformasjon');
			} else if (this.isChat) {
				// @todo this should only be a button if user is craftsman - for ease of dev this is currently disabled
				// if (!this.profileStore.isCurrentUserCraftsman) {
				//   return commonStartSection(<ChevronLeftIcon />, "Chat");
				// }
				const channel = this.channelId ? this.chatStore.findChannel(this.channelId, true) : undefined;
				return buttonLinkStartSection(
					<ChevronLeftIcon />,
					channel?.name ? `#${channel.name}` : 'Samtaleinfo',
					`/channel/${this.channelId}/info`
				);
			} else if (this.isCurrentLocation('/app/new-project')) {
				return commonStartSection(<CloseIcon />, 'Ny jobb');
			} else {
				let title: string = '';

				return (
					<Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
						<IconButton color="inherit" edge="start" onClick={this.uiState.toggleNavigationDrawer}>
							<MenuIcon />
						</IconButton>

						{title ? (
							<Badge
								badgeContent={
									this.workspaceStore.getSetting('beta', 'disabled') === false ? 'BETA' : ''
								}
								sx={(theme) => ({
									color: theme.palette.primary.contrastText,
								})}
							>
								<Typography variant="h6">{title}</Typography>
							</Badge>
						) : (
							<Badge
								badgeContent={
									this.workspaceStore.getSetting('beta', 'disabled') === false ? 'BETA' : ''
								}
								sx={(theme) => ({
									color: theme.palette.primary.contrastText,
								})}
							>
								<Link
									to={this.isCurrentPathBehindLoginWall ? `/app/dashboard` : '/'}
									style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
									className="no-underline"
								>
									{this.renderLogo()}
								</Link>
							</Badge>
						)}
					</Box>
				);
			}
		}

		renderEndSection() {
			const { currentUserProfile } = this.profileStore;
			const { isValidPendingOrder } = this.uiState;
			const { currentUserId } = this.userStore;

			if (this.isCurrentLocation('/app/new-project')) {
				return (
					<section>
						<Button disabled={!isValidPendingOrder} onClick={this.handleOrder}>
							Bestill
						</Button>
					</section>
				);
			} else if (this.isCurrentPathBehindLoginWall && currentUserId) {
				return (
					<Stack direction="row" alignItems="center">
						<Hidden mdDown>
							<CraftsmanGuard>
								<FeatureGuard feature="project.create">
									<motion.div
										whileHover={{ scale: 1.05 }}
										whileTap={{ scale: 0.95 }}
										transition={{ type: 'spring', stiffness: 300 }}
									>
										<Button
											variant="outlined"
											startIcon={<AddIcon />}
											sx={{
												fontWeight: 'bold',
												color: '#fff',
												backgroundColor: 'transparent',
												borderColor: '#fff',
												transition: 'all 0.3s ease',
												marginRight: '0.5rem',
												'&:hover': {
													backgroundColor: '#fbbf24',
													borderColor: '#fff',
												},
											}}
											onClick={this.toggleCreateProjectDialog}
										>
											Ny jobb
										</Button>
									</motion.div>
								</FeatureGuard>
								<NotificationsMenu />
							</CraftsmanGuard>
						</Hidden>

						<AccountMenu />
					</Stack>
				);
			} else {
				if (currentUserId) {
					const name = currentUserProfile?.name ? currentUserProfile.name.split(' ')[0] : '';
					return (
						<Stack direction="row">
							<Stack
								data-hj-suppress
								direction="column"
								justifyContent="center"
								sx={(theme) => ({
									textAlign: 'right',
									display: { xs: 'flex', sm: 'flex' },
									color: theme.palette.primary.contrastText,
								})}
							>
								<Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
									{name}
								</Typography>
								<Typography
									variant="body2"
									sx={{ fontSize: '0.7rem', maxWidth: '100px' }}
									className="text-ellipsis"
								>
									{this.company?.name}
								</Typography>
							</Stack>
							<Tooltip title="Min side">
								<IconButton
									component={Link}
									to="/app/dashboard"
									size="small"
									sx={{ ml: 1 }}
									// aria-controls={this.open ? 'account-menu' : undefined}
									// aria-haspopup="true"
									// aria-expanded={this.open ? 'true' : undefined}
								>
									<ProfilePicture
										noTooltip
										sx={{ width: 32, height: 32 }}
										profile={currentUserProfile}
									/>
								</IconButton>
							</Tooltip>
						</Stack>
					);
				}

				return <LoginButton />;
			}
		}

		render() {
			const isVideoCallScreen = this.isCurrentLocation(
				'/app/projects/:projectId/chat/:channelId/video/:sessionId'
			);

			if (isVideoCallScreen) {
				return null;
			}

			const isBefareSmart = config.appId === AppId.Smart;
			let defaultTextColor: 'textPrimary' | 'textSecondary' | 'white' | undefined = 'textPrimary';
			if (isBefareSmart) {
				// if route is home or / then use white text
				if (this.isHomeScreen) {
					defaultTextColor = 'white';
				}
			}

			return (
				<>
					<ElevationScroll
						isCurrentPathBehindLoginWall={this.isCurrentPathBehindLoginWall}
						color={isBefareSmart ? 'primary' : 'default'}
						defaultTextColor={defaultTextColor}
					>
						<AppBar className="TopAppBar" position="sticky">
							<Toolbar>
								<Box flexGrow="1">{this.renderStartSection()}</Box>
								{this.renderEndSection()}
							</Toolbar>
						</AppBar>
					</ElevationScroll>
					<NavigationDrawer />
				</>
			);
		}
	}
);

export default withHooks(TopAppBar);

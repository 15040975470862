import React, { useState } from 'react';
import { Stack, Box, Typography, InputAdornment, IconButton, TextField } from '@mui/material';
import Xarrow, { Xwrapper } from 'react-xarrows';
import { Check, Language } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { smartOrange } from './HomeSmart';

type WebUrlInputProps = {
	onSubmit: (url: string) => void;
	id?: string;
};

const WebUrlInput = (props: WebUrlInputProps) => {
	const [webUrl, setWebUrl] = useState('');
	const [error, setError] = useState(false);

	const handleWebUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setWebUrl(value);

		// Enkel URL-validering
		const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[^\s]*)?$/i;
		setError(!urlPattern.test(value));
	};

	const handleSubmit = () => {
		if (!error && webUrl.trim() !== '') {
			props.onSubmit(webUrl);
		}
	};

	const onKeyUp = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			handleSubmit();
		}
	};

	return (
		<TextField
			label="Web URL"
			variant="outlined"
			id={props.id}
			fullWidth
			value={webUrl}
			onChange={handleWebUrlChange}
			onKeyUp={onKeyUp}
			error={error}
			helperText={error ? 'Ugyldig URL' : ''}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<Language color="action" />
					</InputAdornment>
				),
				endAdornment: (
					<InputAdornment position="end">
						<IconButton onClick={handleSubmit} disabled={error || webUrl.trim() === ''}>
							<Check />
						</IconButton>
					</InputAdornment>
				),
			}}
			sx={(theme) => ({
				color: theme.palette.primary.contrastText,
				'& input:-webkit-autofill': {
					WebkitBoxShadow: '0 0 0 100px transparent inset', // Transparent bakgrunn
					WebkitTextFillColor: 'inherit', // Bruk standard tekstfarge
				},
				'& input:-webkit-autofill:hover, & input:-webkit-autofill:focus': {
					WebkitBoxShadow: '0 0 0 100px transparent inset', // Transparent også på hover/fokus
					WebkitTextFillColor: 'inherit',
				},
			})}
		/>
	);
};

type SmartChatDemoUrlInputProps = {
	onWebUrlSubmit?: (url: string, mode: 'desktop' | 'mobile') => void;
	preventNavigation?: boolean;
	title?: string;
	description?: string;
};

const SmartChatDemoUrlInput = (props: SmartChatDemoUrlInputProps) => {
	const navigate = useNavigate();

	const onWebUrlSubmit = (url: string, mode: 'desktop' | 'mobile' = 'desktop') => {
		if (props.onWebUrlSubmit) {
			props.onWebUrlSubmit(url, mode);
			return;
		}
		// we want just the domain, so let's strip the protocol and path using URL constructor
		// if missing https://, we'll add it
		if (!url.startsWith('http')) {
			url = 'https://' + url;
		}
		try {
			const domain = new URL(url).hostname;

			if (!props.preventNavigation) {
				navigate(`/smart-demo/${domain}?mode=${mode}`);
			}
		} catch (error) {
			console.error('Invalid URL', error);
			toast.error('Ugyldig URL');
		}
	};

	return (
		<Xwrapper>
			<Stack direction="column" spacing={16} sx={{ width: '100%', zInedx: 2, position: 'relative' }}>
				<WebUrlInput onSubmit={onWebUrlSubmit} id="url-input" />
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						// position: 'absolute',
						// bottom: '-8rem',
						// right: 0,
						// translate: 'transformX(-20%)',
						// padding: '1rem',
					}}
				>
					{/* <Box sx={{ position: 'absolute', top: 0, left: '50%', transform: 'translateX(-50%)' }} id="url-note" /> */}
					<Stack spacing={2}>
						<Typography id="url-note" component="h2" variant="h6" color={smartOrange} textAlign="center">
							{props.title ?? 'Prøv SmartChat på din nettside'}
						</Typography>
						<Typography variant="body2" color="textSecondary">
							{props.description ??
								`Dette er bare en demo basert på noen få sider fra din nettside. Ingenting
							blir endret på nettsiden du skriver inn.`}
						</Typography>
					</Stack>
				</Box>
				<Xarrow
					start="url-note" // ID of the starting element
					startAnchor={'top'}
					end="url-input" // ID of the target element
					endAnchor={{ position: 'bottom', offset: { x: -100, y: 0 } }} // Position of the target's anchor
					dashness={{ animation: 1 }}
					color={smartOrange}
					strokeWidth={2}
					path="smooth" // Smooth curved arrow
					headSize={8} // Size of the arrowhead
				/>
			</Stack>
		</Xwrapper>
	);
};

export default SmartChatDemoUrlInput;

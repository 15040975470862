export class DateUtil {
	static createDateWithTimezoneOffset(isoString?: string) {
		if (!isoString) {
			return new Date();
		}
		const date = new Date(isoString);
		return date;
	}

	static postedWhen(created: Date) {
		const now = new Date();
		const NOW_COMPARISON: number = now.getTime() - 120 * 1000; // 120 sec
		const HOURS_COMPARISON: number = now.getTime() - 3600 * 6 * 1000; // 6 sec
		const WEEK_COMPARISON: number = now.getTime() - 1000 * 60 * 60 * 24 * 7;

		if (created.getTime() > NOW_COMPARISON) {
			return 'akkurat nå';
		}

		const diff = now.getTime() - created.getTime(); // Difference in milliseconds

		// const days = Math.floor(diff / (1000 * 60 * 60 * 24));
		const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
		const minutes = Math.floor((diff / (1000 * 60)) % 60);

		if (created.getTime() > HOURS_COMPARISON) {
			if (hours > 1) {
				return `${hours} t`;
			}

			return `${Math.floor(minutes)} min`;
		}

		if (
			created.getDate() === now.getDate() &&
			created.getMonth() === now.getMonth() &&
			created.getFullYear() === now.getFullYear()
		) {
			return 'i dag';
		}

		if (
			created.getDate() === now.getDate() - 1 &&
			created.getMonth() === now.getMonth() &&
			created.getFullYear() === now.getFullYear()
		) {
			return 'i går';
		}

		if (created.getTime() > WEEK_COMPARISON) {
			return created.toLocaleDateString('nb-NO', {
				weekday: 'short',
			});
		}

		return created.toLocaleDateString('nb-NO', {
			day: 'numeric',
			month: 'short',
		});
	}

	static dateToLocaleFormat(date: Date, locale: string = 'nb-NO') {
		if (!date?.toDateString) {
			return '';
		}
		return date.toLocaleDateString(locale, {
			day: 'numeric',
			month: 'short',
			year: 'numeric',
		});
	}

	static dateToLocaleTimeFormat(date: Date, locale: string = 'nb-NO') {
		if (!date?.toDateString) {
			return '';
		}
		return date.toLocaleTimeString(locale, {
			hour: '2-digit',
			minute: '2-digit',
		});
	}

	static toLocaleDateTimeFormat(date: Date, locale: string = 'nb-NO') {
		if (!date?.toDateString) {
			return '';
		}
		return `${this.dateToLocaleFormat(date, locale)} ${this.dateToLocaleTimeFormat(date, locale)}`;
	}

	static toHHMMSS(secs: number) {
		const sec_num = Math.round(secs);
		const hours = Math.floor(sec_num / 3600);
		const minutes = Math.floor(sec_num / 60) % 60;
		const seconds = sec_num % 60;

		const [sH, sM, sS] = [hours, minutes, seconds].map((v) => (v < 10 ? '0' + v : v));
		// .filter((v, i) => v !== '00' || i > 0);

		return `${sH !== '00' ? sH + 't ' : ''}${sM !== '00' ? sM + 'min ' : ''} ${sS !== '00' ? sS + 's' : ''}`;
	}
}

import {
	Alert,
	Autocomplete,
	Box,
	Button,
	Chip,
	Container,
	Grid,
	Hidden,
	IconButton,
	Stack,
	StyledComponentProps,
	TextField,
	Typography,
} from '@mui/material';
import { HookTypes, withHooks } from '../../utils/withHooks';
import { Project } from '../../stores/Project';
import { action, computed, makeObservable, observable, runInAction, toJS } from 'mobx';

import AuthManager from '../../auth/AuthManager';
import { ChannelTypeDTO } from '../../dto/channel.types';
import CompanyAvailabilityDialog from '../home/CompanyAvailabilityDialog';
import CompanyCard from '../common/company/CompanyCard';
import EditIcon from '@mui/icons-material/Edit';
import Footer from '../footer/Footer';
import LogUtil from '../../helpers/LogUtil';
import OrderChatConfirmationComponent from '../order-chat/OrderChatConfirmationComponent';
import ProjectApi from '../../api/endpoints/ProjectApi';
import React from 'react';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import ProductBuilder from './ProductBuilder';
import FeatureGuard from '../../utils/FeatureGuard';
import CompanyLogo from '../common/CompanyLogo';
import { TrakEvent } from '../../api/endpoints/TrakApi';
import HowItWorksCarousel from '../how-it-works/HowItWorksCarousel';
import CompaniesNearUser from '../common/company/CompaniesNearUser';
import OrderChatCreatingProject from '../order-chat/OrderChatCreatingProject';
import OrderChatVerificationCode from '../order-chat/OrderChatVerificationCode';
import MinElektrikerNorgeseliten from '../common/MinElektrikerNorgeseliten';
import AddressCard from '../common/AddressCard';
import { Address } from '../../types';
import { toast } from 'react-toastify';
import CountUp from 'react-countup';
import { Company } from '../../stores/company/Company';

const PREFIX = 'OrderChatScreen';

const classes = {
	root: `${PREFIX}-root`,
	textArea: `${PREFIX}-textArea`,
	backButton: `${PREFIX}-backButton`,
	image: `${PREFIX}-image`,
	resendVerificationCodeButton: `${PREFIX}-resendVerificationCodeButton`,
};

const Root = styled('div')(({ theme: { spacing, breakpoints, mixins } }) => ({
	[`& .${classes.root}`]: {
		minHeight: '86vh',
		marginTop: spacing(2),
		marginBottom: spacing(2),
		[breakpoints.up('sm')]: {
			marginTop: spacing(3),
			marginBottom: spacing(3),
		},
		[breakpoints.up('lg')]: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			minHeight: '86vh',
			marginTop: 0,
			marginBottom: mixins.toolbar.minHeight,
		},
	},
	[`& .${classes.resendVerificationCodeButton}`]: {
		marginTop: '2rem',
	},

	[`& .${classes.textArea}`]: {
		marginBottom: spacing(1),
	},

	[`& .${classes.backButton}`]: {
		marginRight: 8,
	},

	[`& .${classes.image}`]: {
		width: '100%',
	},
}));

// eslint-disable-next-line no-unused-vars
enum ORDER_CHAT_STEPS {
	// eslint-disable-next-line no-unused-vars
	COMPLETE_FORM,
	// eslint-disable-next-line no-unused-vars
	VERIFY_PHONE,
	// eslint-disable-next-line no-unused-vars
	CREATING_PROJECT,
	// eslint-disable-next-line no-unused-vars
	ORDER_COMPLETED,
}

type Props = HookTypes &
	StyledComponentProps & {
		hideCompanyCard?: boolean;
	};

const OrderChatScreen2 = observer(
	class OrderChat2 extends React.Component<Props> {
		static readonly contextType = StoreContext;

		get location() {
			return this.props.location!;
		}

		get params() {
			return this.props.params!;
		}

		get history() {
			return this.props.navigate!;
		}

		get queryParams() {
			return this.props.queryObj;
		}

		formRef = React.createRef<any>();

		addressValue?: Address;
		addressOptions: Address[] = [];
		loading: boolean = true;

		findAddressDebounced: any;

		selectedCompany: Company | undefined = undefined;
		nearbyCompanies: Company[] = [];

		_name: string = '';
		_phone: string = '';
		_email: string = '';
		description: string = '';
		phoneError: string | undefined;
		descriptionError: string | undefined;
		newProject: Project | undefined;
		creatingProject: boolean = false;
		missingCompany: boolean = false;

		currentStep: ORDER_CHAT_STEPS = ORDER_CHAT_STEPS.COMPLETE_FORM;
		createProjectProgress: number = 0;
		createProjectCaption: string = 'Validerer data';

		showCompanyAvailabilityDialog: boolean = false;

		chosenProducts: any;
		_hideCompanyCard: boolean = false;

		trakEvent: TrakEvent | undefined;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				addressOptions: observable,
				addressValue: observable,
				selectedCompany: observable,
				chosenProducts: observable,
				_name: observable,
				_phone: observable,
				_email: observable,
				description: observable,
				currentStep: observable,
				createProjectProgress: observable,
				createProjectCaption: observable,
				newProject: observable,
				phoneError: observable,
				descriptionError: observable,
				missingCompany: observable,
				showCompanyAvailabilityDialog: observable,
				creatingProject: observable,
				_hideCompanyCard: observable,
				loading: observable,
				hideCompanyCard: computed,
				companyStore: computed,
				userStore: computed,
				profileStore: computed,
				projectStore: computed,
				uiState: computed,
				company: computed,
				companyAddress: computed,
				profile: computed,
				name: computed,
				email: computed,
				phone: computed,
				setOptions: action,
				setInputValue: action,
				setValue: action,
				findElectrician: action,
				findAddress: action,
				findCompany: action,
				init: action,
				onVerificationCodeComplete: action,
				sendVerificationCode: action,
				createProject: action,
				setName: action,
				setEmail: action,
				setPhone: action,
				setDescription: action,
				updateUserData: action,
				toggleCompanyAvailabilityDialog: action,
				changeSelectedCompany: action,
				onChosenProducts: action,
				selectCompanyByCompanyId: action,
				loadNearbyCompanies: action,
				onNearbyCompaniesLoaded: action,
				setAddress: action,
			});

			this.findAddressDebounced = debounce((value: string) => this.findAddress(value), 500);
		}

		componentDidMount() {
			this.init().catch((e) => console.error(e));
		}

		componentDidUpdate(prevProps: Readonly<Props>): void {
			if (prevProps.params?.companyId !== this.params.companyId) {
				this.init().catch((e) => console.error(e));
			}
		}

		async init() {
			if (!this.trakEvent) {
				this.trakEvent = await this.rootStore.trakEvent({
					type: 'OrderChat',
					value: 'loaded',
					experiment: `OrderChatScreen2${this.props.hideCompanyCard ? '-hideCompanyCard' : ''}`,
				});
			}

			if (this.params.postCode && this.params.companyId) {
				const company = this.nearbyCompanies.find((company) => '' + company.id == '' + this.params.companyId);
				if (!company) {
					await this.companyStore.loadCompanyFromCompanyId(this.params.companyId);
					await this.loadNearbyCompanies(this.params.postCode);
				}

				await this.selectCompanyByCompanyId(this.params.companyId);
				runInAction(() => {
					this._hideCompanyCard = true;
				});
			}

			if (this.queryParams.d) {
				this.description = this.queryParams.d;
			}

			runInAction(() => (this.loading = false));
		}

		onChosenProducts = (products: any) => {
			this.chosenProducts = products;
		};

		updateUserData = () => {
			if (this.profile) {
				if (!this.name) {
					this._name = '' + this.profile.name;
				}
				let profilePhone = this.profile.phoneNumber;
				if (this.profile?.phoneNumber?.indexOf(' ') && !this.phone) {
					profilePhone = this.profile.phoneNumber.split(' ')[1];
				}

				this._phone = this.phone ?? profilePhone ?? '';
				this._email = this.profile.email ?? this.email ?? '';

				if (this.profileStore.mostRecentUserAddress && !this.address?.postCode) {
					this.setInputValue(this.profileStore.mostRecentUserAddress.addressString);

					this.setAddress(this.profileStore.mostRecentUserAddress);
				}
			}
		};

		get rootStore() {
			return this.context as RootStore;
		}

		get companyStore() {
			return this.rootStore.companyStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get projectStore() {
			return this.rootStore.projectStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get hideCompanyCard() {
			return this._hideCompanyCard || this.props.hideCompanyCard;
		}

		get address() {
			return this.addressValue;
		}

		get company() {
			return this.selectedCompany;
		}

		get companyAddress() {
			if (!this.company) {
				return undefined;
			}

			return {
				street: this.company.address,
				postCode: this.company.postalCode,
				postArea: this.company.city,
			};
		}

		get profile() {
			return this.profileStore.currentUserProfile;
		}

		get name() {
			return this._name ?? this.profile?.name ?? '';
		}

		get email() {
			return this._email ?? this.profile?.email ?? '';
		}

		get phone() {
			return this._phone ?? this.profile?.phoneNumber ?? '';
		}

		get customerChannel() {
			return this.newProject?.channels?.find((channel) => channel.channelType === ChannelTypeDTO.ProjectCustomer);
		}

		get customerChannelUrl() {
			if (this.customerChannel) {
				return `/projects/${this.newProject!.id}/chat/${this.customerChannel.id}?orderCompleted`;
			}
			return '/dashboard';
		}

		get points() {
			if (this.addressValue?.lat && this.addressValue?.lon) {
				return [
					{
						lat: this.addressValue.lat,
						lon: this.addressValue.lon,
						name: this.addressValue.addressString ?? this.addressValue.street,
					},
				];
			}

			return [];
		}

		get postCode() {
			return this.addressValue?.postCode ?? this.params.postCode;
		}

		setOptions = (value: Address[]) => {
			this.addressOptions = value;
		};

		setValue = (value: Address) => {
			if (value?.street) {
				const address = this.addressOptions.find((option) => {
					return option.street === value.street && option.postCode === value.postCode;
				});

				if (address) {
					this.setAddress(address);
					this.findElectrician();
				} else {
					this.addressValue = undefined;
					this.trakEvent = undefined;
					this.rootStore.setTrakMeta({});
				}
			} else {
				this.addressValue = undefined;
				this.selectedCompany = undefined;
				this.trakEvent = undefined;
				this.rootStore.setTrakMeta({});
			}
			this.missingCompany = false;
		};

		setInputValue = (value: any) => {
			this.findAddressDebounced(value);
		};

		setName = (e: React.ChangeEvent<HTMLInputElement>) => {
			this._name = e.target.value;

			if (this.name.length > 0) {
				this.descriptionError = undefined;
			}
		};

		setEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
			this._email = e.target.value;
		};

		setPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
			this._phone = e.target.value;
			if (this._phone.length > 0) {
				this.phoneError = undefined;
			}
		};

		setDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
			this.description = e.target.value;
			if (this.description.length > 0) {
				this.descriptionError = undefined;
			}
		};

		setAddress = (address: Address) => {
			console.log('setting address', address);
			this.addressValue = address;
			if (this.addressValue?.street) {
				this.descriptionError = undefined;
			}
		};

		findElectrician = async () => {
			// todo: add some checks and error messages
			await this.findCompany();
		};

		findCompany = async () => {
			if (this.addressValue?.postCode) {
				await this.loadNearbyCompanies(this.addressValue?.postCode);
			}
		};

		selectCompanyByCompanyId = async (companyId: string) => {
			const company = this.companyStore.findCompanyById(companyId);
			console.log('selectCompanyByCompanyId', companyId, company);
			if (company) {
				this.changeSelectedCompany(company);
				this.missingCompany = false;
			}
		};

		loadNearbyCompanies = async (postCode: string) => {
			if (postCode) {
				console.log('loading companies', postCode);
				await this.companyStore.loadCompanies(postCode);
				this.nearbyCompanies =
					this.companyStore.getCompaniesNearPostCode(postCode ?? this.addressValue?.postCode) ?? [];

				runInAction(() => {
					this.rootStore.setTrakMeta({
						location: {
							country: 'Norway',
							postCode: postCode,
							lat: this.addressValue?.lat,
							lon: this.addressValue?.lon,
						},
					});
				});
				await this.onNearbyCompaniesLoaded(postCode);
			}
		};

		onNearbyCompaniesLoaded = async (postCode?: string) => {
			console.log('onNearbyCompaniesLoaded', postCode, this.nearbyCompanies);
			this.nearbyCompanies =
				this.companyStore.getCompaniesNearPostCode(postCode ?? this.addressValue?.postCode) ?? [];
			if (this.nearbyCompanies.length > 0) {
				this.selectCompanyByCompanyId(this.nearbyCompanies[0]?.id).catch((e) => console.error(e));

				setTimeout(() => {
					this.formRef?.current?.scrollIntoView();
				});
			} else {
				this.trakEvent = await this.rootStore.trakEvent({
					...this.trakEvent,
					outcome: 'NoCompany',
					value: this.addressValue?.postArea,
					type: 'OrderChat',
					experiment: `OrderChatScreen2${this.hideCompanyCard ? '-hideCompanyCard' : ''}`,
				});
				this.missingCompany = true;
				LogUtil.warn(
					`:alert: Customer could not find company at near post code ${this.addressValue?.postCode}`
				);
			}
		};

		toggleCompanyAvailabilityDialog = () => {
			this.showCompanyAvailabilityDialog = !this.showCompanyAvailabilityDialog;
		};

		changeSelectedCompany = async (company?: Company | undefined) => {
			this.showCompanyAvailabilityDialog = false;
			if (company) {
				this.selectedCompany = company;
				this.trakEvent = await this.rootStore.trakEvent({
					...this.trakEvent,
					outcome: 'FoundCompany',
					value: this.selectedCompany.name,
					type: 'OrderChat',
					experiment: `OrderChatScreen2${this.hideCompanyCard ? '-hideCompanyCard' : ''}`,
				});
			}
		};

		findAddress = async (address: string) => {
			if (address.length > 3) {
				const response = await ProjectApi.AddressSearch(`${address}*`);
				if (response.statusCode === 200) {
					if (response.data.hits) {
						const options: Address[] = response.data.hits.map((hit: any) => ({
							street: hit.adressetekst,
							postCode: hit.postnummer,
							postArea: hit.poststed,
							lat: hit.representasjonspunkt?.lat,
							lon: hit.representasjonspunkt?.lon,
							municipality: hit.kommunenavn,
						}));

						this.setOptions(options);
						return;
					}
				}
			}

			this.setOptions([]);
		};

		// eslint-disable-next-line no-unused-vars
		onVerificationCodeComplete = async (code: string) => {
			await this.userStore.checkLoginStatus();
			await this.profileStore.loadProfile(true);
			runInAction(async () => {
				const profile: any = {
					name: this.name.length > (this.profile?.name ?? '').length ? this.name : (this.profile?.name ?? ''),
					email: this.email || this.profile?.email,
				};
				await this.profileStore.editProfile(profile);
			});

			if (this.userStore.isLoggedIn) {
				runInAction(() => {
					this.currentStep = ORDER_CHAT_STEPS.CREATING_PROJECT;
				});
				this.updateUserData();
				await this.createProject();
			}
		};

		sendVerificationCode = async () => {
			if (!this.phone) {
				this.phoneError = 'Legg til mobilnr.';
				return;
			}
			try {
				this.currentStep = ORDER_CHAT_STEPS.VERIFY_PHONE;
				await AuthManager.phoneAuth(undefined, this.phone);
			} catch (err) {
				console.error(err);
			}
		};

		validateData = () => {
			if (!this.name) {
				this.descriptionError = 'Legg inn ditt navn.';
				return false;
			}

			if (!this.userStore.isLoggedIn && !this.phone) {
				this.descriptionError = 'Legg inn ditt mobilnr.';
				return false;
			}

			if (!this.selectedCompany) {
				return false;
			}
			let chosen = [];
			if (this.chosenProducts) {
				chosen = Object.keys(this.chosenProducts).map((key: any) => {
					return this.chosenProducts[key].filter((product: any) => product.quantity > 0);
				});
			}
			if (!(this.description || chosen.length > 0)) {
				this.descriptionError = 'Legg inn en beskrivelse av det du lurer på';
				return false;
			}

			if (!this.address) {
				this.descriptionError = 'Legg inn oppdragsadresse.';
				return false;
			}

			this.descriptionError = undefined;

			return true;
		};

		createProject = async () => {
			if (this.creatingProject || this.newProject) {
				return;
			}

			if (!this.validateData()) {
				// todo: add error message
				console.warn('Missing required data for create project to work');
				this.currentStep = ORDER_CHAT_STEPS.COMPLETE_FORM;
				return;
			}

			if (!this.profile?.userId) {
				this.sendVerificationCode();
				return;
			}

			this.creatingProject = true;

			this.currentStep = ORDER_CHAT_STEPS.CREATING_PROJECT;
			this.createProjectProgress = 0;
			window.scrollTo(0, 0);

			LogUtil.warn(
				`User tried to send a request to workspace ${this.selectedCompany?.workspaceId}. Preselected company was ${this.selectedCompany?.id}.`
			);

			// we have everything we need to create the project and send the user to the next step

			let selectedAddress: any = this.profileStore.currentUserAddress.find(
				(a) => a.street?.toLowerCase() === this.address?.street?.toLowerCase()
			);

			if (this.chosenProducts && !this.description) {
				let products: string = `# Ønsker tilbud på:`;
				Object.keys(this.chosenProducts).forEach((key: any) => {
					this.chosenProducts[key]
						.filter((product: any) => product.quantity > 0)
						.forEach((product: any) => {
							products += `\n- ${product.quantity}stk ${product.name}`;
						});
				});
				this.description = products;
			}

			const project: any = {
				description: this.description,
				addressId: selectedAddress?.id,
				workspaceId: this.selectedCompany!.workspaceId,
			};

			if (!project.addressId && this.address) {
				console.log('Creating address', this.address);
				await runInAction(async () => {
					this.createProjectProgress = 20;
					this.createProjectCaption = 'Kontakter server';
					const address = await this.profileStore.saveUsersAddress(this.address!);
					project.addressId = address?.id;
					if (!project.addressId) {
						//todo: Handle error
						LogUtil.error('Project missing address', project);
						toast('Kunne ikke opprette jobb. Prøv igjen senere.', { type: 'error' });
					}
				});
			}

			console.log('Creating project', project, this.address);

			runInAction(() => {
				this.createProjectProgress = 50;
				this.createProjectCaption = 'Oppretter jobb';
			});
			const { hideCompanyCard } = this;
			const newProject = await this.projectStore.newProject(
				project,
				`OrderChatScreen2${hideCompanyCard ? '-hideCompanyCard' : ''}`
			);

			if (newProject) {
				runInAction(async () => {
					this.createProjectProgress = 100;
					this.createProjectCaption = 'Jobb opprettet';
					this.rootStore.setTrakMeta({
						location: {
							country: 'Norway',
							postCode: this.postCode,
							lat: this.addressValue?.lat,
							lon: this.addressValue?.lon,
						},
					});
					this.trakEvent = await this.rootStore.trakEvent({
						...this.trakEvent,
						outcome: 'JobCreated',
						value: this.selectedCompany?.name,
					});
					this.newProject = newProject;

					try {
						if ((window as any).dataLayer) {
							(window as any).dataLayer.push({
								event: 'order-chat-completed',
								value: 25,
							});
						}
					} catch (e) {
						console.error(e);
					}

					LogUtil.warn(
						`User created new project in ${this.selectedCompany?.workspaceId}. Preselected company was ${this.selectedCompany?.name}.`
					);
				});
			} else {
				//todo: we have  issues - fix plz
				LogUtil.error(':alert: Unable to create project', project);
				toast('Kunne ikke opprette jobb. Prøv igjen senere.', { type: 'error' });
			}
		};

		renderChosenProducts() {
			if (!this.chosenProducts) {
				return;
			}

			return Object.keys(this.chosenProducts).map((key: any, cIndex: number) => {
				return this.chosenProducts[key]
					.filter((product: any) => product.quantity > 0)
					.map((product: any, index: number) => {
						return (
							<Chip
								key={`product-${cIndex}-${index}`}
								label={product.quantity + ' ' + product.name}
								onDelete={console.log}
								sx={{ m: 1 }}
								color="primary"
							/>
						);
					});
			});
		}

		renderAddressForm() {
			const { addressOptions, addressValue } = this;
			const { workspaceTheme } = this.uiState;

			if (this.params.companyId && this.company?.id == this.params.companyId) {
				return;
			}

			return (
				<Grid item container xs={12}>
					<Hidden mdUp>
						<Grid item xs={12} md={6}>
							{/* <img
											alt=""
											style={{ maxWidth: '500px', margin: 'auto', display: 'block' }}
											className={classes?.image}
											src={`/illustrations/${workspaceTheme}/request.svg`}
										/> */}
							<HowItWorksCarousel />
						</Grid>
					</Hidden>
					<Hidden mdDown>
						<Grid item xs={12} sx={{ height: '6rem' }}></Grid>
					</Hidden>
					<Grid item container xs={12} md={6}>
						<Grid item xs={12}>
							{workspaceTheme == 'norgeseliten' ? (
								<>
									<Stack direction="column" sx={{ margin: '3rem 0' }}>
										<Typography variant="h2" component="span" fontWeight="bold">
											Vi fikser ditt
										</Typography>
										<Typography variant="h2" component="span" color="secondary" fontWeight="bold">
											el-anlegg
										</Typography>
									</Stack>

									<Typography variant="h5" gutterBottom>
										Mer enn{' '}
										<strong>
											<CountUp end={2500} /> faglærte elektrikere
										</strong>{' '}
										er her for å hjelpe deg.
									</Typography>
								</>
							) : (
								<Typography align="center" gutterBottom variant="h4">
									Start en <span className="text--italic text--bold">gratis chat</span> med en faglært
									håndverker.
								</Typography>
							)}

							<Typography align="center" gutterBottom variant="subtitle1">
								Søk på din adresse for å finne{' '}
								{workspaceTheme == 'norgeseliten' ? 'elektrikerene' : 'håndverkerene'} som er nærmest
								deg.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								data-hj-suppress
								id="address-lookup"
								fullWidth
								getOptionLabel={(option) =>
									typeof option === 'string'
										? option
										: `${option?.street}, ${option?.postCode} ${option?.postArea}`
								}
								filterOptions={(x) => x}
								options={toJS(addressOptions)}
								autoComplete
								includeInputInList
								filterSelectedOptions
								value={toJS(addressValue)}
								isOptionEqualToValue={(option: any, value: any) => option?.street === value?.street}
								onChange={(event: any, newValue: any) => {
									this.setValue(newValue);
								}}
								onInputChange={(event, newInputValue) => {
									this.setInputValue(newInputValue);
								}}
								renderInput={(params) => (
									<TextField {...params} label="Adresse" fullWidth autoComplete="street-address" />
								)}
							/>
							<Button
								variant="contained"
								color="primary"
								disabled={!this.address}
								fullWidth
								onClick={this.findElectrician}
								sx={{ margin: '1rem 0' }}
							>
								Finn {workspaceTheme == 'norgeseliten' ? 'elektriker' : 'håndverker'}
							</Button>
						</Grid>
					</Grid>
					<Hidden mdDown>
						<Grid
							item
							xs={12}
							md={6}
							sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
						>
							{/* <img
											alt=""
											className={classes?.image}
											src={`/illustrations/${workspaceTheme}/request.svg`}
										/> */}
							<HowItWorksCarousel />
						</Grid>
					</Hidden>
				</Grid>
			);
		}

		renderForm() {
			const { hideCompanyCard, name } = this;
			const forcedCompanyChoice = this.params.companyId && this.company?.id == this.params.companyId;
			return (
				<Root>
					<Container classes={{ root: classes.root }} className="OrderChatScreen">
						<Grid container>
							{this.renderAddressForm()}

							<Grid container item xs={12} justifyContent="center">
								{this.missingCompany && (
									<Grid container alignItems="center" justifyContent="center" direction="column">
										<Grid item xs={12} md={6}>
											<Alert severity="warning">
												Beklager, vi fant ingen som kan hjelpe deg nær denne adressen.
											</Alert>
										</Grid>
									</Grid>
								)}
								{this.company && (
									<>
										<Grid item xs={12} md={8} sx={{ padding: '1rem' }} ref={this.formRef}></Grid>

										{!hideCompanyCard ? (
											<Grid container item xs={12} md={8}>
												<Grid item xs={12} sx={{ padding: '1rem' }}>
													<Box>
														{forcedCompanyChoice ? (
															<Typography variant="overline">Chat med oss:</Typography>
														) : (
															<>
																<Typography variant="overline">
																	Nærmeste bedrift
																</Typography>
																<IconButton
																	onClick={this.toggleCompanyAvailabilityDialog}
																>
																	<EditIcon />
																</IconButton>
															</>
														)}
													</Box>
													<Box>
														<CompanyCard
															company={this.company}
															destination={this.address}
														/>
													</Box>
												</Grid>
											</Grid>
										) : (
											<Grid
												container
												item
												xs={12}
												md={8}
												justifyContent="center"
												alignItems="center"
											>
												<CompanyLogo company={this.company} />
											</Grid>
										)}

										<Grid container item xs={12} md={8} justifyContent="center" alignItems="center">
											<Grid item xs={12} sx={{ padding: '1rem' }}>
												<Box>
													<Box
														sx={{
															fontSize: '0.8rem',
															background: '#bae1f3',
															padding: '0.5rem',
															borderRadius: '4px',
															borderTopLeftRadius: 0,
															color: '#212121',
														}}
													>
														Hei! 👋
														<br />
														Vi trenger litt mer informasjon for å kunne sette deg i kontakt
														med {this.company.name}. Fyll inn skjemaet under så får du en
														SMS når {this.company.name} har fått tid til å se på
														henvendelsen.
													</Box>
												</Box>
											</Grid>
										</Grid>

										<FeatureGuard feature="befare.next">
											<Grid item xs={8} sx={{ padding: '1rem' }}>
												<Typography variant="subtitle2">
													Bruk produktvelgeren for å beskrive jobben, eller legg inn en
													beskrivelse i tekstfeltet.
												</Typography>
												<ProductBuilder onChange={this.onChosenProducts} />
											</Grid>
										</FeatureGuard>
										<Grid item xs={12} md={8} sx={{ padding: '1rem' }}>
											{/* PRODUCT BUILDER */}
											<Box sx={{ position: 'relative' }}>
												<TextField
													data-hj-suppress
													id="order-chat-description"
													label="Beskrivelse"
													multiline
													error={Boolean(this.descriptionError)}
													rows={4}
													fullWidth
													sx={{ margin: '0.5rem 0' }}
													placeholder=""
													variant="filled"
													value={this.description}
													onChange={this.setDescription}
													required={!this.chosenProducts}
													helperText="F.eks. Vil ha downlights på terrassen"
												/>
												<FeatureGuard feature="befare.next">
													<Box sx={{ position: 'absolute', bottom: '2rem' }}>
														{this.renderChosenProducts()}
													</Box>
												</FeatureGuard>
											</Box>

											<TextField
												id="order-chat-name"
												label="Navn"
												data-hj-suppress
												value={name}
												autoComplete="name"
												error={Boolean(this.descriptionError && name.length < 1)}
												fullWidth
												required
												sx={{ margin: '0.5rem 0' }}
												placeholder="Navn navnesen"
												variant="filled"
												onChange={this.setName}
											/>
											{!this.profile?.userId && (
												<TextField
													data-hj-suppress
													id="order-chat-phone"
													label="Brukernavn (Mobilnr)"
													value={this.phone}
													error={Boolean(this.descriptionError && this.phone.length < 8)}
													required
													fullWidth
													sx={{ margin: '0.5rem 0' }}
													placeholder="12345678"
													variant="filled"
													autoComplete="tel-national"
													onChange={this.setPhone}
													helperText="Brukes til å logge deg inn og gi deg beskjed når bedriften sender deg en melding"
												/>
											)}
											<TextField
												id="order-chat-email"
												label="E-post"
												data-hj-suppress
												value={this.email}
												fullWidth
												sx={{ margin: '0.5rem 0' }}
												placeholder="post@email.no"
												autoComplete="email"
												variant="filled"
												onChange={this.setEmail}
											/>

											<AddressCard
												address={this.address}
												preventSave
												onAddressSaved={this.setAddress}
												onChange={this.setAddress}
												editOnInit
												style={{ margin: '1rem 0' }}
											/>

											{this.descriptionError && (
												<Alert severity="error" style={{ margin: '1rem 0' }}>
													{this.descriptionError}
												</Alert>
											)}

											{this.profile && (
												<Button
													variant="contained"
													color="primary"
													fullWidth
													onClick={this.createProject}
												>
													Neste
												</Button>
											)}
											{!this.profile && (
												<>
													<Typography variant="body2" gutterBottom>
														Når du trykker på "Neste" vil du få tilsendt en engangs
														verifikasjonskode på SMS. Denne må du skrive inn for å bekrefte
														at du er eier av mobilnummeret.
													</Typography>

													<Typography variant="body2" gutterBottom>
														Det er <strong>gratis</strong> og <strong>uforpliktende</strong>
													</Typography>

													<Button
														variant="contained"
														color="primary"
														fullWidth
														onClick={this.createProject}
													>
														Neste
													</Button>
												</>
											)}
										</Grid>

										<CompanyAvailabilityDialog
											isOpen={this.showCompanyAvailabilityDialog}
											onClose={this.toggleCompanyAvailabilityDialog}
											onSelect={this.changeSelectedCompany}
											postCode={this.postCode}
										/>
									</>
								)}
							</Grid>
							{!this.company && <CompaniesNearUser />}

							<FeatureGuard feature="minelektriker-norgeseliten">
								<MinElektrikerNorgeseliten />
							</FeatureGuard>
						</Grid>
					</Container>

					<Footer />
				</Root>
			);
		}

		render() {
			if (this.loading) {
				return;
			}

			switch (this.currentStep) {
				case ORDER_CHAT_STEPS.ORDER_COMPLETED:
					return <OrderChatConfirmationComponent />;
				case ORDER_CHAT_STEPS.VERIFY_PHONE:
					return <OrderChatVerificationCode phone={this.phone} onSuccess={this.onVerificationCodeComplete} />;
				case ORDER_CHAT_STEPS.CREATING_PROJECT:
					return (
						<OrderChatCreatingProject
							progress={this.createProjectProgress}
							caption={this.createProjectCaption}
							url={this.customerChannelUrl}
							points={this.points}
						/>
					);
				default:
					return this.renderForm();
			}
		}
	}
);

export default withHooks(OrderChatScreen2);

import AsyncStorageHelper from '../../auth/AsyncStorageHelper';
import config from '../../config/config';
import { ChannelDTO, ChannelTypeDTO } from '../../dto/channel.types';
import { FetchWrapper, FetchWrapperResponse, FetchWrapperTypedResponse } from '../../fetch/FetchWrapper';
import Message from '../../stores/Message';
import { UpsertMessageDTO } from '../../types';

/**
 * Chats
 */
export default class ChatApi {
	static async archiveChannel(channelId: number): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channel/${channelId}/archive`;

		return FetchWrapper.put(url, {
			requireAuth: true,
		});
	}

	static async createChannel(
		channelType: ChannelTypeDTO,
		channelName: string,
		initialMembers: string[] = [],
		projectId?: string,
		agentId?: number
	): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/create-channel`;

		return FetchWrapper.typedPost(url, {
			body: JSON.stringify({
				channelName,
				channelType, // with projectId it has to be a PROJECT-* type channel, if not it will fail
				initialMembers, // array of userIds
				projectId, // if it's a PROJECT-* type channel this is required
				agentId,
			}),
			requireAuth: true,
		});
	}

	static async addChannelMember(channelId: number, userId: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channel/${channelId}/join/${userId}`;

		return FetchWrapper.put(url, {
			requireAuth: true,
		});
	}

	static async getUnreadInWorkspace(): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/unread`;

		return FetchWrapper.typedGet(url, {
			requireAuth: true,
		});
	}

	static async getUnreadInWorkspaceChildren(): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/unread/workspace`;

		return FetchWrapper.typedGet(url, {
			requireAuth: true,
		});
	}

	static async pinMessage(channelId: number, messageId: number): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channel/${channelId}/pin/${messageId}`;

		return FetchWrapper.put(url, {
			requireAuth: true,
		});
	}

	static async unpinMessage(channelId: number, messageId: number): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channel/${channelId}/pin/${messageId}`;

		return FetchWrapper.delete(url, {
			requireAuth: true,
		});
	}

	static async removeChannelMember(channelId: number, userId: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channel/${channelId}/join/${userId}`;

		return FetchWrapper.delete(url, {
			requireAuth: true,
		});
	}

	static async updateChannel(channel: any): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channel/${channel.id}`;

		return FetchWrapper.typedPost(url, {
			body: JSON.stringify({
				channel: channel,
			}),
			requireAuth: true,
		});
	}

	static async isChannelNameAllowed(channelName: any): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channel/allowed-name/${channelName}`;

		return FetchWrapper.typedGet(url, {
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<Chat[]>} chats
	 */
	static async getChannels(): Promise<FetchWrapperTypedResponse<ChannelDTO[]>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channels`;

		return FetchWrapper.typedGet(url, {
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperTypedResponse<ChannelDTO>>} chats
	 */
	static async getChannel(channelId: number): Promise<FetchWrapperTypedResponse<ChannelDTO>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channel/${channelId}`;
		return FetchWrapper.typedGet(url, {
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<Channel>} chats
	 */
	static async markChannelAsRead(channelId: number): Promise<FetchWrapperResponse> {
		if (!channelId) {
			throw new Error('Channel id missing - cant fetch channel with missing channelId');
		}

		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channel/${channelId}/read`;
		return FetchWrapper.typedPost(url, {
			requireAuth: true,
		});
	}

	/**
	 * @param  {String} channelId
	 * @param {Message} messageDTO
	 * @returns {Promise<Message[]>} messages
	 */
	static async sendMessage(channelId: number, messageDTO: UpsertMessageDTO): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/chat/channel/${channelId}/message`;

		return FetchWrapper.put(url, {
			body: JSON.stringify({
				message: messageDTO,
			}),
			requireAuth: true,
		});
	}

	/**
	 * @param  {String} channelId
	 * @param {Message} message
	 * @returns {Promise<Message[]>} messages
	 */
	static async updateMessage(channelId: number, message: Message): Promise<any> {
		try {
			const befareJWT = await AsyncStorageHelper.getCachedBefareJWT();
			const host = config.hosts.api;
			const url = `//${host}/v1/chat/channel/${channelId}/message`;

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${befareJWT}`,
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					message: message.toDTO(),
				}),
			});

			if (response.status !== 200) {
				throw new Error(`${response.status} for ${url}`);
			}

			return await response.json();
		} catch (error: any) {
			throw new Error(error);
		}
	}

	/**
	 * @param  {String} channelId
	 * @param {Message} message
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async deleteMessage(channelId: number, messageId: number): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channel/${channelId}/message/${messageId}`;

		return FetchWrapper.delete(url, {
			requireAuth: true,
		});
	}

	/**
	 * @param  {String} channelId
	 * @param {Message} message
	 * @param {String} reaction
	 * @returns {Promise<Message[]>} messages
	 */
	static async sendReaction(channelId: number, message: Message, reaction: string): Promise<any> {
		try {
			const befareJWT = await AsyncStorageHelper.getCachedBefareJWT();
			const host = config.hosts.api;
			const url = `//${host}/v1/chat/channel/${channelId}/message/${message.id}/reaction`;

			const response = await fetch(url, {
				method: 'PUT',
				headers: {
					Authorization: `Bearer ${befareJWT}`,
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					reaction: reaction,
				}),
			});

			if (response.status !== 200) {
				throw new Error(`${response.status} for ${url}`);
			}

			return await response.json();
		} catch (error: any) {
			throw new Error(error);
		}
	}

	/**
	 * @param  {String} channelId
	 * @param {Message} message
	 * @param {String} reaction
	 * @returns {Promise<Message[]>} messages
	 */
	static async deleteReaction(channelId: number, message: Message, reaction: string): Promise<any> {
		try {
			const befareJWT = await AsyncStorageHelper.getCachedBefareJWT();
			const host = config.hosts.api;
			const url = `//${host}/v1/chat/channel/${channelId}/message/${message.id}/reaction`;

			const response = await fetch(url, {
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${befareJWT}`,
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					reaction: reaction,
				}),
			});

			if (response.status !== 200) {
				throw new Error(`${response.status} for ${url}`);
			}

			return await response.json();
		} catch (error: any) {
			throw new Error(error);
		}
	}

	/**
	 * @param  {Number} channelId
	 * @returns {Promise<Message[]>} messages
	 */
	static async getMessages(channelId: number, skip: number = 0, limit: number = 100): Promise<Message[]> {
		try {
			const befareJWT = await AsyncStorageHelper.getCachedBefareJWT();
			const host = config.hosts.api;
			const url = `//${host}/v1/chat/channel/${channelId}/${skip}/${limit}`;

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${befareJWT}`,
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			});

			if (response.status !== 200) {
				throw new Error(`${response.status} for ${url}`);
			}

			const messages = await response.json();

			return messages as Message[];
		} catch (error: any) {
			throw new Error(error);
		}
	}
}

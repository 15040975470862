import { Box, Button, Link, Stack, styled, StyledComponentProps, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { RootStore } from '../../../stores/RootStore';
import { Helmet } from 'react-helmet';
import StoreContext from '../../../stores/StoreContext';
import { HookTypes } from '../../../utils/withHooks';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import FeatureSection, { type FeatureSectionProps } from './FeatureSection';

// Icons
import SecurityIcon from '@mui/icons-material/Security';
import InsightsIcon from '@mui/icons-material/Insights';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import Features from './Features';
import Pricing from './Pricing';
import Spotlight from './Spotlight';
import GradientText from './GradientText';
import { AppLogo } from '../../logo/AppLogo';
import FloatingOrbs from './FloatingOrbs';
import SmartDemo from './SmartDemo';
import { action, makeObservable, observable } from 'mobx';
import Pipeline from './Pipeline';

export const smartOrange = '#ff6347';

const features: FeatureSectionProps[] = [
	{
		icon: <SecurityIcon />, // Fra Material UI
		title: 'Trygghet',
		description: 'SmartChat følger de nyeste sikkerhetsstandardene, slik at kundedata alltid er beskyttet',
	},
	{
		icon: <InsightsIcon />,
		title: 'Kundeinnsikt',
		description: 'Avansert analyse gir deg innsikt i kundenes spørsmål og preferanser',
	},
	{
		icon: <SupportAgentIcon />,
		title: '24/7 Kundestøtte',
		description: 'En chatbot som aldri har en dårlig dag, og alltid er på jobb',
	},
	{
		icon: <DashboardIcon />,
		title: 'Dashboard',
		description: 'Få oversikt over kundetilfredshet og populære spørsmål i sanntid',
	},
	{
		icon: <AutoAwesomeIcon />,
		title: 'Automatisk Tilpasning',
		description: 'Innholdet tilpasses automatisk etter det brukeren ser',
	},
	{
		icon: <IntegrationInstructionsIcon />,
		title: 'Sømløs Integrasjon',
		description: 'Integrer med dine eksisterende systemer for en helhetlig kundeopplevelse',
	},
];

const Grid2Section = styled(Grid2)(({ theme }) => ({
	padding: theme.spacing(2),
	maxWidth: '1024px', // todo BEF-605 increase for larger screens
	paddingBottom: '3rem',
}));

type Props = HookTypes & StyledComponentProps;

const HomeSmart = observer(
	class HomeSmart extends React.Component<Props> {
		browserEmulatorUrl?: string;
		showTitle: boolean = true;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				browserEmulatorUrl: observable,
				showTitle: observable,
				onWebUrlSubmit: action,
			});
		}

		static readonly contextType = StoreContext;
		BefareChatId = '488FB28F-A1A1-4725-ACCF-29AF461445E6';
		get params() {
			return this.props.params!;
		}

		get history() {
			return this.props.navigate!;
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		componentDidMount() {
			try {
				if ((window as any).BefareChat) {
					(window as any).BefareChat.init(this.BefareChatId);
				}
			} catch (error) {
				console.log('BefareAiChat', error);
			}
		}

		componentWillUnmount(): void {
			try {
				if ((window as any).BefareChat) {
					(window as any).BefareChat.destroy();
				}
			} catch (error) {
				console.log('BefareAiChat', error);
			}
		}

		openChat = () => {
			try {
				if ((window as any).BefareChat) {
					(window as any).BefareChat.open();
				}
			} catch (error) {
				console.log('BefareAiChat', error);
			}
		};

		SendMessageAndOpen(message: string) {
			try {
				if ((window as any).BefareChat) {
					(window as any).BefareChat.sendMessageAndOpen(message);
				}
			} catch (error) {
				console.log('BefareAiChat', error);
			}
		}

		onWebUrlSubmit = (url: string, mode: 'desktop' | 'mobile') => {
			this.browserEmulatorUrl = url;
			this.showTitle = false;
		};

		trySmartChat = () => {
			this.SendMessageAndOpen('Hei, jeg er interessert i en demo av SmartChat');
		};

		aboutUs = () => {
			this.SendMessageAndOpen('Hei, jeg vil vite mer om Norwegian Blue AS');
		};

		press = () => {
			this.SendMessageAndOpen('Hei, jeg journalist og vil vite mer om selskapet, SmartChat og Sidekick AI');
		};

		contactUs = () => {
			this.SendMessageAndOpen('Hei, jeg vil legge igjen kontaktinformasjon for å komme i kontakt med dere');
		};

		render() {
			// const url = this.browserEmulatorUrl;

			return (
				<>
					<Helmet defer={false}>
						<title>{this.uiState.generateTitleString('SmartChat', undefined, false)}</title>
						<meta
							property="og:title"
							content={this.uiState.generateTitleString('SmartChat', undefined, false)}
						/>
						<meta
							property="og:description"
							content={this.uiState.generateDescriptionString(
								'Livechat og neste generasjons kundeservice gjort enkelt'
							)}
						/>
						<meta property="og:locale" content="no_NO" />
						<meta property="og:image:alt" content={'https://smartchat.no/icon-192x192.png'} />
						<meta
							property="og:image"
							content={'https://smartchat.no/icon-192x192.png/images/smartchat/SmartChat-Insight.png'}
						/>
					</Helmet>
					<Spotlight>
						<Grid2Section
							container
							xs
							direction="row"
							sx={(theme) => ({
								minHeight: '100dvh',
								justifyContent: 'center',
								paddingTop: theme.spacing(4),
								margin: 'auto',
							})}
						>
							<Grid2Section
								xs={12}
								justifyContent="center"
								alignItems="center"
								sx={{
									display: 'flex',
									minHeight: '100dvh',
									position: 'relative',
									// border: '1px dashed blue'
								}}
							>
								<FloatingOrbs numOrbs={5} />
								<Stack
									direction="column"
									spacing={{ xs: 6, md: 2 }}
									sx={{
										paddingTop: { md: '8rem' },
										width: '100%',
										zInedx: 2,
										// border: '1px dashed white'
									}}
								>
									{this.showTitle && (
										<Box>
											<GradientText variant="h1" sx={{ textAlign: 'center' }}>
												<AutoAwesomeIcon
													sx={(theme) => ({
														color: theme.palette.primary.contrastText,
													})}
												/>{' '}
												SmartChat
											</GradientText>
											<Typography
												variant="subtitle1"
												sx={{ textAlign: 'center' }}
												color="textPrimary"
											>
												Livechat og neste generasjons kundeservice gjort enkelt
											</Typography>
										</Box>
									)}
									{/* <Box sx={{ textAlign: 'center' }}>
										<Button variant="contained" color="primary" onClick={this.trySmartChat}>
											Prøv nå
										</Button>
									</Box> */}
									{/* <Box sx={{ height: '100px' }} /> */}
									<SmartDemo asComponent preventNavigation onWebUrlSubmit={this.onWebUrlSubmit} />
								</Stack>
							</Grid2Section>
							{/* <Grid2Section
								xs={12}
								justifyContent="center"
								alignItems="center"
								sx={{
									display: 'flex',
									padding: '2rem',
									position: 'relative',
								}}
							>

							</Grid2Section> */}
							{this.browserEmulatorUrl && (
								<Grid2Section
									xs={12}
									justifyContent="center"
									alignItems="center"
									sx={{
										display: 'flex',
										padding: '2rem',
										position: 'relative',
									}}
								>
									<Stack direction="column" spacing={2}>
										<Typography
											component="h2"
											variant="h4"
											gutterBottom
											sx={{
												color: 'text.primary',
											}}
										>
											Du bestemmer samtaleflyten
										</Typography>
										{/* <Typography variant="body1" sx={{ color: 'text.secondary', width: { sm: '100%', md: '60%' } }}>
											Du har full kontroll over chatten.
										</Typography> */}
										<Typography
											variant="body1"
											sx={{ color: 'text.secondary', width: { sm: '100%', md: '60%' } }}
										>
											Hvordan den svarer. Hva som skal snakkes om. Hvor dataen sendes til slutt.
											Alt er tilpasset dine behov.
										</Typography>
										<Box mt={(theme) => theme.spacing(12)}>
											<Pipeline
												pipeline={Pipeline.buildPipeline(
													this.browserEmulatorUrl ?? 'SmartChat Demo'
												)}
											/>
										</Box>
									</Stack>
								</Grid2Section>
							)}
							<Grid2Section
								xs={12}
								justifyContent="center"
								alignItems="center"
								direction="column"
								sx={{
									display: 'flex',
								}}
							></Grid2Section>
							<Grid2Section>
								<Features />
							</Grid2Section>
							<Grid2Section container xs={12} spacing={2} justifyContent="center" alignItems="flex-start">
								{features.map((feature) => (
									<Grid2 xs={12} md={4} key={feature.title}>
										<FeatureSection {...feature} />
									</Grid2>
								))}
							</Grid2Section>

							<Grid2Section
								sx={{
									pt: { xs: 4, sm: 12 },
									pb: { xs: 8, sm: 16 },
								}}
							>
								<Pricing />
							</Grid2Section>

							<Grid2Section
								sx={{
									pt: { xs: 4, sm: 12 },
									pb: { xs: 8, sm: 16 },
								}}
							>
								<Stack direction="column" spacing={2}>
									<Typography
										component="h2"
										variant="h4"
										gutterBottom
										sx={{ color: 'text.primary', textAlign: 'center' }}
									>
										Alt-i-ett Chatteplattform
									</Typography>
									<Typography variant="body1" sx={{ color: 'text.secondary', textAlign: 'center' }}>
										En komplett løsning med chatbots, RAG, kunnskapsbase, samtaleanalyse og mye mer
									</Typography>
									<Box
										sx={(theme) => ({
											textAlign: 'center',
											width: '100%',
											borderRadius: `${theme.shape.borderRadius}px`,
											aspectRatio: '16 / 9',
											// background: theme.palette.primary.main,
											// boxShadow: `0 0px 24px ${alpha(theme.palette.primary.light, 0.4)}`,
											backgroundImage: 'url(/images/smartchat/SmartChat-Insight.png)',
											backgroundSize: 'cover',
										})}
									/>
								</Stack>
							</Grid2Section>

							<Grid2
								container
								xs={12}
								justifyContent="center"
								spacing={3}
								alignItems="center"
								sx={(theme) => ({
									borderTop: `1px solid ${theme.palette.divider}`,
									background: theme.palette.background.default,
								})}
							>
								<Grid2Section container xs={12}>
									<Grid2 container xs={12}>
										<Grid2 xs={12} md={8}>
											<Stack direction="column" spacing={2}>
												<AppLogo darkMode={this.uiState.isDarkMode} height="40px" />
												<Typography
													variant="body2"
													color="textPrimary"
													sx={{ fontWeight: 'bold' }}
												>
													Prøv fremtidens chatbot
												</Typography>
												<Typography variant="body2" color="textPrimary">
													Ikke bli stående på sidelinjen. Ta kontroll over kundeservicen din
													med SmartChat.
												</Typography>
												<Box>
													<Button
														variant="outlined"
														color="primary"
														onClick={this.trySmartChat}
													>
														Prøv nå
													</Button>
												</Box>
											</Stack>
										</Grid2>
										<Grid2 xs={12} md={4}>
											<Stack direction="column" spacing={1}>
												<Typography variant="overline" color="textPrimary">
													Selskap
												</Typography>
												<Link color="textPrimary" onClick={this.aboutUs}>
													Om oss
												</Link>
												<Link color="textPrimary" onClick={this.press}>
													Presse
												</Link>
												<Link color="textPrimary" onClick={this.contactUs}>
													Kontakt oss
												</Link>
											</Stack>
										</Grid2>
									</Grid2>
								</Grid2Section>
								<Grid2Section
									xs={12}
									container
									sx={(theme) => ({
										borderTop: `1px solid ${theme.palette.divider}`,
									})}
								>
									<Grid2 xs={12}>
										<Stack direction="column" spacing={1}>
											<Typography variant="body2" color="textPrimary">
												© {new Date().getFullYear()} Norwegian Blue AS
											</Typography>
											<Typography variant="body2" color="textPrimary">
												Org.nr. 914 209 250
											</Typography>
										</Stack>
									</Grid2>
								</Grid2Section>
							</Grid2>
						</Grid2Section>
					</Spotlight>
				</>
			);
		}
	}
);

export default HomeSmart;

import { Stack } from '@mui/material';
import GradientText from '../home/smart/GradientText';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { AppLogoProps } from './AppLogo';


// biome-ignore lint/correctness/noUnusedVariables: <explanation>
const SmartChatLogo = ({ width = '100%', height = '100%', darkMode = false, plainColors, disableBanner }: AppLogoProps) => {
	if (plainColors) {
		const darkModeImageGroup = {
			banner: '/logo/smartchat-white-banner-512.png',
			logo: '/logo/smartchat-white-512.png'
		}

		const lightModeImageGroup = {
			banner: '/logo/smartchat-black-banner-512.png',
			logo: '/logo/smartchat-black-512.png'
		}

		const imageGroup = darkMode ? darkModeImageGroup : lightModeImageGroup;
		const src = disableBanner ? imageGroup.logo : imageGroup.banner;

		return <img src={src} alt="logo" width={width} height={height} />;

	}

	return (
		<Stack
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			spacing={1}
			sx={{ textDecoration: 'none !important' }}
		>
			<AutoAwesomeIcon
				sx={(theme) => ({
					color: theme.palette.primary.contrastText,
					fontSize: '2rem',
				})}
			/>
			<GradientText
				variant="h6"
				sx={{
					textAlign: 'center',
					textDecoration: 'none !important',
					verticalAlign: 'middle',
					fontSize: '1.5rem',
				}}
			>
				SmartChat
			</GradientText>
		</Stack>
	);
};

export default SmartChatLogo;

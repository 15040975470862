import { RootStore } from './RootStore';
import VideoChat from '../api/endpoints/VideoChat';
import { makeAutoObservable, runInAction } from 'mobx';
import { Trigger, TriggerType } from '../Trigger.types';
import { SoundEffect } from './UiState';
import config from '../config/config';
import LogUtil from '../helpers/LogUtil';
import { VideoChatArchiveDTO } from '../dto/videoChat.types';

type VideoChatCredentials = {
	API_KEY: string;
	SESSION_ID: string;
	TOKEN: string;
};

const dummyCredentials: VideoChatCredentials = {
	API_KEY: config.tokbox.apiKey,
	SESSION_ID: '',
	TOKEN: '',
};

/**
 * Video chat store
 */
export class VideoChatStore {
	rootStore: RootStore;
	credentials: VideoChatCredentials;
	sessions: VideoChatSession[] = [];
	activeCall: any = null;
	incomingCall: any = null;
	isLoadingSessions: boolean = false;
	lastSessionLoadTime: number = 0;

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, {
			rootStore: false,
		});
		this.rootStore = rootStore;
		this.credentials = dummyCredentials;

		this.processTrigger = this.processTrigger.bind(this);
		this.updateSessionFromServer = this.updateSessionFromServer.bind(this);

		this.init();
	}

	init() {
		this.rootStore.userStore.UserIdChanged.on(this.loadSessions);
		// Something might happen when the page is in the backgrond
		// Some browsers suspend websocket connections
		// We need to make sure we have the data
		this.rootStore.Online.on(this.loadSessions);
		this.rootStore.PageVisible.on(this.loadSessions);

		this.rootStore.userStore.SignedOut.on(() => {
			runInAction(() => {
				this.sessions = [];
				console.log('Sessions reset');
			});
		});
	}

	loadSessions = () => {
		if (this.isLoadingSessions) {
			return;
		}
		if (this.lastSessionLoadTime > Date.now() - 10000) {
			return;
		}

		this.isLoadingSessions = true;
		this.lastSessionLoadTime = Date.now();
		VideoChat.getSessions(50, 0).then((response) => {
			if (response.statusCode === 200) {
				const sessions = response.data;
				sessions.forEach(this.updateSessionFromServer);
			}
			runInAction(() => {
				this.isLoadingSessions = false;
			});
		});
	};

	async processTrigger(trigger: Trigger<any>) {
		try {
			switch (trigger.urn) {
				case TriggerType.VIDEO_CHAT_STARTED:
					// should check that trigger.event.data.userId is not the current user
					this.setIncomingCall(trigger.event?.data);
					break;
				case TriggerType.VIDEO_CHAT_ARCHIVE_STARTED:
					LogUtil.info(`VideoChatStore: Archive started`, trigger.event.data);
					this.getSession(trigger.event.data.channelId, trigger.event.data.sessionId, true);
					break;
				case TriggerType.VIDEO_CHAT_ARCHIVE_ENDED:
					LogUtil.info(`VideoChatStore: Archive ended`, trigger.event.data);
					this.getSession(trigger.event.data.channelId, trigger.event.data.sessionId, true);
					break;
				case TriggerType.VIDEO_CHAT_ARCHIVE_STORED:
					LogUtil.info(
						`VideoChatStore: Archive stored, should get notified about this with a message`,
						trigger.event.data
					);
					this.getSession(trigger.event.data.channelId, trigger.event.data.sessionId, true);
					break;
				case TriggerType.VIDEO_CHAT_ENDED:
					LogUtil.info(`VideoChatStore: Video chat ended`, trigger.event.data);
					// @todo update video call widget
					this.getSession(trigger.event.data.channelId, trigger.event.data.sessionId, true);
					break;
				case TriggerType.VIDEO_CHAT_JOIN:
					LogUtil.info(`VideoChatStore: Somone got a token to this chat`, trigger.event.data);
					this.getSession(trigger.event.data.channelId, trigger.event.data.sessionId, true);
					break;
				default:
					LogUtil.info(`VideoChatStore: unknown trigger`, trigger.event.data);
			}
		} catch (err) {
			LogUtil.error(err);
		}
	}

	findToken = (sessionId: string) => {
		const session = this.sessions.find((s) => s.sessionId === sessionId);
		if (!session) {
			return null;
		}

		const currentUser = session.participants?.filter(
			(p: any) => p.userId === this.rootStore.userStore.currentUserId
		);
		if (currentUser && currentUser.length > 0 && currentUser[0].token) {
			runInAction(() => {
				this.updateOrAddSession(session.sessionId, {
					...session,
					userToken: currentUser[0].token,
				});
			});
		}

		return session?.userToken;
	};

	/**
	 * @param  {String} sessionId
	 */
	getToken = async (channelId: string, sessionId: string, createIfMissing: boolean = true) => {
		const session = this.sessions.find((s) => s.sessionId === sessionId);
		if (!session) {
			return null;
		}

		const token = this.findToken(sessionId);

		if (token) {
			return token;
		}

		if (!createIfMissing) {
			return null;
		}

		const response = await VideoChat.getToken(channelId, sessionId);
		if (response.statusCode === 200) {
			const tokenJson = response.data;
			runInAction(() => {
				this.updateOrAddSession(session.sessionId, {
					...session,
					userToken: tokenJson.token,
				});
			});

			return tokenJson.token;
		}

		return null;
	};

	findSession(sessionId: string) {
		return this.sessions.find((s) => s.sessionId === sessionId);
	}

	/**
	 * @param  {String} channelId
	 * @param  {String} sessionId
	 */
	getSession = async (channelId: number, sessionId: string, forceReload: boolean = false) => {
		const session = this.findSession(sessionId);
		if (session && !forceReload) {
			return session;
		}

		const response = await VideoChat.getSession(channelId, sessionId);

		if (response.statusCode === 200) {
			const sessionJson = response.data;
			if (sessionJson?.sessionId) {
				runInAction(() => {
					this.updateSessionFromServer(sessionJson);
				});

				return this.findSession(sessionJson.sessionId);
			}
		}

		return null;
	};

	/**
	 * @param  {any} call?
	 */
	async startCall(call?: any) {
		if (!call.channelId) {
			return;
		}

		const response = await VideoChat.createSession(call.channelId, call.notifyUserIds);

		if (response.statusCode === 200) {
			this.updateSessionFromServer(response.data);
			const session = await this.getSession(call.channelId, response.data.sessionId);
			if (session) {
				runInAction(() => {
					this.setActiveCall(session);
				});
			}

			return session;
		}

		return null;
	}

	setActiveCall(call: any) {
		console.log(`Set active call`, call);
		runInAction(() => {
			this.activeCall = call;
			// We don't want both active at the same time
			this.setIncomingCall(null);
		});
	}

	endIncomingCall = (call: any) => {
		// If we are showing the calling UI for this call - remove it since the call ended
		console.log(`End call?`, this.incomingCall, call);
		try {
			if (this.incomingCall?.sessionId === call?.sessionId) {
				this.declineIncomingCall();
			}
		} catch (err) {
			LogUtil.error('VideoChatStore:endIncomingCall', err);
		}
	};

	setIncomingCall = (call: any, force: boolean = false) => {
		runInAction(() => {
			// check if profile is a part of this channel with this sign in
			// this should be done on backend, but this is a fallback
			// not security related, but it's a ux issue to mix calls from different workspaces
			console.log('Incoming call', call);
			if (call?.channelId) {
				const channel = this.rootStore.chatStore.findChannel(call.channelId, false);

				if (!channel) {
					LogUtil.info(
						'User does not have the origin channel for the call available. Ignoring incoming call.'
					);
					return;
				}
			}

			// no point in letting user get a call nottification from her/him self
			if (!force && '' + call?.userId === '' + this.rootStore.profileStore.currentUserProfile?.userId) {
				console.log('User called user, ignoring incoming call');
				return;
			}

			this.incomingCall = call;
			if (call) {
				this.rootStore.uiState.playSound(SoundEffect.VIDEO_CALL);
			} else {
				this.rootStore.uiState.stopSound(SoundEffect.VIDEO_CALL);
			}
		});
	};

	declineIncomingCall = () => {
		runInAction(() => {
			this.incomingCall = null;
			this.rootStore.uiState.stopSound(SoundEffect.VIDEO_CALL);
		});
	};

	/**
	 * Start archiving session
	 * @param channelId
	 * @param sessionId
	 */
	async startArchive(channelId: string, sessionId: string) {
		try {
			const session = this.sessions.find((s) => s.sessionId === sessionId);
			if (session) {
				const response = await VideoChat.startArchive(channelId, sessionId);
				if (response.statusCode === 200) {
					const recordingStatus = response.data;
					LogUtil.info(`STARTED ARCHIVE IN ${channelId} for ${sessionId}`, recordingStatus);
					runInAction(() => {
						session.recording = true;
						session.archive = recordingStatus.archive;
					});
				}
			}

			return session?.recording;
		} catch (e) {
			LogUtil.error(e);
			return null;
		}
	}

	/**
	 * Stop archiving session
	 * @param channelId
	 * @param sessionId
	 */
	async stopArchive(channelId: string, sessionId: string) {
		try {
			const session = this.sessions.find((s) => s.sessionId === sessionId);
			if (session?.archive?.id) {
				console.log('Stop archive for ', session);
				const response = await VideoChat.stopArchive(channelId, sessionId, session.archive.id);
				if (response.statusCode === 200) {
					const recordingStatus = response.data;
					LogUtil.info(`STOPPED ARCHIVE IN ${channelId} for ${sessionId}`, recordingStatus);
					runInAction(() => {
						session.recording = false;
						session.archive = undefined;
					});
				}
			}

			return session?.recording;
		} catch (e) {
			LogUtil.error('VideoChatStore:stopArchive', e);
			return null;
		}
	}

	addSession = (session: any) => {
		// @todo: refactor & remove, just use updateSessionFromServer directly
		this.updateSessionFromServer(session);
	};

	updateOrAddSession = (sessionId: string, data: any) => {
		runInAction(() => {
			this.updateSessionFromServer(data);
		});
	};

	updateSessionFromServer(json: any) {
		runInAction(() => {
			let session = this.sessions.find(
				(session: any) => session.id === '' + json.id || session.sessionId === json.sessionId
			);

			if (!session) {
				session = new VideoChatSession(json.id);
				this.sessions.push(session);
			}
			if (session) {
				session.updateFromJson(json);
			}
		});
	}

	getActiveCallsInChannels = (channelIds: string[]) => {
		return (
			this.sessions.filter(
				(session) =>
					session.hasStarted && !session.hasEnded && channelIds.find((id) => '' + id === session.channelId)
			) ?? []
		);
	};

	leaveCall(call?: any) {
		const event = {
			...call,
			type: 'leave-call',
		};

		this.rootStore.emitEvent('video-chat', event);
	}

	async endCall(call?: any) {
		const event = {
			...call,
			type: 'end-call',
		};

		console.log('SEND END CALL EVENT\n', event);
		try {
			if (call.channelId && call.sessionId) {
				await VideoChat.endSession(call.channelId, call.sessionId);
			}
		} catch (e) {
			// do nothing
		}

		this.setActiveCall(null);

		this.rootStore.emitEvent('video-chat', event);
	}
}

/**
 * VideoChatSession
 * Domain object
 */
export class VideoChatSession {
	id: string;
	workspaceId: string = '';
	created!: Date;
	updated: Date | null = null;
	deleted: Date | null = null;
	start: Date | null = null;
	ended: Date | null = null;
	sessionId: string = '';
	archiveMode: string | undefined;
	channelId: string | undefined;
	mediaMode: string | undefined;
	name: string | undefined;
	participants: any[] = []; // @todo add type
	userId: string | undefined;
	ownerId: string = '';
	userToken: string | undefined;
	recording: boolean = false;
	archive: VideoChatArchiveDTO | undefined;

	constructor(id?: number) {
		makeAutoObservable(this, {
			id: false,
		});

		this.id = '' + id;
	}

	get asJson() {
		return {
			id: this.id,
			sessionId: this.sessionId,
			ownerId: this.ownerId,
			workspaceId: this.workspaceId,
			created: this.created,
			updated: this.updated,
			deleted: this.deleted,
		};
	}

	get formattedCreatedDate() {
		return this.created.toLocaleDateString();
	}

	get hasStarted() {
		if (this.start) {
			const d = new Date();
			return d.getTime() > this.start.getTime();
		}

		return false;
	}

	get hasEnded() {
		if (this.ended) {
			return true;
		}

		if (this.start && this.start.getTime() + 3600 * 1000 > Date.now()) {
			return false;
		}

		return true;
	}

	setUserToken(token?: string) {
		if (token) {
			this.userToken = token;
		}
	}

	/**
	 * @param  {any} json
	 */
	updateFromJson(json: any) {
		this.id = '' + json.id || this.id;
		this.ownerId = json.ownerId;
		this.workspaceId = json.workspaceId;
		this.created = json.created ? new Date(json.created) : new Date();
		this.updated = json.updated ? new Date(json.updated) : null;
		this.deleted = json.deleted ? new Date(json.deleted) : null;
		this.ended = json.ended ? new Date(json.ended) : null;
		this.start = json.start ? new Date(json.start) : this.created;
		this.sessionId = json.sessionId;
		this.archiveMode = json.archiveMode;
		this.channelId = '' + json.channelId;
		this.mediaMode = json.mediaMode;
		this.name = json.name;
		this.participants = json.participants;
		this.userId = '' + json.userId;
		this.ownerId = '' + json.ownerId;
		this.archive = json.archive;
		if (this.archiveMode === 'always') {
			this.recording = true;
		}
		this.setUserToken(json.userToken);
	}
}

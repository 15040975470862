import {
	AppBar,
	Button,
	Dialog,
	DialogContent,
	DialogProps,
	IconButton,
	Slide,
	Toolbar,
	Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import AlertDialog from './AlertDialog';
import './FullScreenDialog.scss';

type Props = DialogProps & {
	title: string;
	confirmingAction?: {
		title: string;
		action: () => void;
		variant?: 'text' | 'outlined' | 'contained' | undefined;
		color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
	};
	onClose: () => void;
	hasChanges?: boolean; // TODO Should not be optional
	transitionLeft?: boolean;
	noPadding?: boolean;
};

const TransitionUp = React.forwardRef(function Transition(
	props: TransitionProps & { children: any },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} in={true} {...props} />;
});

const TransitionLeft = React.forwardRef(function Transition(
	props: TransitionProps & { children: any },
	ref: React.Ref<unknown>
) {
	return <Slide direction="left" ref={ref} in={true} {...props} />;
});

const FullScreenDialog = observer(
	class FullScreenDialog extends React.Component<Props> {
		static readonly contextType = StoreContext;
		isAlertDialogOpen: boolean = false;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				isAlertDialogOpen: observable,
				handleClose: action,
				closeAlertDialog: action,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			if (this.props.confirmingAction?.action) {
				this.props.confirmingAction.action();
			}
		};

		handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			event.preventDefault();
			event.stopPropagation();
			if (this.props.hasChanges) {
				this.isAlertDialogOpen = true;
			} else {
				this.props.onClose();
			}
		};

		discardChanges = () => {
			this.closeAlertDialog();
			this.props.onClose();
		};

		closeAlertDialog = () => {
			this.isAlertDialogOpen = false;
		};

		render() {
			const { className, confirmingAction, title, children, noPadding, transitionLeft, maxWidth, ...rest } =
				this.props;
			const { isMobile } = this.uiState;
			return (
				<>
					<Dialog
						TransitionComponent={transitionLeft ? TransitionLeft : TransitionUp}
						maxWidth={maxWidth ?? 'xl'}
						fullScreen={isMobile}
						fullWidth
						{...rest}
						className={`FullScreenDialog ${className} ${isMobile ? 'isMobile' : ''}`}
					>
						<form
							onSubmit={this.handleSubmit}
							style={{ maxHeight: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}
						>
							<AppBar position={isMobile ? 'fixed' : 'relative'}>
								<Toolbar>
									<IconButton
										color="inherit"
										edge="start"
										onClick={this.handleClose}
										aria-label="close"
									>
										{transitionLeft ? <ArrowBackIcon /> : <CloseIcon />}
									</IconButton>
									<Typography variant="h6" className="title">
										{title}
									</Typography>
									{confirmingAction && (
										<Button
											autoFocus
											color={confirmingAction.color ?? 'inherit'}
											variant={confirmingAction.variant ?? 'outlined'}
											type="submit"
										>
											{confirmingAction.title}
										</Button>
									)}
								</Toolbar>
							</AppBar>
							<DialogContent
								sx={{
									padding: noPadding ? '0' : '1rem',
									overflowY: 'auto',
									marginTop: isMobile ? 'var(--top-app-bar-height)' : '',
									height: isMobile ? 'calc(100vh - var(--top-app-bar-height))' : 'auto',
									maxHeight: 'calc(100dvh - var(--top-app-bar-height))',
								}}
							>
								{children}
							</DialogContent>
						</form>
					</Dialog>

					<AlertDialog
						confirmingAction={{ title: 'Forkast', action: this.discardChanges }}
						onClose={this.closeAlertDialog}
						open={this.isAlertDialogOpen}
						supportingText="Forkast endringer?"
					/>
				</>
			);
		}
	}
);

export default FullScreenDialog;

import { Button, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import CustomerSupportChat from './CustomerSupportChat';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ChatHeader = styled(Grid2)(({ theme }) => ({
	position: 'fixed',
	top: 'var(--top-app-bar-height)',
	left: 0,
	right: 0,
	padding: theme.spacing(1),
	zIndex: 2,
	background: theme.palette.primary.main,
	color: theme.palette.primary.contrastText,
	boxShadow: theme.shadows[2],
}));

export default class CustomerSupportChatScreen extends React.Component {
	render() {
		return (
			<Stack
				direction="column"
				spacing={2}
				sx={{
					display: 'flex',
					position: 'relative',
					flexDirection: 'column',
					height: 'calc(100vh - var(--top-app-bar-height))',
				}}
			>
				<ChatHeader>
					<Button
						startIcon={<ArrowBackIcon />}
						variant="text"
						color="inherit"
						component={Link}
						to="/elektriker"
						sx={{ marginRight: '1rem' }}
					>
						Tilbake
					</Button>
					<Typography variant="overline" gutterBottom>
						Samtale med kundeservice
					</Typography>
				</ChatHeader>
				<CustomerSupportChat />
			</Stack>
		);
	}
}

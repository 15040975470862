import PersonAdd from '@mui/icons-material/PersonAdd';
import StoreIcon from '@mui/icons-material/Store';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { Box, IconButton, Menu, MenuItem, Divider, ListItemIcon, Tooltip, Stack, Typography } from '@mui/material';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import ProfilePicture from '../common/ProfilePicture';
import StoreContext from '../../stores/StoreContext';
import { RootStore } from '../../stores/RootStore';
import { Link } from 'react-router-dom';
import AsyncStorageHelper from '../../auth/AsyncStorageHelper';
import { DarkModeRounded, LightModeRounded } from '@mui/icons-material';

type Props = {};

const AccountMenu = observer(
	class AccountMenu extends React.Component<Props> {
		static readonly contextType = StoreContext;
		anchorEl: null | HTMLElement = null;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				anchorEl: observable,
				handleClose: action,
				handleOpen: action,
				toggleDarkMode: action,
				rootStore: computed,
				profileStore: computed,
				workspaceStore: computed,
				companyStore: computed,
				company: computed,
				userStore: computed,
				open: computed,
			});
		}

		componentWillUnmount(): void {
			this.handleClose();
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get workspaceStore() {
			return this.rootStore.workspaceStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get companyStore() {
			return this.rootStore.companyStore;
		}

		get open() {
			return Boolean(this.anchorEl);
		}

		get company() {
			if (!this.workspaceStore?.workspace?.id) {
				return undefined;
			}

			return this.companyStore.findCompanyByWorkspaceId(this.workspaceStore.workspace.id);
		}

		get version() {
			const appVersion = document.querySelector('meta[build-version]');
			if (appVersion?.getAttribute('build-version')) {
				return appVersion.getAttribute('build-version');
			}
			return 'utdatert';
		}

		handleClose = () => {
			this.anchorEl = null;
		};

		handleOpen = (e: React.MouseEvent<HTMLElement>) => {
			this.anchorEl = e.currentTarget;
		};

		toggleDarkMode = () => {
			this.uiState.isDarkMode = !this.uiState.isDarkMode;
			this.handleClose();
		};

		render() {
			const { currentUserProfile } = this.profileStore;
			const { hasAdminAccess } = this.workspaceStore;
			const { logOut } = this.userStore;
			const workspaces = AsyncStorageHelper.getCache(`@BefWeb:login:workspaces`);

			return (
				<>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							textAlign: 'center',
							ml: '0.5rem',
							borderLeft: '1px solid #ccc',
							paddingLeft: '0.5rem',
						}}
					>
						<Stack direction="row">
							<Stack
								direction="column"
								justifyContent="center"
								sx={{ textAlign: 'right', display: { xs: 'none', sm: 'flex' } }}
							>
								<Typography data-hj-suppress variant="body2" sx={{ fontSize: '0.7rem' }}>
									{currentUserProfile?.name}
								</Typography>
								<Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
									{this.company?.name}
								</Typography>
							</Stack>
							<Tooltip title="Innstillinger">
								<IconButton
									onClick={this.handleOpen}
									size="small"
									sx={{ ml: 1 }}
									// aria-controls={this.open ? 'account-menu' : undefined}
									// aria-haspopup="true"
									// aria-expanded={this.open ? 'true' : undefined}
								>
									<ProfilePicture
										noTooltip
										sx={{ width: 32, height: 32 }}
										profile={currentUserProfile}
									/>
								</IconButton>
							</Tooltip>
						</Stack>
					</Box>
					<Menu
						anchorEl={this.anchorEl}
						id="account-menu"
						open={this.open}
						onClose={this.handleClose}
						PaperProps={{
							elevation: 0,
							sx: {
								overflow: 'visible',
								filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
								mt: 1.5,
								'& .MuiAvatar-root': {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								'&:before': {
									content: '""',
									display: 'block',
									position: 'absolute',
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: 'background.paper',
									transform: 'translateY(-50%) rotate(45deg)',
									zIndex: 0,
								},
							},
						}}
						transformOrigin={{ horizontal: 'right', vertical: 'top' }}
						anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					>
						<MenuItem onClick={this.handleClose} component={Link} to={'/app/profile'}>
							<ProfilePicture sx={{ width: 32, height: 32 }} profile={currentUserProfile} /> Min profil
						</MenuItem>
						{hasAdminAccess && [
							<Divider key="divider1" />,
							<MenuItem
								onClick={this.handleClose}
								key="admin-link"
								component={Link}
								to={'/app/pro/admin'}
							>
								<ListItemIcon>
									<AdminPanelSettingsIcon fontSize="small" />
								</ListItemIcon>
								Admin
							</MenuItem>,
							<MenuItem
								onClick={this.handleClose}
								key="firma"
								component={Link}
								to={'/app/pro/admin/company'}
							>
								<ListItemIcon>
									<StoreIcon fontSize="small" />
								</ListItemIcon>
								Kontaktinformasjon
							</MenuItem>,
							<MenuItem
								onClick={this.handleClose}
								key="admin-link"
								component={Link}
								to={'/app/pro/admin/settings'}
							>
								<ListItemIcon>
									<Settings fontSize="small" />
								</ListItemIcon>
								Innstillinger
							</MenuItem>,
							<MenuItem
								onClick={this.handleClose}
								key="add-user"
								component={Link}
								to={'/app/pro/admin/company/members'}
							>
								<ListItemIcon>
									<PersonAdd fontSize="small" />
								</ListItemIcon>
								Ansatte
							</MenuItem>,
						]}
						<Divider />

						{workspaces?.length > 0 && (
							<MenuItem
								key="change-workspace"
								onClick={this.handleClose}
								component={Link}
								to={'/sign-in/workspace'}
							>
								<ListItemIcon>
									<ChangeCircleIcon fontSize="small" />
								</ListItemIcon>
								Bytt workspace
							</MenuItem>
						)}
						<MenuItem onClick={this.toggleDarkMode}>
							<ListItemIcon>
								{this.uiState.isDarkMode ? (
									<LightModeRounded fontSize="small" />
								) : (
									<DarkModeRounded fontSize="small" />
								)}
							</ListItemIcon>
							{this.uiState.isDarkMode ? 'Lys modus' : 'Mørk modus'}
						</MenuItem>
						<MenuItem onClick={logOut}>
							<ListItemIcon>
								<Logout fontSize="small" />
							</ListItemIcon>
							Logg ut
						</MenuItem>
						<Divider />
						<Typography
							variant="overline"
							sx={{
								textAlign: 'center',
								display: 'block',
								fontSize: '0.6rem',
							}}
						>
							Versjon {this.version}
						</Typography>
					</Menu>
				</>
			);
		}
	}
);

export default React.memo(AccountMenu);

const features = {
	demo: false,
	'companies.industries': false,
	'ai.chat': true,
	'ai.email': 'alpha',
	'event.invite': true,
	'video.recorder': false,
	'create.workspace': 'alpha',
	'befare.next': 'alpha',
	'sms.custom': true,
	'jobs.map': true,
	'project.checklist': true,
	'checklist.create': true,
	'project.checklist.execution.add': true,
	insight: true,
	customers: true,
	'project.create': true,
};

export default features;

export enum ChannelTypeDTO {
	// eslint-disable-next-line no-unused-vars
	ProjectInternal = 'PROJECT-INTERNAL',
	// eslint-disable-next-line no-unused-vars
	ProjectExternal = 'PROJECT-EXTERNAL',
	// eslint-disable-next-line no-unused-vars
	ProjectCustomer = 'PROJECT-CUSTOMER',
	// eslint-disable-next-line no-unused-vars
	Public = 'PUBLIC', // anyone in workspace can join
	// eslint-disable-next-line no-unused-vars
	Private = 'PRIVATE', // set up members when you create the channel - can't change members after
	// eslint-disable-next-line no-unused-vars
	Restricted = 'RESTRICTED', // aka join by invite only
	// eslint-disable-next-line no-unused-vars
	CustomerService = 'CUSTOMER-SERVICE', // aka join by invite only
	// eslint-disable-next-line no-unused-vars
	Assistant = 'ASSISTANT', // aka join by invite only
	// eslint-disable-next-line no-unused-vars
	Agent = 'AGENT', // Conversational AI
	// eslint-disable-next-line no-unused-vars
	Partner = 'PARTNER', // Partner workspace
}

export type ChannelDTO = {
	id: number;
	channelType: ChannelTypeDTO;
	workspaceId: string;
	topic?: string;
	description?: string;
};

export enum DynamicChannelViewType {
	// eslint-disable-next-line no-unused-vars
	ProjectList = 'project.list',
	// eslint-disable-next-line no-unused-vars
	TicketList = 'ticket.list',
	// eslint-disable-next-line no-unused-vars
	CustomerList = 'customer.list',
	// eslint-disable-next-line no-unused-vars
	AgentList = 'agent.list',
	// eslint-disable-next-line no-unused-vars
	ProjectDetails = 'project.details',
	// eslint-disable-next-line no-unused-vars
	TicketDetails = 'ticket.details',
	// eslint-disable-next-line no-unused-vars
	CustomerDetails = 'customer.details',
	// eslint-disable-next-line no-unused-vars
	AgentDetails = 'agent.details',
	// eslint-disable-next-line no-unused-vars
	NotesList = 'notes.list',
	// eslint-disable-next-line no-unused-vars
	NotesDetail = 'notes.detail',
	// eslint-disable-next-line no-unused-vars
	Calendar = 'calendar',
	// eslint-disable-next-line no-unused-vars
	ColleagueList = 'colleague.list',
}

export type DynamicChannelView = {
	channelId: number;
	userId: number;
	view: DynamicChannelViewType;
	params: Record<string, string>;
};

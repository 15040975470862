import { Button, Card, CardContent, Typography, Unstable_Grid2 as Grid2 } from '@mui/material';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import AsyncStorageHelper, { CookieConsentType } from '../../auth/AsyncStorageHelper';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import './CookieConsent.scss';
import { HookTypes, withLocation } from '../../utils/withHooks';

type Props = Pick<HookTypes, 'location'>;

const CookieConsent = observer(
	class CookieConsent extends React.Component<Props> {
		static readonly contextType = StoreContext;
		loading: boolean = true;

		get location() {
			return this.props.location!;
		}

		hasConsentedToCookies: CookieConsentType | undefined;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				hasConsentedToCookies: observable,
				loading: observable,
				location: computed,
				isQuote: computed,
				handleDismiss: action,
				init: action,
			});
		}

		get isQuote() {
			return this.location.pathname.startsWith('/quote');
		}

		componentDidMount() {
			this.init();
		}

		async init() {
			const hasConsentedToCookies = await AsyncStorageHelper.getCachedCookieConsent();
			runInAction(() => {
				this.hasConsentedToCookies = hasConsentedToCookies;
				this.loading = false;
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		// allow all
		handleDismiss = async () => {
			AsyncStorageHelper.cacheCookieConsent({
				allowRequired: true,
				allowAnalytics: true,
				allowMarketing: true,
			});
			this.rootStore.initAnalytics();
			this.rootStore.initMarketing();

			this.hasConsentedToCookies = await AsyncStorageHelper.getCachedCookieConsent();
		};

		render() {
			if (this.hasConsentedToCookies?.allowRequired || this.loading) {
				return null;
			}

			let className = 'CookieConsent';
			// check if route begins with /quote
			if (this.isQuote) {
				className += ' CookieConsent--quote';
			}

			return (
				<Card className={className} elevation={8}>
					<CardContent>
						<Grid2 container sx={{ maxWidth: '100%' }}>
							<Grid2 sm={12} md justifyContent="center">
								<Typography
									sx={{
										color: '#212121',
									}}
									variant="body2"
								>
									Denne tjenesten bruker egne og tredjeparts informasjonskapsler for at vi skal kunne
									tilby deg en god opplevelse.
								</Typography>
							</Grid2>
							<Grid2 sm={12} md="auto" sx={{ sm: { paddingTop: '1rem' } }}>
								<Button color="primary" component={Link} to="/cookie-declaration">
									Les mer
								</Button>
								<Button variant="contained" color="success" onClick={this.handleDismiss}>
									Godta alle
								</Button>
							</Grid2>
						</Grid2>
					</CardContent>
				</Card>
			);
		}
	}
);

export default withLocation(CookieConsent);

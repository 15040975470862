import { AppId } from '../../config/appId.enum';
import config from '../../config/config';
import BefareLogo from './BefareLogo';
import MinElektrikerLogo from './MinElektrikerLogo';
import SmartChatLogo from './SmartChatLogo';

export interface AppLogoProps {
	width?: string;
	height?: string;
	darkMode?: boolean;
	plainColors?: boolean;
	disableBanner?: boolean;
}

export const AppLogo = ({ width = '100%', height = '100%', darkMode = false, plainColors = true, disableBanner = false }: AppLogoProps) => {

	switch (config.appId) {
		case AppId.Smart:
			return <SmartChatLogo width={width} height={height} darkMode={darkMode} plainColors={plainColors} disableBanner={disableBanner} />;
		case AppId.Befare:
			return <BefareLogo width={width} height={height} darkMode={darkMode} />;
		case AppId.MinElektriker:
			return <MinElektrikerLogo width={width} height={height} darkMode={darkMode} />;
		default:
			return <BefareLogo width={width} height={height} darkMode={darkMode} />;
	}
};

import { Badge, styled } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { RootStore } from '../../../../stores/RootStore';
import StoreContext from '../../../../stores/StoreContext';
import { Channel } from '../../../../stores/ChatStore';
import { computed, makeObservable } from 'mobx';
import ProfilePicture from '../../../common/ProfilePicture';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const StyledProfilePicture = styled(ProfilePicture)(() => ({
	width: '1.5rem',
	height: '1.5rem',
}));

const StyledBadge = styled(Badge)(() => ({
	width: 'auto',
	position: 'relative',
	flexGrow: 0,
	fontSize: '0.7rem',
	'&.peeking': {
		transform: 'translateY(40%)',
		'& .MuiBadge-badge': {
			backgroundColor: 'transparent',
		},
	},
	'&.typing': {
		transform: 'translateY(10%)',
	},
	transition: 'transform 0.3s ease-in-out',
	'& .MuiBadge-badge': {
		top: 0,
		right: 0,
		position: 'absolute',
		zIndex: 2,
	},
	'&:hover': {
		transform: 'translateY(0%)',
	},
}));

type Props = {
	channel: Channel;
};

const ChatInputIndicators = observer(
	class ChatInputIndicators extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				channel: computed,
				typing: computed,
				peeking: computed,
				typingOrPeeking: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get channel() {
			return this.props.channel;
		}

		get typing() {
			return this.channel?.typing ?? [];
		}

		get peeking() {
			return this.channel?.peeking ?? [];
		}

		get typingOrPeeking() {
			const typing = this.typing?.map((p: any) => ({
				typing: true,
				...p,
			}));

			this.peeking.forEach((p: any) => {
				if (!typing.find((profile: any) => profile.userId.toString() === p.userId)) {
					typing.push(p);
				}
			});

			return typing;
		}

		renderPeekingOrTyping() {
			return this.typingOrPeeking
				.filter((p: any) => p.userId.toString() !== this.profileStore.currentUserProfile?.userId.toString())
				.map((p: any) => {
					const profile = this.profileStore.getProfile(p.userId);

					if (!profile) {
						return null;
					}

					const className = p.typing ? 'typing' : 'peeking';

					return (
						<StyledBadge
							className={className}
							color="primary"
							badgeContent={p.typing ? '...' : 0}
							key={profile.userId}
						>
							<StyledProfilePicture typing={p.typing} peeking={!p.typing} profile={profile} />
						</StyledBadge>
					);
				});
		}

		render() {
			return (
				<Grid2 container spacing={1} sx={{ width: '100%', maxWidth: '800px', zIndex: 1, position: 'relative' }}>
					{this.renderPeekingOrTyping()}
				</Grid2>
			);
		}
	}
);

export default ChatInputIndicators;
